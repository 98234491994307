import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetIconsFromOrganizationQuery } from "../../../app/services/organization";
import { getCurrentOrganization } from "../../../app/slices/appStateSlice";
import List from "../../../components/list/List";
import { handleModal } from "../../../components/modal/Modal";
import Preview from "../../../components/preview/Preview";
import Toggle from "../../../components/toggle/Toggle";
import ListOverviewTemplate from "../../../layouts/ListOverviewTemplate";
import { ModalTypes } from "../../../modules/Modals/ConfirmDeleteModal";
import { ImageModalActions } from "../../../modules/Modals/ImageModals/ImageModalSelector";

const SettingsIcons = () => {
	const dispatch = useDispatch();
	const selectedOrg = useSelector(getCurrentOrganization);
	const [skip, setSkip] = useState(true);
	const { data } = useGetIconsFromOrganizationQuery(selectedOrg.id, { skip });
	const [currentObjects, setCurrentObjects] = useState(data);

	useEffect(() => {
		selectedOrg && setSkip(false);
	}, [selectedOrg]);

	useEffect(() => {
		setCurrentObjects(data);
	}, [data]);

	const handlePreview = (id) => {
		let selectedData = getDataById(id);
		handleModal(
			dispatch,
			ModalTypes.Icon,
			ImageModalActions.Preview,
			selectedData
		);
	};

	const handleNew = () => {
		let selectedData = {};
		handleModal(
			dispatch,
			ModalTypes.Icon,
			ImageModalActions.Create,
			selectedData
		);
	};

	const handleEdit = (id) => {
		let selectedData = getDataById(id);
		handleModal(
			dispatch,
			ModalTypes.Icon,
			ImageModalActions.Edit,
			selectedData
		);
	};

	const handleDelete = (item) => {
		handleModal(dispatch, ModalTypes.Icon, ImageModalActions.Delete, item);
	};

	const getDataById = (id) => {
		var result = data.find((i) => i.id === id);
		return result;
	};

	const IconList = () => {
		return (
			<>
				{currentObjects && (
					<List id="icons">
						<List.Header>
							<List.Header.Cell>Preview</List.Header.Cell>
							<List.Header.Cell>Name</List.Header.Cell>
							<List.Header.Cell>Active</List.Header.Cell>
						</List.Header>
						{currentObjects.map((item) => {
							return (
								<List.Item
									key={item.id}
									buttons={[
										// Preview Button hidden if there is no filename (=no file)
										<List.Item.Button
											type="View"
											key="view-button"
											callback={() =>
												handlePreview(item.id)
											}
											hidden={!item.thumbnailUrl}
										/>,
										<List.Item.Button
											type="Edit"
											key="edit-button"
											callback={() => handleEdit(item.id)}
										/>,
										<List.Item.Button
											type="Delete"
											key="delete-button"
											callback={() => handleDelete(item)}
										/>,
									]}
								>
									<List.Item.Content key="preview">
										<Preview
											zoomable={Boolean(
												item.thumbnailUrl
											)}
											filename={item.thumbnailUrl}
											onClick={
												item.thumbnailUrl
													? () =>
															handlePreview(
																item.id
															)
													: undefined
											}
										/>
									</List.Item.Content>
									<List.Item.Content title key="name">
										{item.name}
										{item.id}
									</List.Item.Content>
									<List.Item.Content key="toggle">
										<Toggle
											disabled={true}
											id={item.id}
											checked={true}
										/>
									</List.Item.Content>
								</List.Item>
							);
						})}
					</List>
				)}
			</>
		);
	};

	return (
		<>
			<>
				<div className={"mb-5 mt-5"}>
					<h5>Icons Overview</h5>
				</div>
				<ListOverviewTemplate addCallback={handleNew}>
					{currentObjects && <IconList />}
				</ListOverviewTemplate>
			</>
		</>
	);
};

export default SettingsIcons;
