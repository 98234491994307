import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getEndpoint } from "./basics";
const base = getEndpoint();

export const workplaceApi = createApi({
	reducerPath: "workplaceApi",
	baseQuery: fetchBaseQuery({
		baseUrl: base,
		prepareHeaders: (headers) => {
			headers.set("Content-Type", "application/json");
			headers.set("Accept", "application/json");
			return headers;
		},
		credentials: "include",
	}),
	tagTypes: [],
	endpoints: (builder) => ({
		// getWorkplaces: builder.query({
		// 	query: () => ({
		// 		// When performing a mutation, you typically use a method of
		// 		// PATCH/PUT/POST/DELETE for REST endpoints
		// 		// fetchBaseQuery automatically adds `content-type: application/json` to
		// 		// the Headers and calls `JSON.stringify(patch)`
		// 		url: `workplace`,
		// 		method: "GET",
		// 	}),
		// }),
		getWorkplace: builder.query({
			query: (id) => ({
				url: `workplace/${id}`,
				method: "GET",
			}),
		}),
		createWorkplace: builder.mutation({
			query: (data) => ({
				url: `workplace`,
				method: "POST",
				body: data,
			}),
		}),
		updateWorkplace: builder.mutation({
			query: ({ id, patch }) => ({
				url: `workplace/${id}`,
				method: "PATCH",
				body: patch,
			}),
		}),
		deleteWorkplace: builder.mutation({
			query: (id) => ({
				url: `workplace/${id}`,
				method: "DELETE",
			}),
		}),
	}),
});

// Export hooks for usage in functional components
export const {
	useGetWorkplacesQuery,
	useGetWorkplaceQuery,
	useCreateWorkplaceMutation,
	useDeleteWorkplaceMutation,
	useUpdateWorkplaceMutation,
} = workplaceApi;
