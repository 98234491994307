import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getEndpoint } from "./basics";
const base = getEndpoint();

export const workflowApi = createApi({
	reducerPath: "workflowApi",
	baseQuery: fetchBaseQuery({
		baseUrl: base,
		prepareHeaders: (headers) => {
			headers.set("Content-Type", "application/json");
			headers.set("Accept", "application/json");
			return headers;
		},
		credentials: "include",
	}),
	tagTypes: ["Workflows"],
	endpoints: (builder) => ({
		getWorkflow: builder.query({
			query: (id) => ({
				url: `workflow/${id}`,
				method: "GET",
			}),
			providesTags: ["Workflows"],
		}),
		getLanguages: builder.query({
			query: () => ({
				url: `languages`,
				method: "GET",
			}),
			providesTags: ["Languages"],
		}),
		getWorkflowLanguage: builder.query({
			query: ({ id, language }) => ({
				url: `workflow/${id}/languages/${language}`,
				method: "GET",
			}),
			providesTags: ["Workflow"],
		}),
		getWorkflowLanguages: builder.query({
			query: (id) => ({
				url: `workflow/${id}/languages`,
				method: "GET",
			}),
			providesTags: ["WorkflowLanguages"],
		}),
		getStepsOfWorkflow: builder.query({
			query: (id) => ({
				url: `workflow/${id}/steps`,
				method: "GET",
			}),
			providesTags: ["Workflows", "Steps"],
		}),
		createWorkflow: builder.mutation({
			query: (data) => ({
				url: `workflow`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: (_) => ["Workflows"],
		}),
		createWorkflowLanguage: builder.mutation({
			query: ({ id, data }) => ({
				url: `workflow/${id}/language`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: (_) => ["Workflow"],
		}),
		updateWorkflow: builder.mutation({
			query: ({ id, patch }) => ({
				url: `workflow/${id}`,
				method: "PATCH",
				body: patch,
			}),
			invalidatesTags: (_) => ["Workflows"],
		}),
		deleteWorkflow: builder.mutation({
			query: (id) => ({
				url: `workflow/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: (_) => ["Workflows"],
		}),
		copyWorkflow: builder.mutation({
			query: (id) => ({
				url: `workflow/${id}/copy`,
				method: "POST",
				body: {}
			}),
			invalidatesTags: (_) => ["Workflows"],
		}),
	}),
});

// Export hooks for usage in functional components
export const {
	useGetLanguagesQuery,
	useGetWorkflowQuery,
	useGetStepsOfWorkflowQuery,
	useGetWorkflowLanguagesQuery,
	useGetWorkflowLanguageQuery,
	useCreateWorkflowMutation,
	useCreateWorkflowLanguageMutation,
	useDeleteWorkflowMutation,
	useCopyWorkflowMutation,
	useUpdateWorkflowMutation,
} = workflowApi;
