import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getEndpoint } from "./basics";
const base = getEndpoint();

export const patternApi = createApi({
	reducerPath: "patternApi",
	baseQuery: fetchBaseQuery({
		baseUrl: base,
		prepareHeaders: (headers) => {
			headers.set("Content-Type", "application/json");
			headers.set("Accept", "application/json");
			return headers;
		},
		credentials: "include",
	}),
	tagTypes: [],
	endpoints: (builder) => ({
		// getPatterns: builder.query({
		// 	query: () => ({
		// 		// When performing a mutation, you typically use a method of
		// 		// PATCH/PUT/POST/DELETE for REST endpoints
		// 		// fetchBaseQuery automatically adds `content-type: application/json` to
		// 		// the Headers and calls `JSON.stringify(patch)`
		// 		url: `pattern`,
		// 		method: "GET",
		// 	}),
		// }),
		// getPattern: builder.query({
		// 	query: (id) => ({
		// 		url: `pattern/${id}`,
		// 		method: "GET",
		// 	}),
		// }),
		createPattern: builder.mutation({
			query: (data) => ({
				url: `pattern`,
				method: "POST",
				body: data,
			}),
		}),
		updatePattern: builder.mutation({
			query: ({ id, patch }) => ({
				url: `pattern/${id}`,
				method: "PATCH",
				body: patch,
			}),
		}),
		deletePattern: builder.mutation({
			query: (id) => ({
				url: `pattern/${id}`,
				method: "DELETE",
			}),
		}),
	}),
});

// Export hooks for usage in functional components
export const {
	useCreatePatternMutation,
	useDeletePatternMutation,
	useGetPatternQuery,
	useGetPatternsQuery,
	useUpdatePatternMutation,
} = patternApi;
