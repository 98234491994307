import React, { useState } from "react";
import StepContent from "../../../modules/workflowSteps/StepContent";

const CreateStep = ({ id, saveStepCb }) => {
	const [showAddStep, setShowAddStep] = useState(false);

	const handleAddStep = (e) => {
		e.preventDefault();
		setShowAddStep(true);
	};

	const handleCancelNewStep = (e) => {
		e.preventDefault();
		setShowAddStep(false);
	};

	const CreateStepWarning = () => {
		return (
			<div className="warning-dialogue">
				<span>
					You have to save your new workflow before you can create
					steps.
				</span>
			</div>
		);
	};

	const handleSaveStep = (e, updatedStep) => {
		saveStepCb(e, updatedStep);
		setShowAddStep(false);
	};
	return id === undefined ? (
		<CreateStepWarning />
	) : (
		<>
			{showAddStep ? (
				<StepContent
					step={{mediafiles: []}}
					workflowId={id}
					handleCancel={(e) => handleCancelNewStep(e)}
					saveStepCallback={(e, updatedStep) =>
						handleSaveStep(e, updatedStep)
					}
					editable={true}
					newStep={true}
				/>
			) : (
				<button
					className="btn btn-lg btn-cta float-right"
					onClick={(e) => handleAddStep(e)}
				>
					<i className="icon">+</i>
					Add Step
				</button>
			)}
		</>
	);
};

export default CreateStep;
