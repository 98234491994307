import {configureStore} from "@reduxjs/toolkit";
import appStateReducer from "./slices/appStateSlice";
import notificationStateReducer from "./slices/notifactionStateSlice";
import {userApi} from "./services/user";
import {groupApi} from "./services/group";
import {environmentApi} from "./services/environment";
import {organizationApi} from "./services/organization";
import {workflowApi} from "./services/workflow";
import {workplaceApi} from "./services/workplace";
import {sessionApi} from "./services/session";
import {iconApi} from "./services/icon";
import {ARObjectApi} from "./services/ARObject";
import {patternApi} from "./services/pattern";
import {stepApi} from "./services/step";
import {mediafileApi} from "./services/mediafile";
import {authTenantApi} from "./services/authTenant";

export default configureStore({
	reducer: {
		appState: appStateReducer,
		notificationState: notificationStateReducer,
		sessionApi: sessionApi.reducer,
		userApi: userApi.reducer,
		groupApi: groupApi.reducer,
		environmentApi: environmentApi.reducer,
		organizationApi: organizationApi.reducer,
		workflowApi: workflowApi.reducer,
		workplaceApi: workplaceApi.reducer,
		iconApi: iconApi.reducer,
		mediafileApi: mediafileApi.reducer,
		ARObjectApi: ARObjectApi.reducer,
		patternApi: patternApi.reducer,
		stepApi: stepApi.reducer,
		authTenantApi: authTenantApi.reducer
	},

	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware()
			.concat(sessionApi.middleware)
			.concat(groupApi.middleware)
			.concat(userApi.middleware)
			.concat(workflowApi.middleware)
			.concat(workplaceApi.middleware)
			.concat(environmentApi.middleware)
			.concat(organizationApi.middleware)
			.concat(iconApi.middleware)
			.concat(mediafileApi.middleware)
			.concat(ARObjectApi.middleware)
			.concat(patternApi.middleware)
			.concat(stepApi.middleware)
			.concat(authTenantApi.middleware),
});
