import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { getModalData } from "../../app/slices/appStateSlice";
import { CustomModal } from "../../components/modal/Modal";
import { ModalTypes } from "./ConfirmDeleteModal";
import { SaveCallbackContext } from "../step/StepDescription";

const OverwriteAudioModal = () => {
	const modal = useSelector(getModalData);
	const saveCallback = useContext(SaveCallbackContext);

	useEffect(() => {}, [modal]);

	return (
		<>
			{modal && modal.type === ModalTypes.Audio && (
				<CustomModal
					id={"update-audio"}
					title={"Are you sure?"}
					okButton={
						<button
							type="button"
							className="btn btn-danger ml-3 "
							onClick={(e) => saveCallback(e, modal.data)}
						>
							Yes, overwrite current Audio.
						</button>
					}
				>
					Are you sure you want to generate an audio file from your
					text?
					<br />
					The currently saved audio will be overwritten by this
					action.
				</CustomModal>
			)}
		</>
	);
};

export default OverwriteAudioModal;
