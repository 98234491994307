import React, {useEffect, useState} from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ErrorMessages, SuccessMessages } from "../../../app/Messages";
import {useGetWorkflowsFromOrganizationQuery, useGetWorkplacesFromOrganizationQuery} from "../../../app/services/organization";
import List from "../../../components/list/List";
import ListOverviewTemplate from "../../../layouts/ListOverviewTemplate";

import {
	useCreateWorkplaceMutation,
	useGetWorkplaceQuery,
	useUpdateWorkplaceMutation,
} from "../../../app/services/workplace";
import { getCurrentOrganization } from "../../../app/slices/appStateSlice";
import {
	NotificationType,
	startNotification,
} from "../../../app/slices/notifactionStateSlice";
import Details from "../../../layouts/Details";
import { ModalTypes } from "../../../modules/Modals/ConfirmDeleteModal";
import ErrorHandler from "../../../app/ErrorHandler";

const WorkplaceDetail = ({ data }) => {
	const rootPath = "/workplaces";

	const [updateWorkplace] = useUpdateWorkplaceMutation();
	const [createWorkplace] = useCreateWorkplaceMutation();

	const currentOrg = useSelector(getCurrentOrganization);
	const editMode = Object.keys(data).length !== 0;

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { refetch } = useGetWorkplaceQuery(data.id);
	const { refetch: refetchWorkplaces } =
		useGetWorkplacesFromOrganizationQuery(currentOrg.id);
	const [items, setItems] = useState([]);
	const [workflowGroups, setWorkflowGroups] = useState([]);

	const [skip, setSkip] = useState(true);
	const {
		data: workflowData,
		isLoading,
		error,
		isSuccess: loadSuccess,
	} = useGetWorkflowsFromOrganizationQuery(currentOrg && currentOrg.id, {
		skip,
	});
	useEffect(() => {
		currentOrg && setSkip(false);
	}, [currentOrg]);

	useEffect(() => {
		loadSuccess && setItems(workflowData);
		if(workflowData) {
			let workflowGroupDataReduced = workflowData
				.filter(wf => wf.workplace?._id === data.id)
				.reduce((acc, curr, index) => {
				if(acc[curr.workplace?._id]) {
					acc[curr.workplace?._id].push(curr);
				} else {
					acc[curr.workplace?._id] = [curr];
				}
				return acc;
			}, {});
			workflowGroupDataReduced = Object.values(workflowGroupDataReduced);
			setWorkflowGroups(workflowGroupDataReduced)
		}

		!isLoading && error && ErrorHandler(error);
	}, [error, isLoading, loadSuccess, workflowData]);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	useEffect(() => {}, [currentOrg]);

	const handleSave = (formData) => {
		// var formData = new FormData(document.querySelector("form"));
		// var postData = Object.fromEntries(formData.entries());
		var postData = formData;
		postData = { ...postData, organization: currentOrg.id };

		try {
			editMode
				? updateWorkplace({ id: data.id, patch: postData }).then(() => {
						dispatch(
							startNotification({
								message: SuccessMessages.SAVE,
								type: NotificationType.SUCCESS,
							})
						);
						refetch();
				  })
				: createWorkplace(postData).then(() => {
						startNotification({
							message: SuccessMessages.CREATE,
							type: NotificationType.SUCCESS,
						});
						refetchWorkplaces();
						navigate(-1);
				  });
		} catch (error) {
			dispatch(
				startNotification({
					message: error.message
						? error.message
						: "An error occured.",
					type: NotificationType.ERROR,
				})
			);
		}
	};

	const handleView = (item) => {
		navigate("/workflows/" + item.id);
	};

	const handleRowClick = (e, item) => {
		if (e.target.type === "button" || e.target.type === "submit") return;
		handleView(item);
	};

	const ListGroup = ({workflows}) => {
		let workplaceName = workflows[0].workplace.name;
		let workplaceId = workflows[0].workplace.id;
		return(
			<div className={"drop-container"} key={workplaceId}>
				<div className={"workplace-title"}>
					Workflows
				</div>
				{workflows.map((item, index) => (
					<ListItem index={index} item={item} key={"workplace-view-"+item.id}/>
				))}
			</div>
		)
	}

	const ListItem = ({ item, index }) => {
		return (
			<List.Item
				onClick={(e) => handleRowClick(e, item)}
				classes={"clickable"}
				key={item.id}
				buttons={[]}
			>
				<List.Item.Content key="index">{index + 1}</List.Item.Content>
				<List.Item.Content title key="name">
					{item.name}
				</List.Item.Content>
			</List.Item>
		);
	};

	return (
		<>
			{currentOrg && (
				<form>
					<Details
						saveFunction={handleSubmit(handleSave)}
						modalData={{
							type: ModalTypes.Workplace,
							data: data,
							navigate: rootPath,
						}}
					>
						<div className="form-group">
							<div className="form-group">
								<div className={`label-container`}>
									<label htmlFor={"name"}>
										Workplace Name
									</label>
								</div>
								<div
									className={`input-container ${
										errors["name"] && "error"
									}`}
								>
									<input
										name={"name"}
										type={"text"}
										placeholder="Workplace Name"
										className={`form-control mr-2 centered ${
											errors["name"] && "error"
										}`}
										defaultValue={data?.name ?? ""}
										{...register("name", {
											required: ErrorMessages.REQUIRED,
										})}
									/>
									{errors && errors["name"] && (
										<span className="error">
											{errors["name"]?.message}
										</span>
									)}
								</div>
							</div>
						</div>
					</Details>
				</form>
			)}

			<ListOverviewTemplate isLoading={isLoading}>
				{items && items.length > 0 ? (
					<List id="workflows">
						{
							workflowGroups.map(workflowGroup => (
								<ListGroup workflows={workflowGroup} key={"workplace-group-"+workflowGroup[0].workplace.id}/>
							))
						}
					</List>
				) : (
					<p>No workflows found.</p>
				)}
			</ListOverviewTemplate>
		</>
	);
};

export default WorkplaceDetail;
