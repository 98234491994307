import React from "react";
import GroupSelection from "../../../../../modules/selections/GroupSelection";

const GroupManagement = ({ groups, setGroups }) => {
	return (
		<>
			{groups && (
				<GroupSelection selection={groups} setSelection={setGroups} />
			)}
		</>
	);
};

export default GroupManagement;
