import React, { useEffect } from "react";
import { useGetUsersQuery } from "../../../../app/services/user";
import List from "../../../../components/list/List";
import Searchbar from "../../../../components/search/Searchbar";

const GroupUsers = ({ groupUsers, setGroupUsers }) => {
	const { data: users } = useGetUsersQuery();

	useEffect(() => {}, [groupUsers]);

	const handleRemove = (e, elm) => {
		e.preventDefault();

		setGroupUsers((groupUsers) =>
			groupUsers.includes(elm)
				? groupUsers.filter((item) => item.id !== elm.id)
				: [...groupUsers, elm]
		);
	};

	const getUnselectedUsers = () => {
		let unselectedUsers = users.filter(
			(o) => !groupUsers.some((user) => user.id === o.id)
		);

		const mappedUsers = unselectedUsers.map((user) => ({
			...user,
			name: user.email,
		}));

		return mappedUsers;
	};

	const handleSelect = (user) => {
		setGroupUsers([...groupUsers, user]);
	};

	return (
		<div className="list-container col-12" id="group-users">
			{groupUsers && (
				<>
					{groupUsers.map((user, index) => (
						<List.Item
							key={user.id}
							buttons={[
								<List.Item.Button
									key={`${user.id}-button`}
									type="Remove"
									btncolor="btn-danger"
									icon=""
									callback={(event) =>
										handleRemove(event, user)
									}
								/>,
							]}
						>
							<List.Item.Content key={`${index}-content-1`}>
								{index}
							</List.Item.Content>
							<List.Item.Content
								key={`${user.id}-content-2`}
								title
							>
								{user.email}
							</List.Item.Content>
						</List.Item>
					))}
				</>
			)}
			{users && (
				<Searchbar
					callback={(item) => handleSelect(item)}
					data={getUnselectedUsers()}
				/>
			)}
		</div>
	);
};

export default GroupUsers;
