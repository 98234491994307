import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import List from "../../components/list/List";

import {
	useDeleteGroupMutation,
	useGetGroupsQuery,
} from "../../app/services/group";
import { setModalData, showModal } from "../../app/slices/appStateSlice";
import { ModalTypes } from "../Modals/ConfirmDeleteModal";

const GroupList = ({ data }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { isSuccess: deleteSuccess } = useDeleteGroupMutation();
	const { refetch } = useGetGroupsQuery();

	useEffect(() => {
		refetch();
	}, [deleteSuccess, refetch]);

	const handleRowClick = (e, item) => {
		if (e.target.type === "button" || e.target.type === "submit") return;
		if (Array.from(e.target.classList).includes("list-item-buttons"))
			return;
		handleEdit(item);
	};

	const handleDelete = (item) => {
		dispatch(showModal("delete"));
		dispatch(
			setModalData({
				type: ModalTypes.Group,
				data: item,
			})
		);
	};

	const handleEdit = (item) => {
		navigate("/groups/" + item.id);
	};

	const handleManageUsers = (item) => {
		dispatch(
			setModalData({
				data: item,
				navigate: "/groups/" + item.id + "/users",
			})
		);
		dispatch(showModal("select-users"));
	};

	return (
		<>
			<>
				<List id="groups">
					<List.Header>
						<List.Header.Cell>#</List.Header.Cell>
						<List.Header.Cell>Groupname</List.Header.Cell>
						<List.Header.Cell>Users</List.Header.Cell>
					</List.Header>
					{data.length > 0 &&
						data.map((item, index) => (
							<List.Item
								key={index}
								onClick={(e) => handleRowClick(e, item)}
								classes="clickable"
								buttons={[
									<List.Item.Button
										type="Edit"
										key="edit-button"
										callback={() => handleEdit(item)}
									/>,
									<List.Item.Button
										key="delete-button"
										type="Delete"
										callback={() => handleDelete(item)}
									/>,
								]}
							>
								<List.Item.Content key="index">
									{index + 1}
								</List.Item.Content>
								<List.Item.Content title key="name">
									{item.name}
								</List.Item.Content>
								<List.Item.Content key="buttons">
									<button
										type="button"
										className="btn btn-default btn-tertiary"
										onClick={() => handleManageUsers(item)}
									>
										Manage Users
									</button>
								</List.Item.Content>
							</List.Item>
						))}
				</List>
			</>
		</>
	);
};

export default GroupList;
