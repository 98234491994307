import React from "react";
import { useParams } from "react-router-dom";
import { useGetOrganizationQuery } from "../../../app/services/organization";
import { Titlebar, TitleType } from "../../../layouts/Content";
import FullpageSpinner from "../../../modules/spinner/FullpageSpinner";
import OrganizationDetail from "./OrganizationDetail";
import FourOFour from "../../errors/404";

const OrganizationNew = () => {
	return (
		<>
			<Titlebar
				type={TitleType.Create}
				title="organization"
				parent={{
					name: "Organizations",
					url: "/organizations",
				}}
			/>
			<OrganizationDetail data={{}} />
		</>
	);
};
const OrganizationEdit = () => {
	const { id } = useParams();
	const {
		data,
		isSuccess: loadSuccess,
		isLoading,
		isUninitialized,
	} = useGetOrganizationQuery(id);
	return (
		<>
			{isLoading || isUninitialized ? (
				<FullpageSpinner />
			) : (
				<>
					{loadSuccess && data ? (
						<>
							<Titlebar
								title={data.name}
								parent={{
									name: "Organizations",
									url: "/organizations",
								}}
							/>
							<OrganizationDetail data={data} />
						</>
					) : (
						<FourOFour
							error={`Organization with ID "${id}" not found`}
							parent={{
								name: "organizations",
								url: "/organizations",
							}}
						/>
					)}
				</>
			)}
		</>
	);
};

export { OrganizationNew, OrganizationEdit };
