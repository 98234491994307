import React from "react";
import FileIcon from "../../components/card/FileIcon";

const AccordeonCard = ({ children, ...props }) => {
	return (
		<>
			<div
				className="workflow-step drop-container flex flex-column"
				{...props}
			>
				{children}
			</div>
		</>
	);
};

const AccordeonCardHeader = ({ ...props }) => {};

const AccordeonCardName = ({ children }) => {
	return (
		<div className="row">
			<div className="col-12">{children}</div>
		</div>
	);
};

// const handleDownload  = (file.mediaUrl) => {

// }

const AccordeonCardFiles = ({
	files,
	removeFileCallback,
	editable,
	children,
}) => {
	const FileList = () => {
		if (files.length > 0) {
			return files.map((file, index) => {
				return (
					<FileIcon
						key={index}
						file={file}
						deleteCallback={() => {
							removeFileCallback(index);
						}}
						editable={editable}
					/>
				);
			});
		}
	};
	return (
		<div className="row">
			<div className="file-container flex flex-column">
				{files && (
					<>
						<label>Additional Files</label>
						<div className="flex flex-grid mb-4">
							<FileList />
						</div>
					</>
				)}
				{children}
			</div>
		</div>
	);
};

const AccordeonCardDescription = ({
	mediafile,
	description,
	fullWidth = false,
}) => {
	return (
		<div className="row">
			{!fullWidth ? (
				<>
					<div className="col-4 workflow-file">{mediafile}</div>
					<div className="col-8 workflow-description">
						{description}
					</div>
				</>
			) : (
				<>
					<div className="col-12 workflow-file">{mediafile}</div>
				</>
			)}
		</div>
	);
};

const AccordeonCardButtons = ({ children }) => {
	return (
		<div className="row ">
			<div className="col-12">{children}</div>
		</div>
	);
};

AccordeonCard.Buttons = AccordeonCardButtons;
AccordeonCard.Description = AccordeonCardDescription;
AccordeonCard.Files = AccordeonCardFiles;
AccordeonCard.Name = AccordeonCardName;
AccordeonCard.Header = AccordeonCardHeader;

export default AccordeonCard;
