// const getAT = () => "BIEPEqrW4Aayz:DD]idVn=YoS";
const getEndpoint = () => process.env["REACT_APP_API_ENDPOINT"];
const getLoginRedirect = () => process.env["REACT_APP_UI_URL"];
const getAuthURL = () => getEndpoint() + "/auth/login?redirectUrl=";
const getUploadURL = () =>
	getEndpoint() +
	"/arobject/51d999f6-1eb8-4add-ae69-9297b0b943a2/getUploadUrl";

const Paths = {
	Users: {
		root: "/users",
	},
	Groups: {
		root: "/groups",
	},
	Organizations: {
		root: "/organizations",
	},
	Workplaces: {
		root: "/workplaces",
	},
	Workflows: {
		root: "/workflows",
	},
	Environments: {
		root: "/environments",
	},
	Settings: {
		root: "/settings",
	},
};

export { getUploadURL, getEndpoint, getLoginRedirect, getAuthURL, Paths };
