import React, { useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";
import { SmallListItem } from "../components/listItems/SmallListItem";
import Steps from "../components/stepper/Steps";
import { Mediafile } from "../modules/mediafile/Mediafile";
import {AudioComponent} from "../modules/voiceEditor/VideoComponent";

const Stepper = ({ data }) => {
	const [currentStep, setCurrentStep] = useState(1);
	const [showDownloads, setShowDownloads] = useState(false);
	const [mediafile, setMediafile] = useState(null);

	const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

	useEffect(() => {
		if (currentStep && data && data[currentStep - 1].mediafiles) {
			const file = data[currentStep - 1].mediafiles
				.filter(
					(file) =>
						file.type === "video/mp4" || file.type === "image/jpeg"
				)
				.pop();

			setMediafile(file);
		}
	}, [data, currentStep]);

	const handlers = useSwipeable({
		onSwipedUp: (e) => handleSwipeUp(e),
		onSwipedDown: (e) => handleSwipeDown(e),
		preventScrollOnSwipe: true,
		trackTouch: true,
		trackMouse: true,
	});

	const handleSwipeUp = (e) => {
		setShowDownloads(true);
	};
	const handleSwipeDown = (e) => {
		setShowDownloads(false);
	};

	const toggleDownloadBar = () => {
		setShowDownloads((showDownloads) => !showDownloads);
	};

	const handleNextStep = () => {
		currentStepSetter(currentStep + 1);
		setShowDownloads(false);
	};
	const handlePrevStep = () => {
		currentStepSetter(currentStep - 1);
		setShowDownloads(false);
	};

	const currentStepSetter = (value) => {
		value = clamp(value, 1, data.length);
		setCurrentStep(value);
	};

	const handleDLButtonClick = () => {
		if (data[currentStep - 1].files.length > 1) {
			toggleDownloadBar();
		} else {
			window.open(data[currentStep - 1].files[0].mediaUrl, "_blank");
		}
	};
	// const handleDownload = (file) => {};

	const handleDone = () => {
		// reset to first step
		currentStepSetter(0);
	};

	const StepAudio = ({stepData}) => {
		const langInfo = {stt: false, tts: false};
		let audioMediaFile = stepData.mediafiles
			.filter(mf => mf.type === "audio/mpeg")
			.sort((a, b) => {
				let _a = new Date(a);
				let _b = new Date(b);
				if(_a === _b) {
					return 0;
				}
				if(_a < _b) {
					return -1;
				}
				if(_a > _b) {
					return 1;
				}
				return 0;
			});
		if(audioMediaFile.length > 0 ){
			return (
				<AudioComponent
					workflowId={""}
					step={stepData}
					editmode={false}
					languageInfo={langInfo}
					setAudio={() => {console.log("AUDIO CHANGTE")}}
					recording={false}
				/>
			);
		}
		return(<></>);
	}

	const StepContent = ({ stepData }) => {
		return (
			<>
				{stepData && (
					<>
						{!stepData.description && (
							<>
								<div className="row">
									<div className="col-12 mb-5">
										<h4>{stepData.title}</h4>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-lg-12">
										{mediafile !== null && (
											<Mediafile file={mediafile} />
										)}
									</div>
								</div>
							</>
						)}
						{stepData.description && (
							<>
								<div className="row">
									<div className="col-12 mb-5">
										<h4>{stepData.title}</h4>
									</div>
								</div>
								<div className="row">
										<div className="col-12 col-lg-6">
											{mediafile !== null && (
												<Mediafile file={mediafile} />
											)}
										</div>
										<div className="col-12 col-lg-6">
											{stepData.description}

											<StepAudio stepData={data[currentStep - 1]}/>
										</div>
								</div>
							</>
						)}
					</>
				)}
			</>
		);
	};

	return (
		<>
			{data && (
				<>
					<div className="stepper-container">
						<div className="col-12 flex mt-5 b-5">
							<Steps
								steps={data}
								current={currentStep}
								stepsShowing={5}
								offsetThreshold={3}
							/>
						</div>
					</div>

					<div className="content-container">
						<div className="col-12 b-5">
							<StepContent stepData={data[currentStep - 1]} />
						</div>
					</div>
					<div className="button-bar" {...handlers}>
						<div
							className="col-12  flex"
							style={{ justifyContent: "flex-end" }}
						>
							<div className="button-container">
								{currentStep !== 1 && (
									<button
										className={
											"btn btn-lg mr-1 btn-secondary"
										}
										onClick={handlePrevStep}
									>
										<i className="icon"></i>
										<span>Prev</span>
									</button>
								)}
							</div>
							<div className="button-container">
								{data[currentStep - 1].files &&
									data[currentStep - 1].files.length > 0 && (
										<>
											<button
												className={
													"btn btn-lg mr-1 btn-secondary"
												}
												onClick={handleDLButtonClick}
											>
												<i className="icon"></i>
												{data[currentStep - 1].files
													.length > 1 ? (
													<span>
														{showDownloads
															? "Hide Downloads"
															: "See Downloads"}
													</span>
												) : (
													<span>Download Files</span>
												)}
											</button>
										</>
									)}
							</div>
							<div className="button-container">
								{currentStep < data.length ? (
									<button
										className={"btn btn-lg mr-1 btn-cta"}
										onClick={handleNextStep}
									>
										<span>Next</span>
										<i className="icon"></i>
									</button>
								) : (
									<button
										className={"btn btn-lg mr-1 btn-cta "}
										onClick={handleDone}
									>
										<span>Restart</span>
										<i className="icon"></i>
									</button>
								)}
							</div>
						</div>
						{data[currentStep - 1].files.length > 1 && (
							<div
								className={
									showDownloads
										? "show download-container"
										: "download-container"
								}
							>
								{data[currentStep - 1].files.map(
									(file, index) => {
										return (
											<SmallListItem
												key={"dl-" + index}
												data={{}}
												classes="elevation-2"
											>
												<div className="file-item">
													<i className="icon type icon-hg">
														{file.type === "pdf"
															? ""
															: ""}
													</i>
													<span className="title">
														{file.name}
													</span>
													<div
														onClick={() =>
															window.open(
																file.mediaUrl,
																"_blank"
															)
														}
													>
														<i
															className="clickable icon download icon-hg"
															// onClick={() =>
															// 	handleDownload(file)
															// }
														>
															
														</i>
													</div>
												</div>
											</SmallListItem>
										);
									}
								)}
							</div>
						)}
					</div>
				</>
			)}
		</>
	);
};

export default Stepper;
