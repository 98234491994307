import React, { useEffect, useState } from "react";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import ErrorHandler from "../../../app/ErrorHandler";
import { useGetWorkflowQuery } from "../../../app/services/workflow";
import { Titlebar, TitleType } from "../../../layouts/Content";
import FullpageSpinner from "../../../modules/spinner/FullpageSpinner";
import WorkflowDetail from "./WorkflowDetail";
import WorkflowDetailViewonly from "../viewonly/WorkflowDetailViewonly";
import WorkflowOverviewViewonly from "../viewonly/WorkflowOverviewViewonly";
import FourOFour from "../../errors/404";
import LanguageFlagChooser from "../../../components/select/LanguageFlagChooser";

const WorkflowNew = () => {
	return (
		<>
			<Titlebar
				type={TitleType.Create}
				title={"Workflow"}
				parent={{
					name: "Workflows",
					url: "/workflows",
				}}
			/>
			<WorkflowDetail id={null} />
		</>
	);
};

const WorkflowView = ({ viewonly, detail = false }) => {
	const [skip, setSkip] = useState(true);
	const { id } = useParams();
	const {
		data,
		isSuccess: loadSuccess,
		isLoading,
		error,
		isUninitialized,
	} = useGetWorkflowQuery(id, { skip });
	const [navBarExtensions, setNavbarExtensions] = useOutletContext();

	const navigate = useNavigate();

	const handleSelect = (id) => {
		navigate(`/view/workflows/${id}`);
	}

	useEffect(() => {
		setNavbarExtensions(
			[(
				<LanguageFlagChooser
					workflowId={id}
					handleSelect={handleSelect}
					key={id + "workflowlanguageflagchooser"}
				/>
			)]
		);
	}, [id]);

	useEffect(() => {
		if (!isLoading && !loadSuccess && error) {
			var myError = { status: "418" };
			ErrorHandler(myError);
		}
	}, [isLoading, loadSuccess, id, error]);

	useEffect(() => {
		id && skip && setSkip(false);
	}, [id, skip]);

	useEffect(() => {
		if(data?.organization?.id) {
			console.log("Workflow data:", data);
			localStorage.setItem("selected-org", data.organization.id);
		}
	}, [data]);


	// if(id) {
	// }

	return (
		<>
			{isLoading ? (
				<FullpageSpinner />
			) : (
				<>
					{viewonly ? (
						detail ? (
							<>
								{data && !isUninitialized ? (
									<WorkflowDetailViewonly data={data} />
								) : (
									<FourOFour
										error={`Workflow with ID "${id}" not found`}
										parent={{
											name: "workflows",
											url: "/view/workflows",
										}}
									/>
								)}
							</>
						) : (
							<>
								<WorkflowOverviewViewonly />
							</>
						)
					) : (
						<>
							{data && !isUninitialized ? (
								<>
									<Titlebar
										title={data.name}
										parent={{
											name: "Workflows",
											url: "/workflows",
										}}
									/>
									{data.id && (
										<WorkflowDetail
											id={data.id}
											lang={data.language}
										/>
									)}
								</>
							) : (
								<>
									<FourOFour
										error={`Workflow with ID "${id}" not found`}
										parent={{
											name: "workflows",
											url: "/workflows",
										}}
									/>
								</>
							)}
						</>
					)}
				</>
			)}
		</>
	);
};

export { WorkflowNew, WorkflowView };
