import React, { useEffect, useState } from "react";
import ReactFlagsSelect, { De, Us } from "react-flags-select";
import { useDispatch } from "react-redux";
import { setModalData, showModal } from "../../app/slices/appStateSlice";
import {
	ModalActions,
	ModalTypes,
} from "../../modules/Modals/ConfirmDeleteModal";
import { useGetLanguagesQuery } from "../../app/services/workflow";

const _ = require("lodash");

const useCustomLabels = () => {
	const { data, isSuccess } = useGetLanguagesQuery();
	const [customLabels, setCustomLabels] = useState(null);

	useEffect(() => {
		if (isSuccess && customLabels === null) {
			let labels = _.reduce(
				data,
				(result, { languageIdentifier, flag, tts, stt, translate }) => {
					let secondary = "";
					if (tts || stt || translate) {
						secondary += " (";
					}
					if (tts) {
						secondary += "TTS";
					}
					if (stt) {
						secondary += " STT";
					}
					if (translate) {
						secondary += " TRL";
					}
					if (tts || stt || translate) {
						secondary += ")";
					}
					result[flag] = { primary: languageIdentifier, secondary };
					return result;
				},
				{}
			);
			labels.US = { primary: "Add new language", secondary: "+" };

			setCustomLabels(labels);
		}
	}, [data, customLabels, isSuccess]);

	return customLabels;
};
const useCountryList = (addButton = false) => {
	const { data, isSuccess } = useGetLanguagesQuery();
	const [countries, setCountries] = useState(null);
	useEffect(() => {
		if (isSuccess && countries === null) {
			let countryData = _.uniq(_.map(data, "flag")).sort();

			if (addButton) {
				countryData.push("US");
			}
			setCountries(countryData);
		}
	}, [data, isSuccess, countries, addButton]);

	return countries;
};

const convertLanguageToCountrycode = (languageCode, data) => {
	const matchingObject = _.find(data, { languageCode });
	return matchingObject ? matchingObject.flag : null;
};

const LanguageSelectorTemporary = ({ blacklist, countryList, ...props }) => {
	const [country, setCountry] = useState(null);
	const labels = useCustomLabels();
	const [customLabels, setCustomLabels] = useState(null);
	const countries = useCountryList();
	const [activeCountries, setActiveCountries] = useState(null);
	const [countryBlacklist, setCountryBlacklist] = useState(null);

	const { data: languageData } = useGetLanguagesQuery();

	useEffect(() => {}, [props.languages]);

	useEffect(() => {
		if (
			activeCountries === null &&
			countries !== null &&
			countryBlacklist !== null
		) {
			const filteredCountries = countries.filter(
				(country) => !countryBlacklist.includes(country)
			);
			setActiveCountries(filteredCountries);
		}
	}, [activeCountries, countryBlacklist, countries]);

	useEffect(() => {
		if (blacklist) {
			const test = blacklist.map((languageCode) =>
				convertLanguageToCountrycode(languageCode, languageData)
			);
			setCountryBlacklist(test);
		}
	}, [blacklist, countries, languageData]);

	useEffect(() => {
		if (customLabels === null) {
			setCustomLabels(labels);
		}
	}, [customLabels, labels]);

	const handleSelect = (code) => {
		setCountry(code);

		if (props.handleSelect) {
			props.handleSelect(code);
		}
	};

	return (
		<>
			{customLabels && activeCountries && (
				<ReactFlagsSelect
					selected={country}
					onSelect={handleSelect}
					customLabels={customLabels}
					showOptionLabel={true}
					showSecondarySelectedLabel={false}
					searchable={true}
					countries={activeCountries}
					{...props}
				/>
			)}
		</>
	);
};

const LanguageSelector = ({ active, ...props }) => {
	const [country, setCountry] = useState(null);
	const [languages, setLanguages] = useState(null);

	const labels = useCustomLabels();
	const [customLabels, setCustomLabels] = useState(null);
	const { data: languageData, isSuccess } = useGetLanguagesQuery();

	useEffect(() => {
		if (customLabels === null) {
			setCustomLabels(labels);
		}
	}, [customLabels, labels]);
	useEffect(() => {
		if (isSuccess && props.languages && languages === null) {
			const flagCodes = props.languages.map((languageCode) =>
				convertLanguageToCountrycode(
					languageCode.toLowerCase(),
					languageData
				)
			);

			setLanguages(flagCodes);

			// const modifiedLanguages = _.map(props.languages, (language) => {
			// 	return language.toUpperCase();
			// });

			// setLanguages(
			// 	modifiedLanguages
			// 		? modifiedLanguages
			// 		: ["DE", "EN", "UA", "CZ", "PL"]
			// );

			// console.log("languages");
			// console.log(languages);
			// console.log("modifiedLanguages");
			// console.log(modifiedLanguages);
		}
	}, [props.languages, languages, isSuccess, languageData]);

	useEffect(() => {
		if (active !== country) {
			var tmp = active;
			setCountry(tmp);
		}
	}, [active, props, country]);

	const handleSelect = (code) => {
		if (code === "GB") {
			setCountry(code.toUpperCase());
			props.handleSelect && props.handleSelect("en");
		} else {
			setCountry(code.toUpperCase());
			props.handleSelect && props.handleSelect(code.toUpperCase());
		}
	};

	return (
		<>
			{country && customLabels && (
				<ReactFlagsSelect
					selected={country}
					onSelect={handleSelect}
					customLabels={customLabels}
					showOptionLabel={true}
					showSecondarySelectedLabel={false}
					// searchable={true}
					countries={languages}
					{...props}
				/>
			)}
		</>
	);
};

const LanguageSelectorWithAdd = ({ active, onChangeCB, ...props }) => {
	const [country, setCountry] = useState(null);
	const [languages, setLanguages] = useState(null);
	const dispatch = useDispatch();

	const labels = useCustomLabels();
	const [customLabels, setCustomLabels] = useState(null);

	useEffect(() => {
		if (customLabels === null) {
			setCustomLabels(labels);
		}
	}, [customLabels, labels]);

	useEffect(() => {
		if (languages === null) {
			if (props.languages) {
				const modifiedLanguages = _.map(props.languages, (language) => {
					return _.replace(language.toUpperCase(), "EN", "GB");
				}).concat("US");

				setLanguages(
					modifiedLanguages
						? modifiedLanguages
						: ["DE", "EN", "UA", "CZ", "PL"]
				);
			} else {
				setLanguages(["US"]);
			}
		}
	}, [props.languages, languages]);

	useEffect(() => {
		if (active) {
			var tmp = active.toUpperCase();

			if (tmp === "EN") {
				tmp = _.replace(tmp.toUpperCase(), "EN", "GB");
			}
			setCountry(tmp);
		} else if (languages && languages[0] !== "US") {
			setCountry(languages[0].toUpperCase());
		}
	}, [active, languages]);

	const handleSelect = (code) => {
		if (code === "US") {
			handleAddLanguage();
		} else if (code === "GB") {
			setCountry(code.toUpperCase());
			onChangeCB("en");
		} else {
			setCountry(code.toUpperCase());
			onChangeCB(code.toLowerCase());
		}
	};

	const handleAddLanguage = () => {
		dispatch(showModal("language"));
		dispatch(
			setModalData({
				type: ModalTypes.Language,
				modalAction: ModalActions.Add,
			})
		);
	};

	return (
		<>
			{languages !== null && (
				<>
					{customLabels && (
						<ReactFlagsSelect
							selected={country}
							onSelect={handleSelect}
							customLabels={customLabels}
							countries={languages}
							showOptionLabel={true}
							showSecondarySelectedLabel={false}
							{...props}
							type={"button"}
						/>
					)}
				</>
			)}
		</>
	);
};

const LanguageIndicator = ({ active = "DE", ...props }) => {
	const [country] = useState(active);
	switch (country) {
		default:
		case "DE":
			return <De />;
		case "EN-US":
			return <Us />;
	}
};

export {
	useCustomLabels,
	LanguageIndicator,
	LanguageSelectorWithAdd,
	LanguageSelector,
	LanguageSelectorTemporary,
	convertLanguageToCountrycode,
};
