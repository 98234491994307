import React, { Children } from "react";
import Item from "./Item";
import ListItemButton from "./Buttons";
import ListItemContent from "./Content";

const List = ({ children, ...props }) => {
	return (
		<div className={`list-container ${props.classes}`} {...props}>
			{children}
		</div>
	);
};

const Header = ({ children }) => {
	return (
		<div className="list-header list-item-container">
			{Children.map(children, (child) => {
				return <div className="list-item">{child}</div>;
			})}
			<div className="list-item"> </div>
		</div>
	);
};

const HeaderCell = ({ children }) => {
	return <>{children}</>;
};

List.Header = Header;
List.Header.Cell = HeaderCell;
List.Item = Item;
List.Item.Content = ListItemContent;
List.Item.Button = ListItemButton;

export default List;
