import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ErrorMessages, SuccessMessages } from "../../../app/Messages";
import {
	useCreateOrganizationMutation,
	useGetOrganizationQuery,
	useGetOrganizationsQuery,
	useUpdateOrganizationMutation,
} from "../../../app/services/organization";
import {
	NotificationType,
	startNotification,
} from "../../../app/slices/notifactionStateSlice";
import Details from "../../../layouts/Details";
import { ModalTypes } from "../../../modules/Modals/ConfirmDeleteModal";

const OrganizationDetail = ({ data }) => {
	const rootPath = "/organizations";

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [updateOrganization] = useUpdateOrganizationMutation();
	const [createOrganization] = useCreateOrganizationMutation();

	const { refetch } = useGetOrganizationQuery(data.id);
	const { refetch: refetchOrgs } = useGetOrganizationsQuery();

	const editMode = Object.keys(data).length !== 0;

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const handleSave = (formData) => {
		var postData = formData;

		try {
			editMode
				? updateOrganization({ id: data.id, patch: postData }).then(
						() => {
							dispatch(
								startNotification({
									message: SuccessMessages.SAVE,
									type: NotificationType.SUCCESS,
								})
							);
							refetch();
						}
				  )
				: createOrganization(postData).then(() => {
						startNotification({
							message: SuccessMessages.CREATE,
							type: NotificationType.SUCCESS,
						});
						refetchOrgs();
						navigate(-1);
				  });
		} catch (error) {
			dispatch(
				startNotification({
					message: error.message
						? error.message
						: "An error occured.",
					type: NotificationType.ERROR,
				})
			);
		}
	};

	return (
		<>
			<form>
				<Details
					saveFunction={handleSubmit(handleSave)}
					modalData={{
						type: ModalTypes.Organization,
						data: data,
						navigate: rootPath,
					}}
				>
					<div className="form-group">
						<div className={`label-container`}>
							<label htmlFor={"name"}>Organization Name</label>
						</div>
						<div
							className={`input-container ${
								errors["name"] && "error"
							}`}
						>
							<input
								name={"name"}
								type={"text"}
								placeholder="Organization Name"
								className={`form-control mr-2 centered ${
									errors["name"] && "error"
								}`}
								defaultValue={data?.name ?? ""}
								{...register("name", {
									required: ErrorMessages.REQUIRED,
								})}
							/>
							{errors && errors["name"] && (
								<span className="error">
									{errors["name"]?.message}
								</span>
							)}
						</div>
					</div>
				</Details>
			</form>
		</>
	);
};

export default OrganizationDetail;
