import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { getModalData } from "../../../app/slices/appStateSlice";
import EditImageModal from "./EditImageModal";
import PreviewImageModal from "./PreviewImageModal";
import UploadImageModal from "./UploadImageModal";

const ImageModalActions = {
	Preview: "Preview",
	Edit: "Edit",
	Create: "Create",
	Delete: "Delete",
};

const SelectImageModal = () => {
	const modal = useSelector(getModalData);

	useEffect(() => {}, [modal]);

	const Content = () => {
		if (modal && modal.data) {
			switch (modal.action) {
				case ImageModalActions.Preview:
					return <PreviewImageModal data={modal.data} />;
				case ImageModalActions.Edit:
					return <EditImageModal data={modal.data} />;
				case ImageModalActions.Create:
				default:
					return <UploadImageModal modal={modal} />;
			}
		}
	};

	return <Content />;
};

export { ImageModalActions, SelectImageModal };
