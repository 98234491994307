import { createSlice } from "@reduxjs/toolkit";
import { ModalActions } from "../../modules/Modals/ConfirmDeleteModal";

export const appStateSlice = createSlice({
	name: "appState",
	initialState: {
		modalOpen: false,
		modalID: 0,
		modalData: {},
		selectedGroup: 0,
		currentGroupData: {},
		lang: "de",
		organization: null,
	},
	reducers: {
		toggleModal: (state) => {
			state.modalOpen = !state.modalOpen;
			if (state.modalOpen === false) {
				state.modalData = {};
			}
		},
		showModal: (state, action) => {
			state.modalID = action.payload;
		},
		setModalData: (state, action) => {
			let data = action.payload;
			if (!action.payload.modalAction) {
				data = { ...data, modalAction: ModalActions.Delete };
			}
			state.modalData = data;
		},
		setLanguage: (state, action) => {
			state.lang -= action.payload;
		},
		setSelectedGroup: (state, action) => {
			state.selectedGroup = action.payload;
		},
		setCurrentGroupData: (state, action) => {
			state.currentGroupData = action.payload;
		},
		setCurrentOrganization: (state, action) => {
			state.organization = action.payload;
		},
	},
});

export const {
	toggleModal,
	showModal,
	setLanguage,
	setSelectedGroup,
	setCurrentGroupData,
	setModalData,
	setCurrentOrganization,
} = appStateSlice.actions;

export const fetchCurrentGroupData = (id) => async (dispatch) => {};

export const fetchToggleModal = (data) => async (dispatch) => {};
export const toggleGroupSelectorModal = (data) => async (dispatch) => {
	// GetUsersInGroup(data.id).then((res) => {
	dispatch(setModalData(data));
	dispatch(toggleModal());
	// });
};

export const getModalState = (state) => state.appState.modalOpen;
export const getModalID = (state) => state.appState.modalID;
export const getModalData = (state) => state.appState.modalData;
export const getSelectedGroup = (state) => state.appState.selectedGroup;
export const getCurGroup = (state) => state.appState.currentGroupData;
export const getCurrentOrganization = (state) => state.appState.organization;

export default appStateSlice.reducer;
