import React, { useEffect, useState } from "react";
import { SmallListItem } from "../listItems/SmallListItem";

const Searchbar = ({ callback, data }) => {
	const [searching, setSearching] = useState(false);
	const [searchResult, setSearchResult] = useState([]);
	const [refresh, setRefresh] = useState(false);

	useEffect(() => {}, [refresh]);

	const toggleRefresh = () => {
		setSearching(false);
		setSearchResult([]);
		setRefresh((state) => !state);
	};

	const filterResults = (filter) => {
		let results = data.filter((item) => item.name.includes(filter));
		return results;
	};

	const selectCallback = (item) => {
		callback(item);
		toggleRefresh();
	};

	const handleSearch = () => {
		setSearching(true);
		let filter = document.getElementById("search-value").value;
		setSearchResult(filterResults(filter));
	};

	const Result = () => {
		let resultLength = searchResult.length;
		return (
			<>
				{searchResult.map(
					(item, index) =>
						index < 3 && (
							<SmallListItem
								selectable
								classes={"clickable"}
								data={item}
								key={index}
								selectCallback={() => selectCallback(item)}
							/>
						)
				)}
				{resultLength > 3 && <p className="float-right">...more</p>}
			</>
		);
	};

	return (
		<div
			tabIndex={0}
			className="item-select input-group"
			onBlur={() => setSearching(false)}
			onFocus={() => setSearching(true)}
		>
			<div style={{ position: "relative", flex: "1 1 auto" }}>
				<input
					id={"search-value"}
					type="text"
					className="form-control"
				/>
				{searching && (
					<div className="search-results">
						{searchResult.length > 0 ? (
							<Result />
						) : (
							<p>No Results Found</p>
						)}
					</div>
				)}
			</div>
			<span className="input-group-append">
				<button
					type="button"
					className="btn btn-secondary btn-flat icon icon-lg"
					onClick={handleSearch}
				>
					
				</button>
			</span>
		</div>
	);
};

export default Searchbar;
