import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getEndpoint } from "./basics";
const base = getEndpoint();

export const userApi = createApi({
	reducerPath: "userApi",
	baseQuery: fetchBaseQuery({
		baseUrl: base,
		prepareHeaders: (headers) => {
			headers.set("Content-Type", "application/json");
			headers.set("Accept", "application/json");
			return headers;
		},
		credentials: "include",
	}),
	tagTypes: ["Users"],
	endpoints: (builder) => ({
		getUser: builder.query({
			query: (id) => ({
				url: `user/${id}`,
				method: "GET",
				providesTags: ["Users"],
			}),
		}),
		getUsers: builder.query({
			query: () => ({
				url: `user`,
				method: "GET",
				providesTags: ["Users"],
			}),
		}),
		createUser: builder.mutation({
			query: (data) => ({
				url: `user`,
				method: "POST",
				body: data,
			}),
			invalidateTags: ["Users"],
		}),
		updateUser: builder.mutation({
			query: ({ id, data }) => ({
				url: `user/${id}`,
				method: "PATCH",
				body: JSON.stringify(data),
			}),
			invalidateTags: ["Users"],
		}),
		deleteUser: builder.mutation({
			query: (id) => ({
				url: `user/${id}`,
				method: "DELETE",
			}),
			invalidateTags: ["Users"],
		}),
	}),
});

// Export hooks for usage in functional components
export const {
	useGetUsersQuery,
	useGetUserQuery,
	useCreateUserMutation,
	useDeleteUserMutation,
	useUpdateUserMutation,
} = userApi;
