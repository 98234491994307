import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getEndpoint } from "./basics";
const base = getEndpoint();

export const organizationApi = createApi({
	reducerPath: "organizationApi",
	baseQuery: fetchBaseQuery({
		baseUrl: base,
		prepareHeaders: (headers) => {
			headers.set("Content-Type", "application/json");
			headers.set("Accept", "application/json");
			return headers;
		},
		credentials: "include",
	}),
	tagTypes: ["Organization", "Icons", "ARObjects", "Workflows"],
	endpoints: (builder) => ({
		getOrganizations: builder.query({
			query: () => ({
				url: `organization`,
			}),
		}),
		getOrganization: builder.query({
			query: (id) => ({
				url: `organization/${id}`,
				method: "GET",
			}),
		}),
		getWorkplacesFromOrganization: builder.query({
			query: (id) => ({
				url: `organization/${id}/workplaces`,
				method: "GET",
			}),
		}),
		getWorkflowsFromOrganization: builder.query({
			query: (id) => ({
				url: `organization/${id}/workflows`,
				method: "GET",
			}),
			providesTags: (_) => ["Workflows"],
		}),
		getEnvironmentsFromOrganization: builder.query({
			query: (id) => ({
				url: `organization/${id}/environments`,
				method: "GET",
			}),
		}),
		getARObjsFromOrganization: builder.query({
			query: (id) => ({
				url: `organization/${id}/arobjects`,
				method: "GET",
			}),
		}),
		getIconsFromOrganization: builder.query({
			query: (id) => ({
				url: `organization/${id}/icons`,
				method: "GET",
			}),
			providesTags: (result, error, id) => [{ type: "Icons", id }],
		}),
		createOrganization: builder.mutation({
			query: (data) => ({
				url: `organization`,
				method: "POST",
				body: data,
			}),
		}),
		updateOrganization: builder.mutation({
			query: ({ id, patch }) => ({
				url: `organization/${id}`,
				method: "PATCH",
				body: patch,
			}),
		}),
		deleteOrganization: builder.mutation({
			query: (id) => ({
				url: `organization/${id}`,
				method: "DELETE",
			}),
		}),
	}),
});

// Export hooks for usage in functional components
export const {
	useGetOrganizationsQuery,
	useGetOrganizationQuery,
	useGetWorkplacesFromOrganizationQuery,
	useGetWorkflowsFromOrganizationQuery,
	useGetARObjsFromOrganizationQuery,
	useGetIconsFromOrganizationQuery,
	useGetEnvironmentsFromOrganizationQuery,
	useCreateOrganizationMutation,
	useDeleteOrganizationMutation,
	useUpdateOrganizationMutation,
} = organizationApi;
