import React, {useEffect, useState} from "react";
import {
	showModal,
	getModalID,
	setModalData,
} from "../../app/slices/appStateSlice";
import {useSelector, useDispatch} from "react-redux";

import Modal from "react-bootstrap/Modal";

const CustomModal = ({id, title, children, okButton, ...props}) => {
	var modalId = useSelector(getModalID);
	const [isModalOpen, setIsModalOpen] = useState(modalId === id);

	const dispatch = useDispatch();

	const hideModal = () => {
		dispatch(showModal("none"));
	};

	useEffect(() => {
		setIsModalOpen(modalId === id);
	}, [modalId, id]);

	return (
		<>
			{(modalId !== null || modalId !== "none") && (
				<Modal
					animation={false}
					show={isModalOpen}
					onHide={hideModal}
					centered
					{...props}
				>
					<Modal.Header closeButton className="mb-2">
						<Modal.Title className="capitalize">
							{title}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>{children}</Modal.Body>
					<Modal.Footer className="mt-3">
						<button
							type="button"
							className="btn btn-secondary"
							data-dismiss="modal"
							aria-label="Close"
							onClick={hideModal}
						>
							Cancel
						</button>
						{okButton}
					</Modal.Footer>
				</Modal>
			)}
		</>
	);
};

const handleModal = (dispatch, modalType, modalAction, modalData) => {
	dispatch(
		setModalData({
			type: modalType,
			data: modalData,
			action: modalAction,
		})
	);
	dispatch(showModal(modalAction.toLowerCase()));
};

export {handleModal, CustomModal};
