import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ErrorHandler from "../../app/ErrorHandler";
import { useDeleteEnvironmentMutation } from "../../app/services/environment";
import { useGetEnvironmentsFromOrganizationQuery } from "../../app/services/organization";
import {
	getCurrentOrganization,
	setModalData,
	showModal,
} from "../../app/slices/appStateSlice";
import List from "../../components/list/List";
import { Titlebar, TitleType } from "../../layouts/Content";
import ListOverviewTemplate from "../../layouts/ListOverviewTemplate";
import { ModalTypes } from "../../modules/Modals/ConfirmDeleteModal";

const EnvironmentOverview = () => {
	const rootPath = "/environments";
	const navigate = useNavigate();
	const currentOrg = useSelector(getCurrentOrganization);
	const {
		refetch,
		isLoading,
		error,
		data: environments,
		isSuccess: loadEnvsSuccess,
	} = useGetEnvironmentsFromOrganizationQuery(currentOrg && currentOrg.id);
	const dispatch = useDispatch();
	const { isSuccess: deleteSuccess } = useDeleteEnvironmentMutation();

	useEffect(() => {}, [currentOrg]);

	const handleNewClick = () => {
		navigate(rootPath + "/new");
	};

	const handleRowClick = (e, item) => {
		if (e.target.type === "button" || e.target.type === "submit") return;
		handleEdit(item);
	};

	const handleEdit = (item) => {
		navigate(rootPath + "/" + item.id);
	};

	const handleDelete = (item) => {
		dispatch(showModal("delete"));
		dispatch(setModalData({ type: ModalTypes.Environment, data: item }));
	};

	useEffect(() => {
		!isLoading && error && ErrorHandler(error);
	}, [error, isLoading]);

	useEffect(() => {
		refetch();
	}, [deleteSuccess, refetch]);

	return (
		<>
			<Titlebar type={TitleType.Overview} title="Environment" />
			<ListOverviewTemplate
				addCallback={handleNewClick}
				isLoading={isLoading}
			>
				{/* {loadSuccess && !error && ( */}
				{loadEnvsSuccess && (
					<List id="envs">
						<List.Header>
							<List.Header.Cell>#</List.Header.Cell>
							<List.Header.Cell>
								Environment Name
							</List.Header.Cell>
						</List.Header>
						{environments.length > 0 &&
							environments.map((item, index) => (
								<List.Item
									key={index}
									onClick={(e) => handleRowClick(e, item)}
									classes="clickable"
									buttons={[
										<List.Item.Button
											type="Edit"
											key="edit-button"
											callback={() => handleEdit(item)}
										/>,
										<List.Item.Button
											type="Delete"
											key="delete-button"
											callback={() => handleDelete(item)}
										/>,
									]}
								>
									<List.Item.Content key="index">
										{index + 1}
									</List.Item.Content>
									<List.Item.Content title key="title">
										{item.name}
									</List.Item.Content>
								</List.Item>
							))}
					</List>
				)}
			</ListOverviewTemplate>
		</>
	);
};

export default EnvironmentOverview;
