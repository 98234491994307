import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getModalData, showModal } from "../../app/slices/appStateSlice";
import { SmallListItem } from "../../components/listItems/SmallListItem";
import { CustomModal } from "../../components/modal/Modal";

const SelectUserModal = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const modal = useSelector(getModalData);

	const handleEdit = () => {
		dispatch(showModal("none"));
		navigate(modal.navigate);
	};

	return (
		<>
			{modal.data && (
				<CustomModal
					id={"select-users"}
					title={"Users in " + modal.data.name}
					className="elevation-1"
					okButton={
						<button
							type="button"
							className="btn btn-primary"
							onClick={handleEdit}
						>
							<i className="icon"></i>
							Edit
						</button>
					}
				>
					{modal.data.users && modal.data.users.length > 0 ? (
						<>
							<div className="mb-5">
								<p>Users assigned to this group:</p>
							</div>
							{modal.data.users.map((item, index) => (
								<SmallListItem
									key={"list-item-" + index}
									data={item}
								/>
							))}
						</>
					) : (
						<div className="mb-5">
							<p>No users assigned to this group yet.</p>
						</div>
					)}
				</CustomModal>
			)}
		</>
	);
};

export default SelectUserModal;
