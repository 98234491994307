import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMessages, SuccessMessages } from "../../../app/Messages";
import {
	useCreateARObjectMutation,
	useUpdateARObjectMutation,
} from "../../../app/services/ARObject";
import {
	useCreateIconMutation,
	useUpdateIconMutation,
} from "../../../app/services/icon";
import {
	useGetARObjsFromOrganizationQuery,
	useGetIconsFromOrganizationQuery,
} from "../../../app/services/organization";
import {
	getCurrentOrganization,
	getModalData,
	showModal,
} from "../../../app/slices/appStateSlice";
import {
	NotificationType,
	startNotification,
} from "../../../app/slices/notifactionStateSlice";
import { CustomModal } from "../../../components/modal/Modal";
import { ModalTypes } from "../ConfirmDeleteModal";
import { createEntityWithImage } from "../../../app/Helpers";
import Dropzone from "../../../components/dragndrop/Dropzone";

const EditImageModal = ({ data }) => {
	const dispatch = useDispatch();
	const modal = useSelector(getModalData);
	const currentOrg = useSelector(getCurrentOrganization);
	const [selection, setSelection] = useState(null);
	const [skip, setSkip] = useState(true);

	const { refetch: refetchAR } = useGetARObjsFromOrganizationQuery(
		currentOrg.id,
		{ skip }
	);

	const [createIcon] = useCreateIconMutation();
	const [updateIcon] = useUpdateIconMutation();
	const [createARObj] = useCreateARObjectMutation();
	const [updateARObject] = useUpdateARObjectMutation();

	const { refetch: refetchIcons } = useGetIconsFromOrganizationQuery(
		currentOrg.id,
		{ skip }
	);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	useEffect(() => {
		currentOrg && setSkip(false);
	}, [currentOrg]);

	const handleSave = async (formData) => {
		var postData = formData;

		postData = { ...postData, id: data.id, organization: currentOrg.id };

		switch (modal.type) {
			case ModalTypes.ARObject:
				await createEntityWithImage({
					type: "arobject",
					createFn: createARObj,
					updateFn: updateARObject,
					onSuccess,
					onError,
					postData,
					object: selection,
					patchAttribute: "objectId",
				});

				break;
			default:
			case ModalTypes.Icon:
				await createEntityWithImage({
					type: modal.type,
					createFn: createIcon,
					updateFn: updateIcon,
					onSuccess,
					onError,
					postData,
					object: selection,
					patchAttribute: "imageId",
				});
				break;
		}
	};

	const onError = (error) => {
		console.error(error);
		dispatch(
			startNotification({
				message: error.message,
				type: NotificationType.ERROR,
			})
		);
	};

	const onSuccess = () => {
		if (modal.type === ModalTypes.ARObject) {
			refetchAR();
		} else {
			refetchIcons();
		}

		dispatch(showModal("none")); // hide modal
		dispatch(
			// show success notification
			startNotification({
				message: SuccessMessages.SAVE,
				type: NotificationType.SUCCESS,
			})
		);
	};

	const handleImageSelect = (file) => {
		setSelection(file);
	};

	return (
		<>
			{data && (
				<CustomModal
					id="edit"
					title={"Edit " + data.name}
					okButton={
						<button
							type="button"
							className="btn btn-primary"
							onClick={handleSubmit(handleSave)}
						>
							<i className="icon"></i>
							Save
						</button>
					}
				>
					<form className="fullWidth">
						<div className="mb-3 mt-3 pb-3">
							<div className={`label-container`}>
								<label htmlFor={"name"}>{"Icon Name"}</label>
							</div>
							<div
								className={`input-container ${
									errors["name"] && "error"
								}`}
							>
								<input
									name={"name"}
									type={"text"}
									className={`form-control mr-2 centered ${
										errors["name"] && "error"
									}`}
									defaultValue={data.name ?? ""}
									{...register("name", {
										required: ErrorMessages.REQUIRED,
									})}
								/>
								{errors && errors["name"] && (
									<span className="error">
										{errors["name"]?.message}
									</span>
								)}
							</div>
						</div>
						<div className="form-group mb-3 mt-3 pb-3">
							<label>File</label>
							<Dropzone
								callback={(file) => handleImageSelect(file)}
								file={selection ? selection : data}
							/>
						</div>
					</form>
				</CustomModal>
			)}
		</>
	);
};

export default EditImageModal;
