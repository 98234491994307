import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useOutletContext} from "react-router-dom";
import ErrorHandler from "../../../app/ErrorHandler";
import {useGetOrganizationsQuery, useGetWorkflowsFromOrganizationQuery} from "../../../app/services/organization";
import {getCurrentOrganization, setCurrentOrganization} from "../../../app/slices/appStateSlice";
import List from "../../../components/list/List";
import ListOverviewTemplate from "../../../layouts/ListOverviewTemplate";

const OrganizationSelectionItem = (organization) => {
	return(
		<>
			{organization.id}{organization.name}
		</>
	)
}

const WorkflowOverviewViewonly = () => {
	const navigate = useNavigate();
	const currentOrg = useSelector(getCurrentOrganization);
	const dispatch = useDispatch();
	const { data: organizations } =
		useGetOrganizationsQuery();

	const [navBarExtensions, setNavbarExtensions] = useOutletContext();

	const [items, setItems] = useState([]);
	const [workflowGroups, setWorkflowGroups] = useState([]);
	const [skip, setSkip] = useState(true);
	const {
		data: workflowData,
		isLoading,
		error,
		isSuccess: loadSuccess,
	} = useGetWorkflowsFromOrganizationQuery(currentOrg && currentOrg.id, {
		skip,
	});

	useEffect(() => {
		let id = localStorage.getItem("selected-org");
		if(organizations) {
			if (id === undefined || id === "undefined" || id === null) {
				dispatch(setCurrentOrganization(organizations[0]));
			} else {
				let orgSelection = organizations.find((org) => {
					return org.id === id;
				});
				dispatch(setCurrentOrganization(orgSelection));
			}
			// setNavbarExtensions(
			// 	organizations.map(org =>
			// 		(
			// 			<OrganizationSelectionItem
			// 				organization={org}
			// 			/>
			// 		)
			// 	)
			// );
		}
	}, [organizations, dispatch]);

	useEffect(() => {
		currentOrg && setSkip(false);
	}, [currentOrg]);

	useEffect(() => {
		loadSuccess && setItems(workflowData);
		if(workflowData) {
			let workflowGroupDataReduced = workflowData.reduce((acc, curr, index) => {
				if(acc[curr.workplace._id]) {
					acc[curr.workplace._id].push(curr);
				} else {
					acc[curr.workplace._id] = [curr];
				}
				return acc;
			}, {});
			workflowGroupDataReduced = Object.values(workflowGroupDataReduced);
			setWorkflowGroups(workflowGroupDataReduced)
		}

		!isLoading && error && ErrorHandler(error);
	}, [error, isLoading, loadSuccess, workflowData]);

	const handleView = (item) => {
		navigate("/view/workflows/" + item.id);
	};

	const handleRowClick = (e, item) => {
		if (e.target.type === "button" || e.target.type === "submit") return;
		handleView(item);
	};

	const ListGroup = ({workflows}) => {
		let workplaceName = workflows[0].workplace.name;
		let workplaceId = workflows[0].workplace.id;
		return(
			<div className={"drop-container"} key={workplaceId}>
				<div className={"workplace-title"}>
					Workplace: {workplaceName}
				</div>
				{workflows.map((item, index) => (
					<ListItem index={index} item={item} key={"workplace-view-"+item.id}/>
				))}
			</div>
		)
	}

	const ListItem = ({ item, index }) => {
		return (
			<List.Item
				onClick={(e) => handleRowClick(e, item)}
				classes={"clickable"}
				key={item.id}
				buttons={[]}
			>
				<List.Item.Content key="index">{index + 1}</List.Item.Content>
				<List.Item.Content title key="name">
					{item.name}
				</List.Item.Content>
			</List.Item>
		);
	};
	return (
		<>
			<ListOverviewTemplate isLoading={isLoading}>
				{items && items.length > 0 ? (
					<List id="workflows">
						{
							workflowGroups.map(workflowGroup => (
								<ListGroup workflows={workflowGroup} key={"workplace-group-"+workflowGroup[0].workplace.id}/>
							))
						}
					</List>
				) : (
					<p>No workflows found.</p>
				)}
			</ListOverviewTemplate>
		</>
	);
};

export default WorkflowOverviewViewonly;
