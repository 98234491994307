import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SuccessMessages } from "../../app/Messages";
import {
	useAddUserToGroupMutation,
	useRemoveUserFromGroupMutation,
} from "../../app/services/group";
import { useGetUsersQuery, useGetUserQuery } from "../../app/services/user";
import { setModalData, showModal } from "../../app/slices/appStateSlice";
import {
	NotificationType,
	startNotification,
} from "../../app/slices/notifactionStateSlice";
import List from "../../components/list/List";
import GroupLabels from "../labels/GroupLabels";
import { ModalTypes } from "../Modals/ConfirmDeleteModal";
import _ from "lodash";

const UserListItem = ({ index, item }) => {
	const dispatch = useDispatch();
	const [editMode, setEditMode] = useState(false);
	const [userGroups, setUserGroups] = useState(
		item.groups ? item.groups : {}
	);
	const { isSuccess: loadSuccess } = useGetUsersQuery();
	const { data: oldUserData } = useGetUserQuery(item.id);
	const [addUserToGroup] = useAddUserToGroupMutation();
	const [removeUserFromGroup] = useRemoveUserFromGroupMutation();

	const handleDelete = (item) => {
		dispatch(showModal("delete"));
		dispatch(
			setModalData({
				type: ModalTypes.User,
				data: { ...item, name: item.email },
			})
		);
	};

	const handleEdit = () => {
		setEditMode(true);
	};

	const handleSave = async () => {
		const addedGroups = _.differenceBy(
			userGroups,
			oldUserData.groups,
			"id"
		);
		const removedGroups = _.differenceBy(
			oldUserData.groups,
			userGroups,
			"id"
		);

		const addedGroupPromises = addedGroups.map((group) =>
			addUserToGroup({ userID: item.id, groupID: group.id })
		);
		const removedGroupPromises = removedGroups.map((group) =>
			removeUserFromGroup({ userID: item.id, groupID: group.id })
		);
		await Promise.all([...addedGroupPromises, ...removedGroupPromises]);

		dispatch(
			startNotification({
				message: SuccessMessages.SAVE,
				type: NotificationType.SUCCESS,
			})
		);
		setEditMode(false);
	};

	const handleCancel = () => {
		setEditMode(false);
	};

	useEffect(() => {
		setUserGroups(item.groups);
	}, [item, editMode]);

	return (
		<>
			{loadSuccess && item && (
				<List.Item
					key={"user-" + item.id}
					buttons={[
						!editMode ? (
							<List.Item.Button
								type={"Edit"}
								key="edit-button"
								callback={() => handleEdit(item)}
							/>
						) : (
							<>
								<List.Item.Button
									type={"Save"}
									key="save-button"
									callback={() => handleSave(item)}
								/>
								<List.Item.Button
									type={"Cancel"}
									key="cancel-button"
									callback={() => handleCancel()}
								/>
							</>
						),
						<List.Item.Button
							type="Delete"
							key="delete-button"
							callback={() => handleDelete(item)}
						/>,
					]}
				>
					<List.Item.Content key="index">
						{index + 1}
					</List.Item.Content>
					<List.Item.Content title key="name">
						{editMode ? (
							<div className="form-group">
								<input
									className="form-control"
									id={"user-" + item.id}
									type="text"
									defaultValue={item.email}
								/>
							</div>
						) : (
							<>{item.email}</>
						)}
					</List.Item.Content>
					<List.Item.Content className="hide-onPhone" key="groups">
						<GroupLabels
							editable={editMode}
							groups={userGroups}
							user={item}
							setGroups={setUserGroups}
						/>
					</List.Item.Content>
				</List.Item>
			)}
		</>
	);
};

export default UserListItem;
