import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useDeleteStepMutation } from "../../app/services/step";
import DragItem from "../../components/dragndrop/DragItem";
import StepContent from "./StepContent";

import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { useDispatch } from "react-redux";
import { setModalData, showModal } from "../../app/slices/appStateSlice";
import { ModalTypes } from "../Modals/ConfirmDeleteModal";
import { useParams } from "react-router-dom";
import WorkflowStep from "./WorkflowStep";
import WorkflowEditStep from "./WorkflowEditStep";

const StepAccordion = ({ steps, workflowId, activeStep, ...props }) => {
	const [editMode, setEditMode] = useState(false);
	const [{ isSuccess: deleteSuccess }] = useDeleteStepMutation();

	const dispatch = useDispatch();

	const { id } = useParams();

	useEffect(() => {
		setEditMode(false);
	}, [steps]);

	useEffect(() => {}, [activeStep, deleteSuccess]);

	const handleEditStep = () => {
		setEditMode(true);
	};
	const handleCancelEdit = (e) => {
		e.stopPropagation();
		e.preventDefault();
		setEditMode(false);
	};

	const ToggleAccordionButton = ({ eventKey, callback }) => {
		const executeAccordeonClick = useAccordionButton(eventKey, () => {
			callback && callback(eventKey);
		});

		return (
			<button
				type="button"
				className="btn btn-sm btn-secondary mr-2"
				title="Edit"
				onClick={
					eventKey !== activeStep ? executeAccordeonClick : callback
				}
				eventkey={eventKey}
			>
				<i className="icon"></i>
				Edit
			</button>
		);
	};
	const AccordionItem = ({ step, index, buttons }) => {
		return (
			<Accordion.Item eventKey={index}>
				<DragItem item={step} index={index}>
					<Accordion.Header
						className={"info-box"}
						onClick={() => setEditMode(false)}
					>
						<span className="title">{step.title}</span>
						{/* <span className="subtitle">ID: {step.id}</span> */}
					</Accordion.Header>
					{buttons}
				</DragItem>
				<Accordion.Body>
					<StepContent
						workflowId={workflowId}
						step={step}
						editable={editMode}
						saveStepCallback={(e, item) => {
							props.saveStepCallback(e, item);
							setEditMode(false);
						}}
						handleCancel={(e) => handleCancelEdit(e)}
					/>
					{/*{*/}
					{/*	!editMode ?*/}
					{/*		(*/}
					{/*			<WorkflowStep*/}
					{/*				stepId={step.id}*/}
					{/*			/>*/}
					{/*		) : (*/}
					{/*			<WorkflowEditStep*/}
					{/*				stepId={step.id}*/}
					{/*				workflowId={workflowId}*/}
					{/*			/>*/}
					{/*		)*/}
					{/*}*/}
				</Accordion.Body>
			</Accordion.Item>
		);
	};

	const handleDeleteStep = (e, step) => {
		e.preventDefault();
		dispatch(
			setModalData({
				type: ModalTypes.Step,
				data: { name: step.title, id: step.id, parentId: id },
			})
		);
		dispatch(showModal("delete"));
	};

	return (
		<>
			{steps &&
				steps.map((step, index) => {
					return (
						<AccordionItem
							key={index}
							step={step}
							index={index}
							workflowId={workflowId}
							buttons={
								<div className="workflow-step-button">
									<ToggleAccordionButton
										eventKey={index}
										callback={(e) => handleEditStep(e)}
									/>
									<button
										className="btn btn-sm mr-2 btn-secondary"
										onClick={(e) =>
											props.cloneStepCallback(e, step)
										}
									>
										<i className="icon"></i>
										Clone
									</button>
									<button
										className="btn btn-sm btn-danger"
										onClick={(e) =>
											handleDeleteStep(e, step)
										}
									>
										<i className="icon"></i>
										Delete
									</button>
								</div>
							}
						/>
					);
				})}
		</>
	);
};

export default StepAccordion;
