import React, { useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import DropContainer from "../../../components/dragndrop/DropContainer";
import StepAccordion from "../../../modules/workflowSteps/StepAccordion";
import CreateStep from "./CreateStep";
import Accordion from "react-bootstrap/Accordion";
import { createEntityWithImage } from "../../../app/Helpers";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentOrganization } from "../../../app/slices/appStateSlice";
import {
	NotificationType,
	startNotification,
} from "../../../app/slices/notifactionStateSlice";
import { SuccessMessages } from "../../../app/Messages";
import {
	useCreateFileMutation,
	useCreateMediafileMutation,
	useDeleteFileMutation,
	useUpdateFileMutation,
	useUpdateMediafileMutation,
} from "../../../app/services/mediafile";
import { useGetStepsOfWorkflowQuery } from "../../../app/services/workflow";
import _ from "lodash";
import { useCloneStepMutation } from "../../../app/services/step";
import FullpageSpinner from "../../../modules/spinner/FullpageSpinner";
import {
	setRecordingContext,
	getRecordingContext,
} from "../../../app/contexts";
import { encodeMP3 } from "../../../modules/voiceEditor/VideoComponent";

const StepList = ({
	workflowId,
	workflowSteps,
	saveWorkflowCb,
	setStepsCb,
	activeStepId,
}) => {
	const { data: workflowStepsFromDB, refetch: refetchSteps } =
		useGetStepsOfWorkflowQuery(workflowId);
	const [steps, setSteps] = useState(workflowSteps);
	const [recording, setRecording] = useState(null);
	const [activeStep, setActiveStep] = useState(null);
	const dispatch = useDispatch();
	const currentOrg = useSelector(getCurrentOrganization);

	const [createFile] = useCreateFileMutation();
	const [updateFile] = useUpdateFileMutation();
	const [deleteFile] = useDeleteFileMutation();

	const [createMediafile] = useCreateMediafileMutation();
	const [updateMediafile] = useUpdateMediafileMutation();

	const [cloneStep, { isLoading }] = useCloneStepMutation();

	useEffect(() => {
		// if (activeStepId && steps) {
		// 	var activeStepIndex = steps.findIndex(
		// 		(step) => step.id === activeStepId
		// 	);
		// 	setActiveStep(activeStepIndex);
		// } else if (activeStepId === null) {
		// 	setActiveStep(-1);
		// }
	}, [activeStepId, steps]);

	useEffect(() => {
		workflowStepsFromDB && setSteps(workflowStepsFromDB);
		refetchSteps();
	}, [workflowSteps, workflowStepsFromDB, refetchSteps]);

	const handleDragStart = () => {
		// collapse expanded accordeon on drag start
		const stepList = document.getElementsByClassName("accordion-button");
		const stepList2 = document.getElementsByClassName("accordion-collapse");

		for (let item of stepList) {
			item.classList.add("collapsed");
		}
		for (let item2 of stepList2) {
			item2.classList.remove("show");
		}
	};

	const onDragEnd = (result) => {
		const newItems = Array.from(steps);
		const [removed] = newItems.splice(result.source.index, 1);
		newItems.splice(result.destination.index, 0, removed);
		setSteps(newItems);
		// saveWorkflowCb({ steps: newItems });
		setStepsCb(newItems);
	};

	const handleDeleteStep = (e, step) => {
		e.preventDefault();
		var newSteps = steps.filter((item) => item !== step);
		setSteps(newSteps);
		setStepsCb(newSteps);
	};

	const onSaveError = (error) => {
		dispatch(
			startNotification({
				message: error.message,
				type: NotificationType.ERROR,
			})
		);
	};

	const handleSaveStep = async (e, step) => {
		e.preventDefault();
		const mediafile = step.mediafiles
			? step.mediafiles.length > 0
				? step.mediafiles[step.mediafiles.length - 1]
				: step.mediafiles
			: null;

		if (recording) {
			let mp3Blob = await encodeMP3(recording);
			// let formData = new FormData();
			let postData = {
				name: "recording",
				organization: currentOrg.id,
				step: step.id,
				type: "audio/mpeg",
			};

			await createEntityWithImage({
				type: "mediafile",
				createFn: createMediafile,
				updateFn: updateMediafile,
				onSuccess: onSaveStepSuccess,
				onError: onSaveError,
				postData,
				object: mp3Blob,
				patchAttribute: "fileId",
			});
		}

		if (mediafile) {
			let postData = {
				name: mediafile.name,
				organization: currentOrg.id,
				step: step.id,
				type: mediafile.type,
			};

			createEntityWithImage({
				type: "mediafile",
				createFn: createMediafile,
				updateFn: updateMediafile,
				onSuccess: onSaveStepSuccess,
				onError: onSaveError,
				postData,
				object: mediafile,
				patchAttribute: "fileId",
			});

			let index = _.findIndex(steps, { id: step.id });
			let updatedSteps;
			if (index !== -1) {
				const updatedItems = [...steps]; // Create new array to avoid mutating state directly
				updatedItems[index] = step;
				updatedSteps = updatedItems;
			} else if (steps) {
				updatedSteps = [...steps, step];
			} else {
				updatedSteps = [step];
			}
			saveWorkflowCb({ steps: updatedSteps });
		} else {
			if (step.files) {
				handleAdditionalFileSave(step);
			}

			let index = _.findIndex(steps, { id: step.id });
			let updatedSteps;
			if (index !== -1) {
				const updatedItems = [...steps]; // Create new array to avoid mutating state directly
				updatedItems[index] = step;
				updatedSteps = updatedItems;
			} else {
				updatedSteps = [...steps, step];
			}
			saveWorkflowCb({ steps: updatedSteps });
		}
	};

	const handleFileDeletion = async (fileId) => {
		await deleteFile(fileId);
	};

	const handleAdditionalFileSave = async (step) => {
		var oldStepState = workflowStepsFromDB.find(
			(dbStep) => dbStep.id === step.id
		);
		var oldFileState = oldStepState.files;
		var newFiles = step.files.filter(
			(currentObj) =>
				!oldFileState.some((oldObj) => currentObj.id === oldObj.id)
		);

		const deletedFiles = oldFileState.filter(
			(oldObj) =>
				!step.files.some((currentObj) => currentObj.id === oldObj.id)
		);

		// Create an array of promises for each deleteFile call
		const deletionPromises = deletedFiles.map((file) =>
			handleFileDeletion(file.id)
		);

		// Wait for all promises to resolve
		await Promise.all(deletionPromises);

		newFiles.forEach((file) => {
			let postData = {
				name: file.name,
				organization: currentOrg.id,
				step: step.id,
				type: file.type,
			};

			createEntityWithImage({
				type: "stepfile",
				createFn: createFile,
				updateFn: updateFile,
				onSuccess: onSaveStepSuccess,
				onError: onSaveError,
				postData,
				object: file,
				patchAttribute: "fileId",
			});
		});
	};

	const handleCloneStep = (e, step) => {
		e.preventDefault();
		cloneStep(step.id).then(() => {
			refetchSteps();
			startNotification({
				message: SuccessMessages.CLONE,
				type: NotificationType.SUCCESS,
			});
		});
	};

	const onSaveStepSuccess = () => {
		dispatch(
			// show success notification
			startNotification({
				message: SuccessMessages.UPLOAD,
				type: NotificationType.SUCCESS,
			})
		);
	};

	const handleSelection = (activeIndex) => {
		setActiveStep(activeIndex);
	};

	const handleSetRecording = (file) => {
		setRecording(file);
	};

	return (
		<>
			{isLoading && (
				<div className="overlay fullwidth">
					<FullpageSpinner text="Cloning" />
				</div>
			)}
			<DragDropContext
				onDragEnd={onDragEnd}
				onDragStart={handleDragStart}
			>
				{steps && (
					<Accordion onSelect={handleSelection}>
						<DropContainer id={workflowId} invisible={true}>
							{steps.length > 0 ? (
								<>
									<setRecordingContext.Provider
										value={handleSetRecording}
									>
										<getRecordingContext.Provider
											value={recording}
										>
											<StepAccordion
												workflowId={workflowId}
												activeStep={activeStep}
												steps={steps}
												cancelCallback={null}
												handleDeleteStep={(e, item) =>
													handleDeleteStep(e, item)
												}
												saveStepCallback={(e, step) =>
													handleSaveStep(e, step)
												}
												cloneStepCallback={(e, step) =>
													handleCloneStep(e, step)
												}
											/>
										</getRecordingContext.Provider>
									</setRecordingContext.Provider>
								</>
							) : (
								<>
									{workflowId !== undefined && (
										<div className="warning-dialogue">
											<span>
												This workflow does not contain
												any steps yet.
											</span>
										</div>
									)}
								</>
							)}
						</DropContainer>
					</Accordion>
				)}
			</DragDropContext>
			<CreateStep
				id={workflowId}
				saveStepCb={(e, step) => handleSaveStep(e, step)}
			/>
		</>
	);
};

export default StepList;
