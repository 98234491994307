import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setModalData, showModal } from "../app/slices/appStateSlice";

const Details = ({ children, saveFunction, modalData }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const navigateTo =
		modalData && modalData.navigate ? modalData.navigate : -1;

	const handleCancel = (e) => {
		e.preventDefault();
		navigate(navigateTo);
	};

	const handleDelete = (e) => {
		e.preventDefault();
		dispatch(setModalData(modalData));
		dispatch(showModal("delete"));
	};

	return (
		<>
			{children}
			<div className="row">
				<div className="col-12 mt-5">
					<button
						onClick={handleCancel}
						className="btn btn-lg btn-secondary"
					>
						Cancel
					</button>
					{modalData && Object.keys(modalData.data).length !== 0 && (
						<button
							className="btn btn-lg btn-danger float-right mr-2"
							onClick={handleDelete}
						>
							<i className="icon"></i>Delete
						</button>
					)}
					{saveFunction && (
						<button
							className="btn btn-lg btn-cta float-right mr-2"
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								saveFunction();
							}}
						>
							<i className="icon"></i>Save
						</button>
					)}
				</div>
			</div>
		</>
	);
};

export default Details;
