import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import AccordeonCard from "./AccordeonCard";
import Dropzone from "../../components/dragndrop/Dropzone";
import { Mediafile } from "../mediafile/Mediafile";
import { useCreateStepMutation } from "../../app/services/step";
import { AudioComponent, VideoComponent } from "../voiceEditor/VideoComponent";
import {
	useGetLanguagesQuery,
	useGetWorkflowQuery,
} from "../../app/services/workflow";
import { StepDescription } from "../step/StepDescription";
import { getRecordingContext, setRecordingContext } from "../../app/contexts";

// new step = no step,editABLE
// edit step = step, editABLE
// view = step
const StepContent = ({ step, workflowId, editable = false, ...props }) => {
	const { register, getValues, setValue } = useForm();
	const [files, setFiles] = useState(step.files);
	const [mediafiles, setMediafiles] = useState(step.mediafile);
	const [currentStep, setCurStep] = useState(step);
	const [currentMediafile, setCurMediafile] = useState({});
	const [createStep] = useCreateStepMutation();
	const { data: languagesData } = useGetLanguagesQuery();
	const [languageInfo, setLanguageInfo] = useState({
		stt: false,
		tts: false,
	});
	const { data: workflowData } = useGetWorkflowQuery(workflowId);

	const inputFile = useRef(null);

	const setRecordCallback = useContext(setRecordingContext);
	const getRecordCallback = useContext(getRecordingContext);

	const handleAudioChange = (newAudio) => {
		setRecordCallback(newAudio);
	};

	useEffect(() => {
		step && setMediafiles(step.mediafile);
		setCurStep(step);
		setFiles(step.files);

		if (step.mediafiles) {
			const file = step.mediafiles
				.filter(
					(file) =>
						file.type === "video/mp4" || file.type === "image/jpeg"
				)
				.pop();
			setCurMediafile(file);
		}
	}, [step]);

	useEffect(() => {
		if (languagesData && workflowData) {
			const matchingElement = languagesData.find(
				(element) => element.languageCode === workflowData.language
			);

			if (matchingElement) {
				setLanguageInfo({
					stt: matchingElement.stt,
					tts: matchingElement.tts,
				});
			}
		}
	}, [languagesData, workflowData, step]);

	useEffect(() => {}, []);

	const onSubmit = (e) => {
		e.preventDefault();
		const values = getValues();
		if (!props.newStep) {
			let updatedStep = {
				...step,
				...values,
				mediafiles,
				files,
			};
			props.saveStepCallback(e, updatedStep);
		} else {
			// TODO: unneccessary because its always editmode when you can upload something
			// (I wanted to differenciate between new object / edit existing object)
			let newStep = {
				...values,
			};
			createStep(newStep).then((res) => {
				newStep = { ...res.data, mediafiles: mediafiles };
				props.saveStepCallback(e, newStep);
				setFiles([]);
				setMediafiles([]);
			});
		}
	};

	// const hasMediafile = (currentStep) => {
	// 	return currentStep.mediafiles && currentStep.mediafiles.length > 0;
	// };

	const handleAddFileDialog = (e) => {
		e.preventDefault();
		inputFile.current.click();
	};

	const handleChangeInput = () => {
		if (inputFile.current.files) {
			let fileList = [...files];
			Array.from(inputFile.current.files).map((newFile) =>
				fileList.push(newFile)
			);

			setFiles(fileList);
		}
	};

	const handleRemoveFile = (index) => {
		let temporaryFileList = [...files];
		temporaryFileList.splice(index, 1);
		setFiles(temporaryFileList);
	};
	useEffect(() => {}, [files]);

	const lastImageOrVideo = step.mediafiles
		? step.mediafiles
				.filter(
					(item) =>
						item.type === "image/jpeg" || item.type === "video/mp4"
				)
				.pop()
		: {};

	const MediaStuff = () => {
		return (
			<>
				<>
					<div className="flex flex-column col-12">
						<div className="flex flex-row container">
							<div className="col-6">
								{lastImageOrVideo?.type === "video/mp4" ? (
									<VideoComponent
										workflowId={workflowId}
										step={step}
										editmode={editable}
										languageInfo={languageInfo}
									/>
								) : (
									<Mediafile file={lastImageOrVideo} />
								)}
							</div>
							<div className="col-6 flex flex-column">
								<div className="mb-5">
									<label>Description: </label>
									<br />
									{currentStep.description}
								</div>
								<AudioComponent
									workflowId={workflowId}
									step={step}
									editmode={editable}
									languageInfo={languageInfo}
									setAudio={handleAudioChange}
									recording={getRecordCallback}
								/>
							</div>
						</div>
					</div>
				</>
			</>
		);
	};

	return (
		<>
			{currentStep && languagesData && (
				<AccordeonCard>
					{editable && (
						<>
							{currentStep &&
								Object.keys(currentStep).length === 0 && (
									<div className="title-container mb-3">
										<h5>Add new Step</h5>
									</div>
								)}
							<AccordeonCard.Name>
								<label>Step Title</label>
								<input
									name="step-title"
									className="form-control "
									type="text"
									placeholder="Step Name"
									defaultValue={currentStep.title}
									{...register("title")}
								/>
							</AccordeonCard.Name>
						</>
					)}
					{editable ? (
						<>
							<AccordeonCard.Description
								mediafile={
									<>
										<label>Step Explanation</label>
										<Dropzone
											file={currentMediafile}
											isArray
											callback={setMediafiles}
										/>
									</>
								}
								description={
									<>
										<StepDescription
											register={register}
											text={currentStep.description}
											setValue={setValue}
											languageInfo={languageInfo}
											step={step}
											getValues={getValues}
										/>
										<AudioComponent
											workflowId={workflowId}
											step={step}
											editmode={editable}
											languageInfo={languageInfo}
											setAudio={handleAudioChange}
											recording={getRecordCallback}
										/>
									</>
								}
							></AccordeonCard.Description>
						</>
					) : (
						<>
							{languageInfo && (
								<AccordeonCard.Description
									mediafile={
										<>
											<div className="mediafile-preview">
												<label>Step Explanation</label>
												<MediaStuff />
											</div>
										</>
									}
									fullWidth={true}
								/>
							)}
						</>
					)}
					{files && (
						<AccordeonCard.Files
							files={files}
							removeFileCallback={handleRemoveFile}
							editable={editable}
						>
							{editable && (
								<div className="flex">
									<button
										className="btn btn-md btn-tertiary"
										onClick={(e) => handleAddFileDialog(e)}
									>
										<i className="icon">+</i>
										Add Additional File
									</button>
									<input
										type="file"
										id="file"
										ref={inputFile}
										multiple="multiple"
										style={{ display: "none" }}
										onChange={handleChangeInput}
									/>
								</div>
							)}
						</AccordeonCard.Files>
					)}
					{editable && (
						<AccordeonCard.Buttons>
							<>
								<button
									className="btn btn-md btn-secondary float-left"
									onClick={props.handleCancel}
								>
									Cancel
								</button>
								<button
									className="btn btn-md btn-cta float-right"
									onClick={(event) => {
										onSubmit(event);
									}}
								>
									Save Step
									<i className="icon"></i>
								</button>
							</>
						</AccordeonCard.Buttons>
					)}
				</AccordeonCard>
			)}
		</>
	);
};

export default StepContent;
