import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import ErrorHandler from "../../app/ErrorHandler";
import {
	useCreateUserMutation,
	useGetUsersQuery,
} from "../../app/services/user";
import {
	NotificationType,
	startNotification,
} from "../../app/slices/notifactionStateSlice";
import List from "../../components/list/List";
import UserListItem from "./UserListItem";
import { ErrorMessages } from "../../app/Messages";

const UserList = ({ data, newLine = false, toggleNewLine }) => {
	const dispatch = useDispatch();
	const {
		error,
		isLoading,
		isSuccess: loadSuccess,
		refetch,
	} = useGetUsersQuery();
	const [createUser, { isSuccess: updateSuccess }] = useCreateUserMutation();
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		clearErrors,
	} = useForm();

	useEffect(() => {
		!isLoading && error && ErrorHandler(error);
	}, [error, isLoading, data, updateSuccess]);

	const handleCreate = (data) => {
		var postData = data;
		if (errors.length > 0) {
			dispatch(
				startNotification({
					message: errors,
					type: NotificationType.ERROR,
				})
			);
			return;
		}
		createUser(postData).then((res) => {
			if (res?.error) {
				dispatch(
					startNotification({
						message: res.error.error,
						type: NotificationType.ERROR,
					})
				);
			} else {
				handleToggleNew(false);
				refetch();
			}
		});
	};

	const handleToggleNew = (show) => {
		toggleNewLine(show);
		if (!show) {
			reset();
			clearErrors();
		}
	};

	const NewUser = () => {
		return (
			<>
				{newLine && (
					<form>
						<List.Item
							buttons={[
								<List.Item.Button
									type="Cancel"
									callback={() => handleToggleNew(false)}
								/>,
								<List.Item.Button
									type="Save"
									callback={handleSubmit(handleCreate)}
								/>,
							]}
						>
							<List.Item.Content>#</List.Item.Content>
							<List.Item.Content>
								<div
									className={`input-container ${
										errors["email"] && "error"
									}`}
								>
									<input
										name={"email"}
										type={"text"}
										placeholder="e-Mail"
										className={`form-control mr-2 centered ${
											errors["email"] && "error"
										}`}
										defaultValue={data.name ?? ""}
										{...register("email", {
											required: ErrorMessages.REQUIRED,
										})}
									/>
									{errors && errors["email"] && (
										<span className="error">
											{errors["name"]?.message}
										</span>
									)}
								</div>
							</List.Item.Content>
							<List.Item.Content> </List.Item.Content>
						</List.Item>
					</form>
				)}
			</>
		);
	};

	return (
		<>
			{loadSuccess && (
				<List id="users">
					<List.Header>
						<List.Header.Cell>#</List.Header.Cell>
						<List.Header.Cell>eMail</List.Header.Cell>
						<List.Header.Cell>
							<span className="hide-onPhone">Groups</span>
						</List.Header.Cell>
					</List.Header>
					{data.map((item, index) => {
						return (
							<UserListItem
								item={item}
								index={index}
								key={item.id}
							/>
						);
					})}
					<NewUser />
				</List>
			)}
		</>
	);
};

export default UserList;
