import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Mediafile } from "../../modules/mediafile/Mediafile";

const Dropzone = ({ callback, file, filetypes, ...props }) => {
	const [droppedFile, setDroppedFile] = useState(null);
	const [fileURL, setFileURL] = useState(null);
	const [allowedFiletypes] = useState(
		filetypes
			? filetypes
			: {
					"image/jpeg": [".jpg"],
					"video/mp4": [".mp4"],
					"audio/mpeg": [".mp3"],
			  }
	);

	useEffect(() => {
		if (file && file.length > 0) {
			setDroppedFile(file[file.length - 1]);
			setFileURL(file[file.length - 1].mediaUrl);
		} else if (file && file.mediaUrl) {
			setDroppedFile(file);
			setFileURL(file.mediaUrl);
		} else if (file && file.thumbnailUrl) {
			setDroppedFile(file);
			setFileURL(file.thumbnailUrl);
		}
	}, [file]);

	useEffect(() => {}, [fileURL]);

	const onDrop = useCallback(
		(acceptedFiles) => {
			// Do something with the files
			var fileToUpload =
				acceptedFiles.length > 0 ? acceptedFiles[0] : acceptedFiles;

			callback(fileToUpload);
			setDroppedFile(fileToUpload);
			if (
				fileToUpload.type === "image/jpeg" ||
				fileToUpload.type === "video/mp4" ||
				fileToUpload.type === "audio/mpeg"
			) {
				setFileURL(URL.createObjectURL(fileToUpload));
			}
		},
		// eslint-disable-next-line
		[callback]
	);

	const removeDroppedFile = (e) => {
		e.stopPropagation();
		setDroppedFile(null);
		setFileURL(null);
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		maxFiles: 1,
		accept: allowedFiletypes,
	});

	const UploadOverlay = () => {
		return (
			<>
				<i className="icon"></i>
				<span>
					Drag & Drop
					<br />
					or click to choose a file
				</span>
			</>
		);
	};

	const DeleteOverlay = () => {
		return (
			<i
				className="icon delete-preview"
				onClick={(e) => removeDroppedFile(e)}
			>
				
			</i>
		);
	};

	return (
		<>
			<section className="container">
				{
					// TODO: gescheit
					droppedFile && droppedFile.type === "video/mp4" ? (
						// If Preview is Video:
						<>
							<div className="dropzone has-file">
								<div className="fullWidth preview-video">
									{/* show previews if there has been a file dropped onto the dropzone / there was an image uploaded before */}
									{droppedFile && fileURL && (
										<Mediafile file={droppedFile} />
									)}
									<>
										{/* show delete btn if theres a file */}
										{/* {fileURL && <DeleteOverlay />} */}
										{<DeleteOverlay />}
									</>
								</div>
							</div>
						</>
					) : (
						<>
							<div
								{...getRootProps({
									className: `dropzone ${
										droppedFile && " has-file"
									}`,
								})}
							>
								{droppedFile && fileURL && (
									<div
										style={{
											backgroundImage: `url(${fileURL})`,
										}}
										className="image-preview-layer"
									></div>
								)}
								<input {...getInputProps()} />
								{isDragActive ? (
									<span>Drop File</span>
								) : (
									<>
										{/* show delete btn if theres a file */}
										{fileURL && <DeleteOverlay />}
										{/*  show upload overlay if its an image */}
										<UploadOverlay />
									</>
								)}
							</div>
						</>
					)
				}
			</section>
		</>
	);
};

export default Dropzone;
