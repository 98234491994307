import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getEndpoint } from "./basics";
const base = getEndpoint();

export const authTenantApi = createApi({

	reducerPath: 'authTenantApi',
	baseQuery: fetchBaseQuery({
		baseUrl: base,
		prepareHeaders: (headers) => {
			headers.set("Content-Type", "application/json");
			headers.set("Accept", "application/json");
			return headers;
		},
		credentials: "include"
	}),
	endpoints: (builder) => ({
		getAuthTenant: builder.query({
			query: (domain) => ({
				url: `auth/tenant?domain=${domain}`,
				method: "GET"
			}),
		}),
	})
});

export const {
	useGetAuthTenant
} = authTenantApi;