import {useGetWorkflowLanguageQuery, useGetWorkflowLanguagesQuery} from "../../app/services/workflow";
import React, {useEffect, useState} from "react";
import Flag from 'react-world-flags';
import {v4 as uuid} from 'uuid';

// Not all languages have a corresp
const LANGUAGE_FLAG_MAP = {
	"en": "gb",
	"hi": "in",
	"sv": "se",
	"af": "za",
	"ar": "ae",
	"zh": "dn",
	"da": "dk",
	"fa": "ir",
	"he": "il",
	"ja": "jp",
	"ko": "kr",
	"ms": "my",
	"ta": "in",
	"te": "in",
	"vi": "vn"
}

/**
 * An individual Flag for the flag chooser
 * @param workflowId
 * @param language
 * @param handleSelect
 * @return {Element}
 * @constructor
 */
const LanguageFlag = ({workflowId, language, handleSelect = (workflowId, selectedLanguage) => {}}) => {
	// Logic to fetch new selected languages and make them available for selection
	const [ languageSelectable, setLanguageSelectable ] = useState(false);
	const [ workflowIsCurrentLanguage, setWorkflowIsCurrentLanguage] = useState(false);
	const { data: workflowLanguageData} = useGetWorkflowLanguageQuery({
		id: workflowId,
		language: language
	});
	useEffect(() => {
		if(workflowLanguageData?.id) {
			setLanguageSelectable(true);
			if(workflowLanguageData.id === workflowId) {
				setWorkflowIsCurrentLanguage(true);
			}
		}
	}, [workflowLanguageData]);

	const triggerSelect = () => {
		if(languageSelectable) {
			handleSelect(workflowLanguageData.id, language);
		}
	}

	let lan = LANGUAGE_FLAG_MAP[language] || language;
	return (
		<div className={
				[
					"base-container",
					"flag-container",
					(languageSelectable ? "" : "inactive"),
					(workflowIsCurrentLanguage ? "selected-container" : "")
				].join(" ")
			 }
			 onClick={triggerSelect}
			 key={workflowId + language + "flag"}
		>
			<Flag
				code={lan}
				height="16px"
				key={workflowId + language + "flagDisplay"}
			/>
		</div>
	);
}

/**
 *
 * @param {string} workflowId The id of the current workflow in display. This will be used to fetch the available languages
 * @param {function(string, string)} handleSelect This function is called, once the workflow id of a different language has been fetched.
 * @return {Element}
 * @constructor
 */
const LanguageFlagChooser = ({workflowId, handleSelect = (workflowId, selectedLanguage) => {}}) => {
	// Simply fetch all available language version of the current workflow
	const { data: languages} = useGetWorkflowLanguagesQuery(workflowId);
	useEffect(() => {}, [languages]);

	return(
		<div className="row" key={workflowId + "flagChooser"}>
			{languages && languages.map(l => (
				<LanguageFlag
					workflowId={workflowId}
					language={l}
					handleSelect={handleSelect}
					key={workflowId + l + "flagChooser"}
				/>
			))}
		</div>
	);
}

export default LanguageFlagChooser;