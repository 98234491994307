import { createSelector, createSlice } from "@reduxjs/toolkit";

export const NotificationType = {
	DEFAULT: "default",
	ERROR: "error",
	SUCCESS: "success",
};

const initialState = {
	open: false,
	title: null,
	message: null,
	timeout: 2000,
	type: NotificationType.DEFAULT,
};

export const notificationStateSlice = createSlice({
	name: "notificationState",
	initialState: initialState,
	reducers: {
		toggleNotification: (state) => {
			state.open = !state.open;
			if (state.open === false) {
				state.message = null;
				state.title = null;
			}
		},
		showNotification: (state, action) => {
			state.open = true;
		},
		hideNotification: (state, action) => {
			state.open = false;
		},
		setNotification: (state, action) => {
			const { open, title, message, timeout, type } = action.payload;
			state.open = open;
			state.title = title;
			state.message = message;
			state.timeout = timeout;
			state.type = type;
		},
		setNotificationTitle: (state, action) => {
			state.title = action.payload;
		},
		setNotificationOpenState: (state, action) => {
			state.open = action.payload;
		},
		setNotificationMessage: (state, action) => {
			state.message = action.payload;
		},
		setNotificationType: (state, action) => {
			state.type = action.payload;
		},
		resetNotification: (state, action) => {
			return initialState;
		},
	},
});

export const {
	toggleNotification,
	setNotification,
	setNotificationTitle,
	setNotificationMessage,
	setNotificationType,
	hideNotification,
	showNotification,
	resetNotification,
} = notificationStateSlice.actions;

export const getNotificationStatus = (state) => state.notificationState.open;
export const getNotificationTimeout = (state) =>
	state.notificationState.timeout;

export const notificationStateSelector = (state) => state.notificationState;

export const getNotificationState = createSelector(
	notificationStateSelector,
	(notificationState) => notificationState
);

export const dismissNotification = (state) => async (dispatch) => {
	dispatch(resetNotification());
};

export const startNotification = (notification) => (dispatch, getState) => {
	const defaultTimeout = getState().notificationState.timeout; // get default timeout from initial state
	const timeout = notification.timeout || defaultTimeout; // use notification timeout if present, otherwise use default

	dispatch(
		setNotification({
			open: true,
			title: notification.title,
			message: notification.message,
			timeout: timeout,
			type: notification.type,
		})
	);

	setTimeout(() => {
		dispatch(resetNotification());
	}, timeout);
};
export default notificationStateSlice.reducer;
