import React, { useEffect } from "react";
import GroupSelection from "../../../../modules/selections/GroupSelection";

const GroupPermissions = ({
	memberGroups,
	adminGroups,
	setAdminGroups,
	setMemberGroups,
}) => {
	useEffect(() => {}, [adminGroups, memberGroups]);

	return (
		<>
			{memberGroups && adminGroups && (
				<>
					<div className="col-md-6">
						<div className="col-md-12 mb-3">
							<h5>Write Groups</h5>
						</div>
						{adminGroups && (
							<GroupSelection
								selection={adminGroups}
								setSelection={setAdminGroups}
							/>
						)}
					</div>

					<div className="col-md-6">
						<div className="col-md-12 mb-3">
							<h5>Read Groups</h5>
						</div>
						{memberGroups && (
							<GroupSelection
								selection={memberGroups}
								setSelection={setMemberGroups}
							/>
						)}
					</div>
				</>
			)}
		</>
	);
};

export default GroupPermissions;
