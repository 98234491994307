import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ErrorHandler from "../../app/ErrorHandler";
import { SuccessMessages } from "../../app/Messages";
import { useDeleteARObjectMutation } from "../../app/services/ARObject";
import { useDeleteEnvironmentMutation } from "../../app/services/environment";
import {
	useDeleteGroupMutation,
	useGetGroupsQuery,
	useUpdateGroupMutation,
} from "../../app/services/group";
import { useDeleteIconMutation } from "../../app/services/icon";
import {
	useDeleteOrganizationMutation,
	useGetARObjsFromOrganizationQuery,
	useGetEnvironmentsFromOrganizationQuery,
	useGetIconsFromOrganizationQuery,
	useGetOrganizationsQuery,
	useGetWorkflowsFromOrganizationQuery,
	useGetWorkplacesFromOrganizationQuery,
} from "../../app/services/organization";
import { useDeleteStepMutation } from "../../app/services/step";
import {
	useDeleteUserMutation,
	useGetUsersQuery,
} from "../../app/services/user";
import { useDeleteWorkflowMutation } from "../../app/services/workflow";
import { useDeleteWorkplaceMutation } from "../../app/services/workplace";
import {
	getCurrentOrganization,
	getModalData,
	showModal,
} from "../../app/slices/appStateSlice";
import {
	NotificationType,
	startNotification,
} from "../../app/slices/notifactionStateSlice";
import { CustomModal } from "../../components/modal/Modal";

const ModalTypes = {
	User: "user",
	Group: "group",
	GroupUser: "group user",
	Organization: "organization",
	Workplace: "workplace",
	Workflow: "workflow",
	AccordeonCard: "workflow step",
	Environment: "environment",
	ARObject: "AR object",
	Icon: "icon",
	Patterns: "pattern",
	Language: "language",
	Step: "step",
	Audio: "audio",
};

const ModalActions = {
	Delete: "delete",
	Remove: "remove",
	Add: "add",
	Update: "update",
};

const ConfirmDeleteModal = () => {
	const currentOrg = useSelector(getCurrentOrganization);
	const modal = useSelector(getModalData);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [skip, setSkip] = useState(true);

	const { refetch: refetchUsers } = useGetUsersQuery();
	const [deleteUser] = useDeleteUserMutation();

	const { refetch: refetchGroups } = useGetGroupsQuery();
	const [deleteGroup] = useDeleteGroupMutation();
	const [updateGroup] = useUpdateGroupMutation();

	const { refetch: refetchOrgs } = useGetOrganizationsQuery();
	const [deleteOrganization] = useDeleteOrganizationMutation();

	const { refetch: refetchWorkplaces } =
		useGetWorkplacesFromOrganizationQuery(currentOrg.id, { skip });
	const [deleteWorkplace] = useDeleteWorkplaceMutation();

	const { refetch: refetchWorkflows } = useGetWorkflowsFromOrganizationQuery(
		currentOrg.id,
		{ skip }
	);
	const [deleteWorkflow] = useDeleteWorkflowMutation();

	const [deleteStep] = useDeleteStepMutation();
	// const { refetch: refetchSteps } = useGetStepsQuery();

	// const { refetch: refetchWorkflow } = useGetWorkflowQuery( {
	// 	skip,
	// });

	const { refetch: refetchEnvironments } =
		useGetEnvironmentsFromOrganizationQuery(currentOrg.id, { skip });
	const [deleteEnvironment] = useDeleteEnvironmentMutation();

	const { refetch: refetchARObj } = useGetARObjsFromOrganizationQuery(
		currentOrg.id,
		{ skip }
	);
	const [deleteARObject] = useDeleteARObjectMutation();

	const { refetch: refetchIcons } = useGetIconsFromOrganizationQuery(
		currentOrg.id,
		{ skip }
	);
	const [deleteIcons] = useDeleteIconMutation();

	useEffect(() => {
		currentOrg && setSkip(false);
	}, [currentOrg]);

	const deleteFunctions = {
		[ModalTypes.User]: {
			deleteFn: deleteUser,
			refetchFn: refetchUsers,
		},
		[ModalTypes.Group]: {
			deleteFn: deleteGroup,
			refetchFn: refetchGroups,
		},
		[ModalTypes.GroupUser]: {
			deleteFn: updateGroup,
			refetchFn: refetchGroups,
		},
		[ModalTypes.Organization]: {
			deleteFn: deleteOrganization,
			refetchFn: refetchOrgs,
		},
		[ModalTypes.Workplace]: {
			deleteFn: deleteWorkplace,
			refetchFn: refetchWorkplaces,
		},
		[ModalTypes.Workflow]: {
			deleteFn: deleteWorkflow,
			refetchFn: refetchWorkflows,
		},
		[ModalTypes.AccordeonCard]: {
			deleteFn: deleteStep,
			refetchFn: refetchWorkflows,
		},
		[ModalTypes.Environment]: {
			deleteFn: deleteEnvironment,
			refetchFn: refetchEnvironments,
		},
		[ModalTypes.ARObject]: {
			deleteFn: deleteARObject,
			refetchFn: refetchARObj,
		},
		[ModalTypes.Icon]: {
			deleteFn: deleteIcons,
			refetchFn: refetchIcons,
		},
		[ModalTypes.Step]: {
			deleteFn: deleteStep,
			// refetchFn: refetchWorkflow,
		},
		// [ModalTypes.Patterns]: {
		// 	deleteFn: deletePatterns,
		// 	refetchFn: refetchPatterns,
		// },
	};

	const handleOkButton = () => {
		if (deleteFunctions[modal.type]) {
			handleDelete();
		} else {
			dispatch(
				startNotification({
					message: ErrorHandler.DELETE,
					type: NotificationType.ERROR,
				})
			);
		}
	};
	const handleDelete = async () => {
		const { deleteFn, refetchFn } = deleteFunctions[modal.type];
		try {
			await deleteFn(modal.data.id);
			onDeletionSuccess(refetchFn);
		} catch (error) {
			// handle error
		}
	};
	const onDeletionSuccess = (refetchCB) => {
		dispatch(showModal("none"));
		dispatch(
			startNotification({
				message: SuccessMessages.DELETE,
				type: NotificationType.SUCCESS,
			})
		);
		if (modal.navigate) {
			navigate(modal.navigate);
		} else if (refetchCB) {
			// if a parent ID is given, use parent ID to refresh parent fetch
			if (modal.parentId) {
				refetchCB(modal.parentId);
			} else {
				refetchCB();
			}
		}
	};

	return (
		<>
			{currentOrg && modal && modal.data && (
				<CustomModal
					id="delete"
					title={modal.modalAction + " " + modal.type}
					className={"warning"}
					okButton={
						<button
							type="button"
							className="btn btn-danger ml-3 capitalize"
							onClick={handleOkButton}
						>
							<i className="icon"></i>
							<span className="capitalize">
								{modal.modalAction}
							</span>
						</button>
					}
				>
					<p className="warning-dialog">
						You are about to {modal.modalAction} a {modal.type}.
						<br />
						{modal.modalAction === ModalActions.Remove ? (
							<>
								Do you really want to {modal.modalAction}
								<br />
								<span className="bold">
									{modal.customValue}
								</span>
								<br />
								from
								<span className="bold"> {modal.data.name}</span>
								?
							</>
						) : (
							<>
								Do you really want to {modal.modalAction}{" "}
								<span className="bold"> {modal.data.name}</span>
								?
							</>
						)}
					</p>
					{modal.modalAction === ModalActions.Delete && (
						<p className="warning-dialog warning">
							This action is permanent.
						</p>
					)}
				</CustomModal>
			)}
		</>
	);
};

export { ModalTypes, ModalActions, ConfirmDeleteModal };
