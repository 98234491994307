import React, { useEffect, useState } from "react";
import List from "../../components/list/List";
import ListOverviewTemplate from "../../layouts/ListOverviewTemplate";
import { useNavigate } from "react-router-dom";
import { useUpdateWorkplaceMutation } from "../../app/services/workplace";
import { DragDropContext } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import {
	getCurrentOrganization,
	setModalData,
	showModal,
} from "../../app/slices/appStateSlice";
import { ModalTypes } from "../../modules/Modals/ConfirmDeleteModal";
import DragItem from "../../components/dragndrop/DragItem";
import DropContainer from "../../components/dragndrop/DropContainer";
import { Titlebar, TitleType } from "../../layouts/Content";
import { useGetWorkplacesFromOrganizationQuery } from "../../app/services/organization";
import {
	NotificationType,
	startNotification,
} from "../../app/slices/notifactionStateSlice";
import { SuccessMessages } from "../../app/Messages";

const WorkplaceOverview = () => {
	const navigate = useNavigate();
	const [items, setItems] = useState();
	const [skip, setSkip] = useState(true);
	const dispatch = useDispatch();
	const selectedOrg = useSelector(getCurrentOrganization);
	const [updateWorkplace] = useUpdateWorkplaceMutation();
	const {
		data,
		refetch,
		isLoading,
		loadSuccess,
		deleteSuccess,
		data: workplaces,
		isSuccess,
	} = useGetWorkplacesFromOrganizationQuery(selectedOrg.id, {
		skip,
	});

	useEffect(() => {}, [isSuccess, loadSuccess, deleteSuccess, refetch, data]);

	useEffect(() => {
		selectedOrg && isSuccess && setItems(workplaces);
	}, [selectedOrg, isSuccess, workplaces, loadSuccess]);

	useEffect(() => {
		selectedOrg && setSkip(false);
	}, [selectedOrg]);

	const handleAdd = () => {
		navigate("/workplaces/new");
	};

	const handleEdit = (item) => {
		navigate("/workplaces/" + item.id);
	};

	const handleDelete = (item) => {
		dispatch(showModal("delete"));
		dispatch(
			setModalData({
				type: ModalTypes.Workplace,
				data: item,
			})
		);
	};
	const onDragEnd = (result) => {
		const newItems = Array.from(items);
		const [removed] = newItems.splice(result.source.index, 1);
		newItems.splice(result.destination.index, 0, removed);

		setItems(newItems);
		let patches = newItems.map((item, index) => ({
			...item,
			index: index,
		}));

		const updatePromises = patches.map((patch) =>
			updateWorkplace({ id: patch.id, patch: patch })
		);

		Promise.all(updatePromises)
			.then(() => {
				dispatch(
					startNotification({
						message: SuccessMessages.REORDER,
						type: NotificationType.SUCCESS,
					})
				);
				refetch();
			})
			.catch((error) => {
				dispatch(
					startNotification({
						message: error.message,
						type: NotificationType.ERROR,
					})
				);
			});
	};
	const DnDArea = () => {
		return (
			<>
				{items && (
					<DragDropContext onDragEnd={onDragEnd}>
						<DropContainer data={data} invisible={true}>
							{items.map((item, index) => (
								<DragItem
									key={index}
									item={item}
									index={index}
									handleDelete={handleDelete}
									handleEdit={handleEdit}
									handleRowClick={handleEdit}
								/>
							))}
						</DropContainer>
					</DragDropContext>
				)}
			</>
		);
	};

	return (
		<>
			<Titlebar type={TitleType.Overview} title="Workplace" />
			<ListOverviewTemplate addCallback={handleAdd} isLoading={isLoading}>
				{selectedOrg && workplaces && (
					<List id="workplaces">
						<List.Header>
							<List.Header.Cell>#</List.Header.Cell>
							<List.Header.Cell>Name</List.Header.Cell>
						</List.Header>
						<DnDArea />
					</List>
				)}
			</ListOverviewTemplate>
		</>
	);
};

export default WorkplaceOverview;
