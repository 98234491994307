import React from "react";
import { NavLink } from "react-router-dom";
// import { matchRoutes, useLocation } from "react-router-dom";

// const routes = [{ path: "/members/:id" }];

const TitleWithBreadcrumb = ({ title, parent }) => {
	// const location = useLocation();
	// const [{ route }] = matchRoutes(routes, location);

	return (
		<div className="flex flex-row">
			<div className="flex-item">
				<h2>{title}</h2>
			</div>
			<div className="flex flex-item flex-end breadcrumb-container">
				<ol className="breadcrumb">
					<li className="breadcrumb-item">
						<NavLink to="/">
							<span className="icon icon-lg"></span>
						</NavLink>
					</li>
					{parent && (
						<li className="breadcrumb-item">
							<NavLink to={parent.url}>{parent.name}</NavLink>
						</li>
					)}
					<li className="breadcrumb-item active">{title}</li>
				</ol>
			</div>
		</div>
	);
};

export default TitleWithBreadcrumb;
