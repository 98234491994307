import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getEndpoint } from "./basics";
import { organizationApi } from "./organization";
const base = getEndpoint();

export const mediafileApi = createApi({
	reducerPath: "mediafileApi",
	baseQuery: fetchBaseQuery({
		baseUrl: base,
		prepareHeaders: (headers) => {
			headers.set("Content-Type", "application/json");
			headers.set("Accept", "application/json");
			return headers;
		},
		credentials: "include",
	}),
	tagTypes: [],
	endpoints: (builder) => ({
		getMediafile: builder.query({
			query: (id) => ({
				url: `mediafile/${id}`,
				method: "GET",
			}),
		}),
		createMediafile: builder.mutation({
			query: (data) => ({
				url: `mediafile`,
				method: "POST",
				body: data,
			}),
		}),
		updateMediafile: builder.mutation({
			query: ({ id, patch }) => ({
				url: `mediafile/${id}`,
				method: "PATCH",
				body: patch,
			}),
			onSettled: (_, { queryFulfilled, arg: { patch } }) => {
				if (queryFulfilled?.type === "updateMediafile") {
					// Invalidate the tag for the getMediafilesFromOrganization query with the id of the organization
					organizationApi.invalidateTags(
						"Mediafiles",
						patch.organizationId
					);
				}
			},
		}),
		createSTTFromMediafile: builder.mutation({
			query: (id) => ({
				url: `mediafile/${id}/stt`,
				method: "POST",
			}),
		}),
		deleteMediafile: builder.mutation({
			query: (id) => ({
				url: `mediafile/${id}`,
				method: "DELETE",
			}),
		}),
		createFile: builder.mutation({
			query: (data) => ({
				url: `stepfile`,
				method: "POST",
				body: data,
			}),
		}),
		updateFile: builder.mutation({
			query: ({ id, patch }) => ({
				url: `stepfile/${id}`,
				method: "PATCH",
				body: patch,
			}),
		}),
		deleteFile: builder.mutation({
			query: (id) => ({
				url: `stepfile/${id}`,
				method: "DELETE",
			}),
		}),
	}),
});

// Export hooks for usage in functional components
export const {
	useGetMediafileQuery,
	useCreateFileMutation,
	useDeleteFileMutation,
	useUpdateFileMutation,
	useCreateMediafileMutation,
	useDeleteMediafileMutation,
	useUpdateMediafileMutation,
	useCreateSTTFromMediafileMutation,
} = mediafileApi;
