import React from "react";
import Selectable from "./Selectable";

const SmallListItem = ({
	data,
	children,
	selectable,
	isSelected = true,
	selectCallback,
	classes,
	...props
}) => {
	// TODO: Update this so you just pass all the children elements
	const List = () => {
		return (
			<>
				<div key={data.id} className="info-box-content ">
					{/* TODO: REPLACE THIS */}
					{children ? (
						children
					) : (
						<span className="info-box-text">
							{data.name || data.email}
						</span>
					)}
				</div>
			</>
		);
	};

	const handleSelect = (value) => {
		selectCallback(value);
	};

	return (
		<>
			{data && (
				<>
					{selectable ? (
						<Selectable
							id={data.id}
							callback={handleSelect}
							selected={isSelected}
						>
							<List />
						</Selectable>
					) : (
						<div
							{...props}
							className={
								classes ? classes + " info-box" : "info-box"
							}
						>
							<List />
						</div>
					)}
				</>
			)}
		</>
	);
};

export { SmallListItem };
