import React, { useCallback, useEffect, useState } from "react";
import { Droppable } from "react-beautiful-dnd";
import DragItem from "../../../../../components/dragndrop/DragItem";
import List from "../../../../../components/list/List";

const EnvironmentWorkplace = ({
	data,
	allWorkflows,
	index,
	errors,
	register,
	...props
}) => {
	const [workflows, setWorkflows] = useState(data.workflows);
	const [currentData, setCurrentData] = useState(data);
	const [editGroup, setEditGroup] = useState(true);

	useEffect(() => {
		setWorkflows(data.workflows);
		setEditGroup(true);
	}, [props.envGroups, data.workflows]);

	useEffect(() => {
		setCurrentData(data);
	}, [data]);

	const handleNameChange = useCallback(
		(e) => {
			setCurrentData((prevState) => ({
				...prevState,
				name: e.target.value,
			}));
		},
		[setCurrentData]
	);

	return (
		<>
			{workflows && (
				<div className="drop-container">
					<Droppable key={index} droppableId={data.id.toString()}>
						{(provided, snapshot) => (
							<div
								ref={provided.innerRef}
								{...provided.droppableProps}
							>
								<div className="workplace-title">
									{editGroup ? (
										<input
											className="form-control"
											id={index}
											type="text"
											defaultValue={currentData.name}
											{...register(
												`workplaces.${index}.name`
											)}
											onChange={handleNameChange}
										/>
									) : (
										currentData.name
									)}
								</div>
								<div className="drop-container-buttons">
									<button
										className="btn btn-dm btn-danger float-right mr-2"
										onClick={() =>
											props.handleDelete(currentData.id)
										}
									>
										<i className="icon"></i>
									</button>
								</div>
								<div className="list-container">
									<List>
										{/* render elements in groups */}
										{allWorkflows.map((item, index) => {
											if (
												currentData.workflows.indexOf(
													item.id
												) > -1
											) {
												//In the array!
												return (
													<DragItem
														item={item}
														index={index}
														key={index}
													>
														<div
															style={{
																display: "flex",
																justifyContent:
																	"space-around",
															}}
														>
															<List.Item
																classes={
																	"elevation-2 dndable"
																}
																style={{
																	gridTemplateColumns:
																		"5% auto",
																}}
															>
																<List.Item.Content key="space">
																	{" "}
																</List.Item.Content>
																<List.Item.Content
																	title
																	key="name"
																>
																	{item.name}
																	{"id: " +
																		item.id}
																</List.Item.Content>
															</List.Item>
														</div>
													</DragItem>
												);
											} else {
												return null;
											}
										})}
									</List>
								</div>
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</div>
			)}
		</>
	);
};

export default EnvironmentWorkplace;
