import React from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { setModalData, showModal } from "../../app/slices/appStateSlice";

const GroupLabels = ({ groups, user, editable, setGroups }) => {
	const dispatch = useDispatch();

	function trimGroupName(name) {
		if (name.length > 20) return name.substring(0, 20) + "...";
		return name;
	}

	const handleGroupRemove = (id) => {
		const filteredGroups = groups
			? groups.filter((group) => group.id !== id)
			: {};
		setGroups(filteredGroups);
	};

	const handleAddGroups = () => {
		dispatch(
			setModalData({
				data: user,
			})
		);
		dispatch(showModal("select-groups"));
	};

	const GroupLabel = ({ group }) => {
		return (
			<>
				{editable ? (
					<div className="badge-link">
						<div className="badge badge-pill badge-primary group">
							<span className="">
								{trimGroupName(group.name)}
							</span>
							<span
								className="clickable remove icon"
								onClick={() => handleGroupRemove(group.id)}
							>
								
							</span>
						</div>
					</div>
				) : (
					<NavLink
						to={"/groups/" + group.id}
						className={"badge-link"}
					>
						<div className="badge clickable badge-pill badge-primary mr-1 ml-1">
							{trimGroupName(group.name)}
						</div>
					</NavLink>
				)}
			</>
		);
	};

	return (
		<>
			{groups.map((group, index) => {
				return (
					<GroupLabel key={"grouplabels-" + index} group={group} />
				);
			})}
			{editable && (
				<li className="list-inline-item group-badge-container">
					<span
						className="badge icon clickable icon-cta"
						onClick={() => handleAddGroups()}
						data-toggle="modal"
						data-target="#modal-default"
					>
						
					</span>
				</li>
			)}
		</>
	);
};

export default GroupLabels;
