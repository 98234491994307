import React from "react";
import { Droppable } from "react-beautiful-dnd";

const DropContainer = ({ id, invisible = false, title, children }) => {
	return (
		<>
			{
				<Droppable
					droppableId={id ? id : "null"}
					className="droppable-container"
				>
					{(provided) => (
						<div
							{...provided.droppableProps}
							ref={provided.innerRef}
							className={invisible ? "" : "drop-container"}
						>
							{!invisible && title && (
								<div className="workplace-title">{title}</div>
							)}
							{children}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			}
		</>
	);
};

export default DropContainer;
