import React from "react";

const ListItemContent = ({ children, title, className }) => (
	<>
		{title && (
			<div className={`title-container ${className}`}>
				{React.Children.map(children, (child, index) => (
					<span className={index === 0 ? "title" : "subtitle"}>
						{child}
					</span>
				))}
			</div>
		)}
		{!title && children}
	</>
);

export default ListItemContent;
