import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getModalData, showModal } from "../../app/slices/appStateSlice";
import { CustomModal } from "../../components/modal/Modal";
import GroupSelection from "../selections/GroupSelection";

const SelectGroupsModal = ({ saveCallback }) => {
	const modal = useSelector(getModalData);
	const dispatch = useDispatch();
	const [selectedGroups, setSelectedGroups] = useState([]);

	const handleSave = () => {
		saveCallback(modal.data, selectedGroups);
		dispatch(showModal("none"));
	};

	const handleSelection = (item) => {
		let items = selectedGroups;
		items = selectedGroups.includes(item)
			? selectedGroups.filter((i) => i !== item) // remove item
			: [...selectedGroups, item]; // add item
		setSelectedGroups(items);
	};

	useEffect(() => {
		modal && modal.data && setSelectedGroups(modal.data.groups);
	}, [modal]);

	useEffect(() => {}, [selectedGroups]);

	return (
		<>
			{modal && modal.data && selectedGroups && (
				<CustomModal
					id={"select-groups"}
					title={"Add Groups to " + modal.data.email}
					okButton={
						<button
							type="button"
							className="btn btn-primary ml-3 "
							onClick={handleSave}
						>
							<i className="icon"></i>
							Save
						</button>
					}
				>
					<GroupSelection
						selection={selectedGroups}
						setSelection={setSelectedGroups}
						callback={(item) => handleSelection(item)}
					/>
				</CustomModal>
			)}
		</>
	);
};

export default SelectGroupsModal;
