import React from "react";

const FullpageSpinner = ({ text = "Loading", ...props }) => {
	return (
		<div className="spinner-container" {...props}>
			<div className="spinner-content" {...props}>
				<div className="loading-text">{text}</div>
				<div className="loader cube"></div>
				<div className="loader cube"></div>
				<div className="loader cube"></div>
				<div className="loader cube"></div>
			</div>
		</div>
	);
};

export default FullpageSpinner;
