import React, { useState } from "react";

const Selectable = ({ children, callback, id, selected }) => {
	const [isSelected, setIsSelected] = useState(selected);

	const toggleIsSelected = (id) => {
		setIsSelected((state) => !state);
		callback(id);
	};
	return (
		<>
			<div
				id={id}
				className={
					isSelected
						? "selected info-box clickable"
						: "info-box clickable"
				}
				onClick={() => toggleIsSelected(id)}
			>
				{children}
			</div>
		</>
	);
};

export default Selectable;
