import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getEndpoint } from "./basics";
const base = getEndpoint();

export const ARObjectApi = createApi({
	reducerPath: "ARObjectApi",
	baseQuery: fetchBaseQuery({
		baseUrl: base,
		prepareHeaders: (headers) => {
			headers.set("Accept", "application/json");
			return headers;
		},
		credentials: "include",
	}),
	tagTypes: [],
	endpoints: (builder) => ({
		createARObject: builder.mutation({
			query: (data) => ({
				url: `arobject`,
				method: "POST",
				body: data,
			}),
		}),
		uploadARObject: builder.mutation({
			query: ({ url, data }) => ({
				url: url,
				method: "PUT",
				body: data,
			}),
		}),
		updateARObject: builder.mutation({
			query: ({ id, patch }) => ({
				url: `arobject/${id}`,
				method: "PATCH",
				body: patch,
			}),
		}),
		deleteARObject: builder.mutation({
			query: (id) => ({
				url: `arobject/${id}`,
				method: "DELETE",
			}),
		}),
	}),
});

// Export hooks for usage in functional components
export const {
	useCreateARObjectMutation,
	useDeleteARObjectMutation,
	useUpdateARObjectMutation,
	useUploadARObjectMutation,
} = ARObjectApi;
