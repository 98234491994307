import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	dismissNotification,
	getNotificationState,
} from "../../app/slices/notifactionStateSlice";

const Notification = () => {
	var notificationState = useSelector(getNotificationState);
	const [rerender, setRerender] = useState(false);

	const dispatch = useDispatch();

	useEffect(() => {}, [notificationState, dispatch, rerender]);

	const handleCloseNotification = () => {
		dispatch(dismissNotification());
		setRerender((rerender) => !rerender);
	};

	return (
		<>
			{notificationState.open && (
				<div
					className={`notification-container ${notificationState.type}`}
				>
					<div className="notification">
						{notificationState.message
							? notificationState.message
							: "undefined message"}
					</div>
					<div
						className={"notification-button"}
						onClick={handleCloseNotification}
					></div>
				</div>
			)}
		</>
	);
};

export default Notification;
