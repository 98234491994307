import React, { useEffect, useState } from "react";
import { setModalData, showModal } from "../../app/slices/appStateSlice";
import { ModalActions, ModalTypes } from "../Modals/ConfirmDeleteModal";
import { useDispatch } from "react-redux";
import { useCreateSTTFromMediafileMutation } from "../../app/services/mediafile";
import {
	NotificationType,
	startNotification,
} from "../../app/slices/notifactionStateSlice";
import SmallSpinner from "../spinner/SmallSpinner";

const SaveCallbackContext = React.createContext();

const StepDescription = ({
	step,
	register,
	getValues,
	setValue,
	text,
	languageInfo,
}) => {
	const dispatch = useDispatch();

	const [status, setStatus] = useState({ text: null, info: "IDLE" });
	const [values, setValues] = useState({
		description: text,
	});
	const [createSTTFromMediafile] = useCreateSTTFromMediafileMutation();

	useEffect(() => {}, [languageInfo, values]);

	useEffect(() => {
		setValues({ description: text });
	}, [text]);

	const handleSTT = (e) => {
		e.preventDefault();
		var mediafile = step.mediafiles.reduceRight((acc, obj) => {
			if (obj.type === "audio/mpeg" && !acc) {
				return obj;
			}
			return acc;
		}, null);
		if (mediafile) {
			processSTTConversion(mediafile.id);
		}
	};
	const wait = (ms) => {
		return new Promise((resolve) => setTimeout(resolve, ms));
	};
	const processSTTConversion = async (mediafileId, depth = 1) => {
		try {
			const res = await createSTTFromMediafile(mediafileId);

			if (res?.error) {
				dispatch(
					startNotification({
						message: res?.error.error,
						type: NotificationType.ERROR,
					})
				);

				setStatus({
					text: `Convert to text status: ERROR`,
					info: "ERROR",
				});
				return;
			} else if (res?.data.status === "SUCCESS") {
				setValue(
					"description",
					values.description + "\n" + res.data?.text
				);
				setStatus({
					text: `Convert to text status: ${res?.data.status}`,
					info: res?.data.status,
				});
			} else if (res?.data.status === "PROCESSING") {
				const dots = ".".repeat(((depth - 1) % 3) + 1);
				setStatus({
					text: `Convert to text status: ${res?.data.status} ${dots}`,
					info: res?.data.status,
				});
				// Wait for some time (e.g., 3 seconds) before making the next query
				await wait(3000);
				// Make the next recursive call to processMediaFile with the same mediafileId
				await processSTTConversion(mediafileId, depth + 1);
			}
		} catch (error) {
			dispatch(
				startNotification({
					message: error,
					type: NotificationType.ERROR,
				})
			);
			console.error("Error processing media file:", error);
			setStatus({ text: `Convert to text status: ERROR`, info: "ERROR" });
			return;
		}
	};

	const handleTTS = (e) => {
		e.preventDefault();

		dispatch(showModal("update-audio"));
		let values = getValues();
		var tempData = { ...step, description: values.description };
		dispatch(
			setModalData({
				type: ModalTypes.Audio,
				modalAction: ModalActions.Update,
				data: { step: tempData },
			})
		);
	};
	const Buttons = () => {
		return (
			<>
				{console.log(languageInfo)}
				<div className="flex flex-row mt-3">
					{languageInfo.tts && (
						<button
							className=" btn btn-sm btn-secondary mr-3"
							onClick={(e) => handleTTS(e)}
						>
							Save Step & Text to speech{" "}
							<span className="icon"></span>
						</button>
					)}
					{languageInfo.stt && (
						<button
							className="btn btn-sm btn-secondary "
							onClick={(e) => handleSTT(e)}
						>
							Convert to text <span className="icon"></span>
						</button>
					)}
				</div>
			</>
		);
	};

	const StatusMessage = () => {
		return <div className="status">{status.text}</div>;
	};

	return (
		<div className="description mb-5">
			<div className="col-12">
				<label>Description: </label>
				{values && (
					<div
						className={`description-container  ${
							status.info === "PROCESSING" ? "processing" : ""
						}`}
					>
						{status.info === "PROCESSING" && (
							<SmallSpinner
								className="processing-description"
								text={"Converting Speech to Text"}
							/>
						)}

						<textarea
							name="video-description"
							defaultValue={values.description}
							// onChange={(e) => {
							// 	// setValues({ description: e.target.value });
							// 	setText(e.target.value);
							// }}
							style={{ width: "100%", height: "300px" }}
							{...register("description", {
								required: true,
							})}
						/>
					</div>
				)}
				<StatusMessage />
				<Buttons />
			</div>
		</div>
	);
};

export { StepDescription, SaveCallbackContext };
