import React from "react";

const BUTTON_CONFIGS = {
	Edit: {
		classes: "btn-secondary",
		content: "Edit",
		icon: "",
	},
	Copy: {
		classes: "btn-secondary",
		content: "Copy",
		icon: "⎘",
	},
	QR: {
		classes: "btn-secondary",
		content: "QR",
		icon: "⎘",
	},
	View: {
		classes: "btn-secondary",
		content: "View",
		icon: "",
	},
	Delete: {
		classes: "btn-secondary btn-danger",
		content: "Delete",
		icon: "",
	},
	Save: {
		classes: "btn-cta",
		content: "Save",
		icon: "",
		type: "submit",
	},
	Cancel: {
		classes: "btn-secondary",
		content: "Cancel",
		icon: "",
	},
};

const DefaultListButton = ({ callback, config, ...props }) => {
	const { classes, icon, content } = config;
	return (
		<button
			className={`btn btn-sm mr-1 ${classes}`}
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
				callback();
			}}
			{...props}
			disabled={props.disabled}
		>
			<i className="icon">{icon}</i>
			{content}
		</button>
	);
};

const ListItemButton = ({ id, type, ...props }) => {
	const callback = props.callback;
	const config = BUTTON_CONFIGS[type] || {};

	return (
		<DefaultListButton
			config={config}
			callback={() => callback(id)}
			{...props}
		/>
	);
};

export default ListItemButton;
