import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getEndpoint } from "./basics";
const base = getEndpoint();

export const stepApi = createApi({
	reducerPath: "stepApi",
	baseQuery: fetchBaseQuery({
		baseUrl: base,
		prepareHeaders: (headers) => {
			headers.set("Content-Type", "application/json");
			headers.set("Accept", "application/json");
			return headers;
		},
		credentials: "include",
	}),
	tagTypes: [],
	endpoints: (builder) => ({
		getSteps: builder.query({
			query: () => ({
				url: `step`,
				method: "GET",
			}),
		}),
		getStep: builder.query({
			query: (id) => ({
				url: `step/${id}`,
				method: "GET",
			}),
		}),
		createStep: builder.mutation({
			query: (data) => ({
				url: `step`,
				method: "POST",
				body: data,
			}),
		}),
		cloneStep: builder.mutation({
			query: (id) => ({
				url: `step/${id}/clone`,
				method: "POST",
			}),
		}),
		updateStep: builder.mutation({
			query: ({ id, patch }) => ({
				url: `step/${id}`,
				method: "PATCH",
				body: patch,
			}),
		}),
		createTTS: builder.mutation({
			query: (id) => ({
				url: `step/${id}/tts`,
				method: "POST",
			}),
		}),
		deleteStep: builder.mutation({
			query: (id) => ({
				url: `step/${id}`,
				method: "DELETE",
			}),
		}),
	}),
});

// Export hooks for usage in functional components
export const {
	useGetStepsQuery,
	useGetStepQuery,
	useCloneStepMutation,
	useCreateStepMutation,
	useDeleteStepMutation,
	useUpdateStepMutation,
	useCreateTTSMutation,
} = stepApi;
