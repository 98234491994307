import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Titlebar } from "../../layouts/Content";
import { SelectImageModal } from "../../modules/Modals/ImageModals/ImageModalSelector";
import SettingsAR from "./tabs/SettingsAR";
import SettingsIcons from "./tabs/SettingsIcons";

const CustomizeAppTypes = {
	AR: "AR Object",
	Icon: "Industrial Icon",
	Pattern: "Pattern",
};

const CustomizeApp = () => {
	return (
		<>
			<Titlebar title="Customize App" />
			<Tabs
				transition={false}
				defaultActiveKey="ar"
				id="justify-tab-example"
				className="mb-5"
				justify
			>
				<Tab eventKey="ar" title="AR Objects">
					<SettingsAR />
				</Tab>
				<Tab eventKey="icons" title="Industrial Icons">
					<SettingsIcons />
				</Tab>
			</Tabs>
			<SelectImageModal />
		</>
	);
};

export { CustomizeAppTypes, CustomizeApp };
