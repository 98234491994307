import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { useGetWorkflowsFromOrganizationQuery } from "../../../../../app/services/organization";
import { getCurrentOrganization } from "../../../../../app/slices/appStateSlice";
import DragItem from "../../../../../components/dragndrop/DragItem";
import List from "../../../../../components/list/List";
import { useFieldArray } from "react-hook-form";
import EnvironmentWorkplace from "./EnvironmentWorkplace";
import _ from "lodash";
import { ErrorMessages } from "../../../../../app/Messages";

const Settings = ({
	data,
	control,
	unassignedWorkflows,
	errors,
	register,
	...props
}) => {
	const [currentData, setCurrentData] = useState(data);
	const currentOrg = useSelector(getCurrentOrganization);
	const [currentWorkplaces, setCurrentWorkplaces] = useState(
		data.workplaces ? data.workplaces : []
	);

	const { data: allWorkflows, isSuccess: loadWorkflowSuccess } =
		useGetWorkflowsFromOrganizationQuery(currentOrg && currentOrg.id);
	// const { data: unassignedWorkflows } =
	// 	useGetUnassignedWorkflowsOfEnvironmentQuery(data.id, { skip });
	const [currentUnassignedWorkflows, setCurrentUnassignedWorkflows] =
		useState();
	const { replace } = useFieldArray({
		control,
		name: "workplaces", // unique name for your Field Array
	});

	useEffect(() => {}, [currentUnassignedWorkflows, unassignedWorkflows]);

	useEffect(() => {
		replace(currentWorkplaces);
	}, [currentWorkplaces, replace, currentData]);

	useEffect(() => {
		setCurrentData(data);
		setCurrentUnassignedWorkflows(
			unassignedWorkflows ? unassignedWorkflows : allWorkflows
		);
	}, [data, unassignedWorkflows, allWorkflows]);

	useEffect(() => {}, [currentWorkplaces, unassignedWorkflows]);

	const handleDelete = (id) => {
		const addTheseWorkflowsToTheCurrentUnassignedWorkflows = _(
			currentWorkplaces
		)
			.find({ id })
			.get("workflows")
			.filter((workflowId) =>
				_(allWorkflows).map("id").includes(workflowId)
			)
			.value();
		//  merge array of currentunassigned and soontobeunassigned workflows
		var updatedUnassignedWorkflows = [
			...currentUnassignedWorkflows,
			...addTheseWorkflowsToTheCurrentUnassignedWorkflows,
		];

		setCurrentUnassignedWorkflows(updatedUnassignedWorkflows);

		//  remove workplace with the id from currentWorkplaces
		var updatedWorkplaces = currentWorkplaces.filter((wp) => wp.id !== id);
		setCurrentWorkplaces(updatedWorkplaces);
	};

	const handleAddWorkplace = () => {
		setCurrentWorkplaces([
			...currentWorkplaces,
			{
				name: "A new one",
				id: currentWorkplaces.length + 1,
				workflows: [],
			},
		]);
	};

	const addUnassignedWorkflowArray = (id) => {
		// Update unassigend workflows state
		let workflowToUnassign = allWorkflows.find(
			(workflow) => workflow.id === id
		);
		setCurrentUnassignedWorkflows([
			...currentUnassignedWorkflows,
			workflowToUnassign,
		]);
	};
	const removeUnassignedWorkflowArray = (id) => {
		// Update unassigend workflows state
		let modifiedUnassignedWorkflows = currentUnassignedWorkflows;
		modifiedUnassignedWorkflows = modifiedUnassignedWorkflows.filter(
			(wf) => wf.id !== id
		);
		setCurrentUnassignedWorkflows(modifiedUnassignedWorkflows);
	};

	const getUpdatedSourceWorkflows = (indexSource, draggableId) => {
		// a) get copy of the workflows
		var sourceWorkflows = currentWorkplaces[indexSource].workflows;

		// b) update source workflow copy - remove dragged workflow id
		sourceWorkflows = sourceWorkflows.filter((wf) => wf !== draggableId);
		// c) update workplace workflow at destination index to mutated workplace workflow

		return sourceWorkflows;
	};

	function onDragEnd(result) {
		// cancel action if not being dragged in any container
		if (!result.destination) return;
		// cancel action if not being dragged in the same container
		if (
			result.destination.droppableId === result.source.droppableId ||
			result.destination.droppableId === "0"
		) {
			return;
		}

		// create a copy of the current workplaces state array
		var copyCurrentWorkplaces = _.cloneDeep(currentWorkplaces);

		// get index of current workplace drag source container
		var indexSource = currentWorkplaces.findIndex(
			(wp) => wp.id.toString() === result.source.droppableId.toString()
		);

		// IF the dragged item is being dragged back to unassiged workflows container:
		if (result.destination.droppableId === "unassigned-container") {
			// a) add currently dragged item to unassigned array
			addUnassignedWorkflowArray(result.draggableId);
			// b) update the copy of the current workplaces (remove recently dragged element from workplace)
			copyCurrentWorkplaces[indexSource].workflows =
				getUpdatedSourceWorkflows(indexSource, result.draggableId);
			// c) update current workplace array
			setCurrentWorkplaces(copyCurrentWorkplaces);
			return;
		}

		//////////////////////////////////// STEP 1: UPDATE WORKPLACE WHERE YOU DRAG WORKFLOW FROM

		if (indexSource >= 0) {
			copyCurrentWorkplaces[indexSource].workflows =
				getUpdatedSourceWorkflows(indexSource, result.draggableId);
		}

		////////////////////////////////// STEP 2: ADD DRAGGED WORKFLOW TO DESTINATION WORKPLACE
		let indexDestination = currentWorkplaces.findIndex(
			(wp) =>
				wp.id.toString() === result.destination.droppableId.toString()
		);

		// a) ADD workflowId on Index
		let updatedWorkflowDestination = [
			...currentWorkplaces[indexDestination].workflows,
			result.draggableId,
		];

		// b) set workplace workflow at destination index to mutated workplace workflow
		copyCurrentWorkplaces[indexDestination].workflows =
			updatedWorkflowDestination;

		//  update current workplaces state
		setCurrentWorkplaces(copyCurrentWorkplaces);

		//  update list of unassigned workflows
		removeUnassignedWorkflowArray(result.draggableId);
	}

	const Workplaces = () => {
		return (
			<>
				{currentWorkplaces.map((field, index) => {
					return (
						<EnvironmentWorkplace
							data={field}
							index={index}
							handleDelete={handleDelete}
							allWorkflows={allWorkflows}
							key={index}
							register={register}
							errors={errors}
							{...props}
						/>
					);
				})}
				<div className="row">
					<div className="col-12">
						<button
							type="button"
							className="add-button btn btn-cta center btn-sm float-right"
							onClick={() => handleAddWorkplace()}
						>
							<span className="icon"></span>
							<span> add group</span>
						</button>
					</div>
				</div>
			</>
		);
	};

	const UnassignedWorkflows = () => {
		return (
			<>
				<List>
					{currentUnassignedWorkflows.map((item, index) => (
						<DragItem key={index} item={item} index={index}>
							<div
								style={{
									display: "flex",
									justifyContent: "space-around",
								}}
							>
								<List.Item
									classes={"elevation-2 dndable"}
									style={{
										gridTemplateColumns: "5% auto",
									}}
								>
									<List.Item.Content> </List.Item.Content>
									<List.Item.Content title>
										{item.name}
										{"id: " + item.id}
									</List.Item.Content>
								</List.Item>
							</div>
						</DragItem>
					))}
				</List>
			</>
		);
	};

	return (
		<>
			{loadWorkflowSuccess && data && (
				<>
					<div className="form-group mb-5">
						<div className={`label-container`}>
							<label htmlFor={"name"}>Environment Name</label>
						</div>
						<div
							className={`input-container ${
								errors["name"] && "error"
							}`}
						>
							<input
								name={"name"}
								type={"text"}
								className={`form-control mr-2 centered ${
									errors["name"] && "error"
								}`}
								defaultValue={data?.name ?? ""}
								{...register("name", {
									required: ErrorMessages.REQUIRED,
								})}
							/>
							{errors && errors["name"] && (
								<span className="error">
									{errors["name"]?.message}
								</span>
							)}
						</div>
					</div>
					<DragDropContext onDragEnd={onDragEnd}>
						<div className={"row mb-5"}>
							<div className={"col-md-5"}>
								<label>Unassigned Workflows</label>
								{currentUnassignedWorkflows && (
									<div className="drop-container mt-1">
										<Droppable
											key={"unassigned-container"}
											droppableId={"unassigned-container"}
										>
											{(provided, snapshot) => (
												<div
													ref={provided.innerRef}
													{...provided.droppableProps}
												>
													<UnassignedWorkflows />
													{provided.placeholder}
												</div>
											)}
										</Droppable>
									</div>
								)}
							</div>
							<div className={"col-md-7"}>
								<label className="mb-2">
									Environmental Workplaces
								</label>
								{currentWorkplaces && <Workplaces />}
							</div>
						</div>
					</DragDropContext>
				</>
			)}
		</>
	);
};

export default Settings;
