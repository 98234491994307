export const SuccessMessages = {
	SAVE: "Succesfully saved!",
	DELETE: "Succesfully deleted!",
	UPLOAD: "Succesfully uploaded your file!",
	UPDATE: "Succesfully updated!",
	CLONE: "Succesfully cloned!",
	COPY: "Succesfully copied!",
	REORDER: "Successfully changed the order of the items!",
};

export const ActionMessages = {
	"COPY_PROGRESS": "Copy in progress..."
}

export const ErrorMessages = {
	REQUIRED: "required",
	TOOLONG: "too many characters",
	TOOSHORT: "not enough characters",
	GENERIC: "Error!"
};

export const FileError = {
	UNSUPPORTED: "unsupported file type",
};
