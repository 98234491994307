import React from "react";

function Toggle({ label, id, checked, checkedCB, disabled }) {
	return (
		<div className="custom-control custom-switch">
			<input
				type="checkbox"
				className="custom-control-input"
				id={"customSwitch-" + id}
				checked={checked}
				onChange={() => checkedCB()}
				disabled={disabled}
			/>
			<label
				className="custom-control-label"
				htmlFor={"customSwitch-" + id}
			>
				{label}
			</label>
		</div>
	);
}

export default Toggle;
