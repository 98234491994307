import React from "react";
import { Draggable } from "react-beautiful-dnd";
import List from "../list/List";

const DragItem = ({ item, index, children, ...props }) => {
	const handleOnClick = (e) => {
		e.stopPropagation();
		props.handleRowClick(item);
	};

	const handleEdit = () => {
		props.handleEdit(item);
	};

	const handleDelete = () => {
		props.handleDelete(item);
	};

	return (
		<Draggable key={item.id} draggableId={item.id} index={index}>
			{(provided, snapshot) => (
				<div
					ref={provided.innerRef}
					snapshot={snapshot}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					className="draggable"
				>
					{children ? (
						children
					) : (
						<List.Item
							onClick={handleOnClick}
							classes="clickable"
							key={item.id}
							buttons={[
								<List.Item.Button
									key={`${item.id}-edit`}
									type="Edit"
									callback={handleEdit}
								/>,
								<List.Item.Button
									key={`${item.id}-delete`}
									type="Delete"
									callback={handleDelete}
								/>,
							]}
						>
							<List.Item.Content>
								<span className="hide-onSmallPhone">
									{index + 1}
								</span>
							</List.Item.Content>
							<List.Item.Content title>
								{item.name}
							</List.Item.Content>
						</List.Item>
					)}
				</div>
			)}
		</Draggable>
	);
};

export default DragItem;
