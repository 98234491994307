import React from "react";
import { NavLink } from "react-router-dom";

const FourOFour = ({ error, parent }) => {
	return (
		<div className="error-container centered">
			<p className="error-code">404</p>
			<p className="error-text">{error ? error : "page not found"}</p>
			{parent && (
				<NavLink className="mt-5 btn btn-secondary" to={parent.url}>
					<i className="icon"></i> back to {parent.name}
				</NavLink>
			)}
		</div>
	);
};

export default FourOFour;
