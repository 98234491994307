import React from "react";
import Tab from "react-bootstrap/esm/Tab";
import Tabs from "react-bootstrap/esm/Tabs";
import { Titlebar } from "../../layouts/Content";
import SettingsGroups from "./tabs/SettingsGroups";
import SettingsUsers from "./tabs/SettingsUsers";

const SettingsOrganization = () => {
	return (
		<>
			<Titlebar title="Organization Settings" />
			<Tabs
				transition={false}
				defaultActiveKey="patterns"
				id="justify-tab-example"
				className="mb-5"
				justify
			>
				<Tab eventKey="patterns" title="Manage Users">
					<SettingsUsers />
				</Tab>
				<Tab eventKey="ar" title="User Groups">
					<SettingsGroups />
				</Tab>
				{/* <Tab eventKey="icons" title="eMail Template">
				<SettingsEmail />
			</Tab> */}
			</Tabs>
		</>
	);
};
export default SettingsOrganization;
