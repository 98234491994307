import React, {useState} from "react";
import {getAuthURL, getEndpoint} from "../../app/services/basics";
import logo75 from '../../static/logo75.png';
import {Button} from "react-bootstrap";

// eslint-disable-next-line
const emailRegex = /^[\w-\.]+@([\w-]+\.)+([\w-]{2,4})$/;
const apiBase = getEndpoint();

const Login = () => {

	const [isButtonDisabled, setButtonDisabled] = useState(false);
	const [email, setEmail] = useState("");

	const disableButton = () => {
		setButtonDisabled(true);
	};

	const enableButton = () => {
		setButtonDisabled(false);
	};

	const onEmailChange = async (event) => {
		setEmail(event.target.value + "");
		if(emailRegex.test(email)) {
			let emailSplit = emailRegex.exec(email);
			let domain = emailSplit[1] + emailSplit[2];
			let authTenant = await fetch(`${apiBase}/auth/tenant?domain=${domain}`, {
				headers: {
					"Content-Type": "application/json",
					"Accept": "application/json"
				},
				credentials: "include",
			})
				.then(res => res.json());
			console.log(authTenant);
			if(authTenant?.id) {
				enableButton();
				return;
			}
		}
		disableButton();
	}

	const triggerLogin = async () => {
		console.log("triggered login")
		console.log(email);
		if(!isButtonDisabled) {
			console.log("login notdisabled");
			let emailSplit = emailRegex.exec(email);
			let domain = emailSplit[1] + emailSplit[2];
			let authTenant = await fetch(`${apiBase}/auth/tenant?domain=${domain}`, {
				headers: {
					"Content-Type": "application/json",
					"Accept": "application/json"
				},
				credentials: "include",
			})
				.then(res => res.json());
			console.log(authTenant);
			if(authTenant?.id) {
				window.location.replace(getAuthURL() + document.location.origin + `&authTenantIdentifier=${authTenant.id}`);
			}
		}
	}

	return (
		<>
			<div className={"container login-container"}>
				<div className={"row"}>
					<div className={"col-12 d-flex justify-content-center pt-4 pb-4 login-logo"}>
						<img src={logo75} alt={"MT XR"}/>
					</div>
					<div className={"col-12 justify-content-center pb-4"}>
						<div className={"input-group"}>
							<div className={"input-group-prepend"}>
								<span className={"input-group-text"}>Email</span>
							</div>
							<input type={"text"} className={"form-control input-text"} onChange={onEmailChange}/>
						</div>
					</div>
					<div className={"col-12 d-flex justify-content-center pt-4"}>
						<Button disabled={isButtonDisabled} onClick={triggerLogin}>Login</Button>
					</div>
				</div>
			</div>
		</>
	);
};

export default Login;
