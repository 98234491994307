import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ErrorHandler from "../../app/ErrorHandler";
import { useGetGroupsQuery } from "../../app/services/group";
import { Titlebar, TitleType } from "../../layouts/Content";
import ListOverviewTemplate from "../../layouts/ListOverviewTemplate";
import GroupList from "../../modules/lists/GroupList";
import SelectUserModal from "../../modules/Modals/SelectUserModal";

const GroupOverview = () => {
	const navigate = useNavigate();
	const {
		data,
		isLoading,
		error,
		isSuccess: loadSuccess,
	} = useGetGroupsQuery();

	const handleNewClick = () => {
		navigate("/groups/new");
	};

	useEffect(() => {
		!isLoading && error && ErrorHandler(error);
	}, [error, isLoading]);

	return (
		<>
			<Titlebar type={TitleType.Overview} title={"Group"} />
			{/*  GRP OVERVIEW */}
			<ListOverviewTemplate
				addCallback={handleNewClick}
				isLoading={isLoading}
			>
				{loadSuccess && <GroupList data={data} />}
			</ListOverviewTemplate>
			<SelectUserModal />
		</>
	);
};

export default GroupOverview;
