const ErrorHandler = (error) => {
	const handleError = () => {
		console.log("ErrorHandler");
		console.log(error);
		switch (error.status.toString()) {
			case "FETCH_ERROR":
				break;
			case "403":
			case "401":
				if(window.location.pathname !== "/Login") {
					window.location.replace("/Login");
				}
				//window.location.replace(getAuthURL() + document.URL);
				break;
			default:
				if(window.location.pathname !== "/Login") {
					window.location.replace("/Login");
				}
				//window.location.replace("/Login");
				//window.location.replace(getAuthURL() + document.URL);
				return true;
		}
	};

	return handleError();
};

export default ErrorHandler;
