import React, { useEffect, useState } from "react";
import { useGetUsersQuery } from "../../app/services/user";
import { Titlebar, TitleType } from "../../layouts/Content";
import ListOverviewTemplate from "../../layouts/ListOverviewTemplate";
import UserList from "../../modules/lists/UserList";
import SelectGroupsModal from "../../modules/Modals/SelectGroupsModal";

const UserOverview = () => {
	const [showNewRow, setShowNewRow] = useState(false);
	const { isSuccess: loadSuccess, data, isLoading } = useGetUsersQuery();
	const [userData, setUserData] = useState();

	useEffect(() => {
		loadSuccess && setUserData(data);
	}, [loadSuccess, data, setUserData]);

	const handleGroupSave = (user, newGroups) => {
		var updatedData = userData.map((item) =>
			item.id === user.id ? { ...item, groups: newGroups } : item
		);
		setUserData(updatedData);
	};

	const handleAddButton = () => {
		setShowNewRow((showNewRow) => !showNewRow);
	};

	return (
		<>
			{userData && (
				<>
					<Titlebar type={TitleType.Overview} title="Users" />
					<ListOverviewTemplate
						addCallback={handleAddButton}
						isLoading={isLoading}
					>
						{loadSuccess && (
							<UserList
								data={userData}
								newLine={showNewRow}
								toggleNewLine={setShowNewRow}
							/>
						)}
					</ListOverviewTemplate>
					<SelectGroupsModal
						saveCallback={(user, groups) =>
							handleGroupSave(user, groups)
						}
					/>
				</>
			)}
		</>
	);
};

export default UserOverview;
