import { BrowserRouter, Navigate, useRoutes } from "react-router-dom";
import "./App.css";
import { useGetSessionInfoQuery } from "./app/services/session";
import "./extra.css";
import { Layout, ViewonlyLayout } from "./layouts/Basic";
import { Content } from "./layouts/Content";
import { CustomizeApp } from "./pages/customize/CustomizeApp";
import {
	EnvironmentEdit,
	EnvironmentNew,
} from "./pages/environments/details/EnvironmentDetailRoutes";
import EnvironmentOverview from "./pages/environments/EnvironmentOverview";
import FourOFour from "./pages/errors/404";
import { GroupEdit, GroupNew } from "./pages/groups/detail/GroupDetailRoutes";
import GroupOverview from "./pages/groups/GroupOverview";
import {
	OrganizationEdit,
	OrganizationNew,
} from "./pages/organizations/detail/OrganizationDetailRoutes";
import Organizations from "./pages/organizations/Organizations";
import SettingsOrganization from "./pages/settings/SettingsOrganization";
import UserOverview from "./pages/users/UserOverview";
import {
	WorkflowNew,
	WorkflowView,
} from "./pages/workflows/details/WorkflowDetailRoutes";
import WorkflowOverview from "./pages/workflows/WorkflowOverview";
import {
	WorkplaceEdit,
	WorkplaceNew,
} from "./pages/workplaces/detail/WorkplaceDetailRoutes";
import WorkplaceOverview from "./pages/workplaces/WorkplaceOverview";
import "./siemens.scss";
import SettingsSystem from "./pages/settings/SettingsSystem";
import Login from "./pages/login/Login";

function App() {
	const { data: sessionData, isSuccess } = useGetSessionInfoQuery();

	const Routing = () => {
		let routes = useRoutes([
			//////////////////////// SIDEBAR LAYOUT PATHS
			{
				path: "/",
				element: <Layout />,
				children: [
					{
						index: true,
						element: <Navigate replace to="/workflows" />,
					},
					// USERS
					{
						path: "/users",
						element: <Content className={"content-wrapper viewonly"}/>,
						children: [
							{
								index: true,
								element: <UserOverview />,
							},
						],
					},
					// GROUPS
					{
						path: "/groups",
						parent: "groups",
						element: <Content className={"content-wrapper viewonly"} />,
						children: [
							{
								index: true,
								element: <GroupOverview />,
							},
							{
								path: "new",
								element: <GroupNew />,
							},
							{
								path: ":id",
								element: <GroupEdit />,
							},
							{
								path: ":id/:tab",
								element: <GroupEdit />,
							},
						],
					},
					// ORGS
					{
						path: "/organizations",
						element: <Content className={"content-wrapper viewonly"} />,
						children: [
							{
								index: true,
								element: <Organizations />,
							},
							{
								path: "new",
								element: <OrganizationNew />,
							},
							{
								path: ":id",
								element: <OrganizationEdit />,
							},
						],
					},
					// WORKPLACE
					{
						path: "/workplaces",
						element: <Content className={"content-wrapper viewonly"} />,
						children: [
							{
								index: true,
								element: <WorkplaceOverview />,
							},
							{
								path: "new",
								element: <WorkplaceNew />,
							},
							{
								path: ":id",
								element: <WorkplaceEdit />,
							},
						],
					},
					// WORKFLOWS
					{
						path: "/workflows",
						element: <Content className={"content-wrapper viewonly"} id="workflows" />,
						children: [
							{
								index: true,
								element: <WorkflowOverview />,
							},
							{
								path: "new",
								element: <WorkflowNew />,
							},
							{
								path: ":id",
								element: <WorkflowView editable="true" />,
							},
						],
					},
					// ENVS
					{
						path: "/environments",
						element: <Content className={"content-wrapper viewonly"} id="environments" />,
						children: [
							{
								index: true,
								element: <EnvironmentOverview />,
							},
							{
								path: "new",
								element: <EnvironmentNew />,
							},
							{
								path: ":id",
								element: <EnvironmentEdit />,
							},
						],
					},
					// SETTINGS
					{
						path: "/settings/organization",
						element: <Content className={"content-wrapper viewonly"} />,
						children: [
							{
								index: true,
								element: <SettingsOrganization />,
							},
						],
					},
					{
						path: "/settings/system",
						element: <Content className={"content-wrapper viewonly"}/>,
						children: [
							{
								index: true,
								element: <SettingsSystem />
							},
						]
					},
					// SETTINGS
					{
						path: "/customize",
						element: <Content className={"content-wrapper viewonly"} />,
						children: [
							{
								index: true,
								element: <CustomizeApp />,
							},
						],
					},
				],
			},
			////////////////////////  NO SIDEBAR LAYOUT PATHS
			{
				path: "/view",
				element: <ViewonlyLayout />,
				children: [
					{
						element: <Content classes="fullwidth" />,
						children: [
							{
								index: true,
								element: <WorkflowView
									viewonly={true}
									detail={false}
								/>
							}
						],
					},
					{
						path: "workflows",
						element: <Content classes="fullwidth" />,
						children: [
							{
								index: true,
								element: (
									<WorkflowView
										viewonly={true}
										detail={false}
									/>
								),
							},
							{
								path: "all",
								element: (
									<WorkflowView
										viewonly={true}
										detail={false}
									/>
								),
							},
							{
								path: ":id",
								element: (
									<WorkflowView
										viewonly={true}
										detail={true}
									/>
								),
							},
						],
					},
				],
			},
			{
				path: "/Login",
				element: <Login/>
			},
			{
				path: "*",
				element: <Layout />,
				children: [
					{
						path: "*",
						element: <Content />,
						children: [
							{
								path: "*",
								element: <FourOFour />,
							},
						],
					},
				],
			}
		]);
		return routes;
	};

	return (
		<>
			<BrowserRouter>
				{isSuccess && sessionData.message === "Not signed in" ? (
					//ErrorHandler({ status: "403" })
					<Login />
				) : (
					<Routing />
				)}
			</BrowserRouter>
		</>
	);
}

export default App;
