import React, { useEffect } from "react";
import { useGetGroupsQuery } from "../../app/services/group";
import { SmallListItem } from "../../components/listItems/SmallListItem";
import Searchbar from "../../components/search/Searchbar";

const GroupSelection = ({ callback, selection, setSelection }) => {
	const { data: groups, isSuccess: loadSuccess } = useGetGroupsQuery();

	useEffect(() => {}, [selection, groups]);

	const getUnselectedGroups = () => {
		let unselectedGroups = groups.filter(
			(o) => !selection.some((group) => group.id === o.id)
		);
		return unselectedGroups;
	};

	const handleSelect = (add) => {
		setSelection((selection) =>
			selection.includes(add)
				? selection.filter((item) => item.id !== add.id)
				: [...selection, add]
		);
	};

	return (
		<>
			{loadSuccess && (
				<>
					{selection &&
						selection.length > 0 &&
						selection.map((item) => (
							<SmallListItem
								key={"sli-" + item.id}
								data={item}
								selectable
								selectCallback={() =>
									callback
										? callback(item)
										: handleSelect(item)
								}
							/>
						))}

					<Searchbar
						callback={handleSelect}
						data={getUnselectedGroups()}
					/>
				</>
			)}
		</>
	);
};

export default GroupSelection;
