import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getEndpoint } from "./basics";
const base = getEndpoint();

export const environmentApi = createApi({
	reducerPath: "environmentApi",
	baseQuery: fetchBaseQuery({
		baseUrl: base,
		prepareHeaders: (headers) => {
			headers.set("Content-Type", "application/json");
			headers.set("Accept", "application/json");
			return headers;
		},
		credentials: "include",
	}),
	tagTypes: ["Environment"],
	endpoints: (builder) => ({
		getEnvironments: builder.query({
			query: () => ({
				// When performing a mutation, you typically use a method of
				// PATCH/PUT/POST/DELETE for REST endpoints
				// fetchBaseQuery automatically adds `content-type: application/json` to
				// the Headers and calls `JSON.stringify(patch)`
				url: `environment`,
				method: "GET",
			}),
		}),
		getEnvironment: builder.query({
			query: (id) => ({
				url: `environment/${id}`,
				method: "GET",
			}),
			provideTags: ["Environment"],
		}),
		getUnassignedWorkflowsOfEnvironment: builder.query({
			query: (id) => ({
				url: `environment/${id}/unassignedWorkflows`,
				method: "GET",
			}),
			provideTags: ["Environment"],
		}),
		createEnvironment: builder.mutation({
			query: (data) => ({
				url: `environment`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: (result, error, { id }) => [
				{ type: "Environment", id },
			],
		}),
		updateEnvironment: builder.mutation({
			query: ({ id, patch }) => ({
				url: `environment/${id}`,
				method: "PATCH",
				body: patch,
			}),
			invalidatesTags: (result, error, { id }) => [
				{ type: "Environment", id },
			],
		}),
		deleteEnvironment: builder.mutation({
			query: (id) => ({
				url: `environment/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: (result, error, { id }) => [
				{ type: "Environment", id },
			],
		}),
	}),
});

// Export hooks for usage in functional components
export const {
	useGetEnvironmentsQuery,
	useGetEnvironmentQuery,
	useGetUnassignedWorkflowsOfEnvironmentQuery,
	useCreateEnvironmentMutation,
	useDeleteEnvironmentMutation,
	useUpdateEnvironmentMutation,
} = environmentApi;
