import React from "react";

const SmallSpinner = ({ text, ...props }) => {
	return (
		<div className={`spinner-container small ${props.className}`}>
			<div className="spinner-content">
				<div className="loading-text">{text}</div>
				<div className="loader cube"></div>
				<div className="loader cube"></div>
				<div className="loader cube"></div>
				<div className="loader cube"></div>
			</div>
		</div>
	);
};

export default SmallSpinner;
