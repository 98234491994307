import { useEffect, useRef, useState } from "react";
import { FileError } from "../../app/Messages";
import CustomVideo from "../../components/video/CustomVideo";
import Recorder from "../voiceEditor/Recorder";

export const Mediafile = ({ file, ...props }) => {
	// const mediafile = file.mediafiles[file.mediafiles.length - 1];
	const mediafile = file
		? file.length > 0
			? file[file.length - 1]
			: file
		: null;
	if (mediafile) {
		switch (mediafile.type) {
			case "video/mp4":
				return (
					<CustomVideo mediafile={mediafile} innerRef={props.ref} />
				);
			case "image/png":
			case "image/jpeg":
			case "image/gif":
				var image =
					mediafile instanceof File
						? URL.createObjectURL(mediafile)
						: mediafile.mediaUrl;
				return (
					<>
						{mediafile && (
							<div className="image-container">
								<img src={image} alt={mediafile.title} style={{width: "100%"}}/>
							</div>
						)}
					</>
				);
			default:
				return (
					<p>
						{FileError.UNSUPPORTED}: {mediafile.type}{" "}
						{mediafile.name}
					</p>
				);
		}
	}
};

export const RecorderMediafile = ({ files, editable, ...props }) => {
	const [mediafile, setMediafile] = useState(null);

	const videoRef = useRef(null);
	const audioRef = useRef(null);
	const recordRef = useRef(null);

	useEffect(() => {
		if (files.length > 0) {
			setMediafile(files[0]);
		}
	}, [files]);

	useEffect(() => {}, [videoRef]);

	const onPlay = () => {
		videoRef.current.play();
		audioRef && audioRef.current.playPause();
		recordRef && recordRef.current && recordRef.current.playPause();
	};

	const onPause = () => {
		videoRef.current.pause();
		audioRef && audioRef.current.playPause();
		recordRef && recordRef.current && recordRef.current.playPause();
	};

	return (
		<>
			{mediafile && videoRef && (
				<CustomVideo mediafile={mediafile} innerRef={videoRef} />
			)}
			{editable && videoRef && audioRef && (
				<Recorder
					videoRef={videoRef}
					mediaFiles={files}
					audioRef={audioRef}
					recordRef={recordRef}
					onPlay={onPlay}
					onPause={onPause}
					editMode={editable}
				/>
			)}
		</>
	);
};
