import { useEffect, useState } from "react";
import {
	useGetWorkflowQuery,
} from "../../app/services/workflow";

const WorkflowTitle = ({id}) => {
	const [workflowTitle, setWorkflowTitle] = useState("Workflow title");
	const { data } = useGetWorkflowQuery(id);
	useEffect(() => {
		if(data?.name) {
			setWorkflowTitle(data.name);
		}
	}, [data]);
	useEffect(() => {}, [id]);
	return (
		<>
			{workflowTitle}
		</>
	);
}

const Navbar = ({ id, extensions }) => {

	const wrapNavItem = (item, index) => {
		return (
			<li className="nav-item" key={"menu-extension"+index}>
				{item}
			</li>
		);
	}

	return (
		<>
			{/* <!-- Navbar --> */}
			<nav className="navbar navbar-expand navbar-dark">
				<ul className="navbar-nav">
					<li className="nav-item base-container">
						<a href="/view/workflows/" className="button btn-md">
							All Workflows
						</a>
					</li>
				</ul>
				<h1 className="navbar-title">
					{id && <WorkflowTitle id={id}/>}
				</h1>
				<ul className="navbar-nav ml-auto">
					{extensions.map((e, index) => wrapNavItem(e, index))}
				</ul>
			</nav>
		</>
);
};

export default Navbar;
