import React, { Component } from "react";

import WaveSurfer from "wavesurfer.js";
import { getEndpoint } from "../../app/services/basics";
import { Mediafile } from "../mediafile/Mediafile";

const audioEncoder = require("audio-encoder");

const encodeMP3 = async (audioBuffer) => {
	return new Promise((resolve, reject) => {
		audioEncoder(
			audioBuffer,
			192,
			(progress) => {},
			(audio) => resolve(audio)
		);
	});
};
class VideoComponent extends Component {
	constructor(props) {
		super(props);
		this.vidRef = React.createRef();
		this.state = {
			caption: "",
			text: "",
			audioId: "",
			referenceAudioId: "",
			playActive: false,
			recordingActive: false,
			referenceMuted: true,
			statusMessage: "Application started...",
			video: "workflow.mp4",
		};
		this.text = "";

		this.step = props.step;
		this.editmode = props.editmode;
		this.hasSTT = this.props.languageInfo.stt;
		this.hasTTS = this.props.languageInfo.tts;

		this.register = this.props.register;
	}

	componentDidMount() {
		const video = this.step.mediafiles
			.filter((file) => file.type === "video/mp4")
			.pop();

		this.setState({
			caption: this.step.title,
			text: this.step.description,

			video: video,
		});
	}

	play(e) {
		if (e) {
			e.preventDefault();
		}
		this.setState({
			playActive: true,
		});
	}

	pause(e) {
		if (e) {
			e.preventDefault();
		}
		this.setState({
			playActive: false,
		});
		if (this.videoAudioWs) {
			this.videoAudioWs.pause();
		}
		this.stopRecording();
	}

	handleChange(property, e) {
		let c = {};
		c[property] = e.target.value;
		this.setState(c);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.language !== prevProps.language) {
			const video = this.step.mediafiles
				.filter((file) => file.type === "video/mp4")
				.pop();

			this.setState({
				caption: this.step.title,
				text: this.step.description,
				video: video,
			});
		}
	}
	render() {
		return (
			<>
				<Mediafile file={this.state.video} />
			</>
		);
	}
}
class AudioComponent extends Component {
	constructor(props) {
		super(props);
		this.audioRef = React.createRef();
		this.captionRef = React.createRef();
		this.textRef = React.createRef();
		this.state = {
			caption: "",
			text: "",
			audioId: "",
			referenceAudioId: "",
			playActive: false,
			recordingActive: false,
			referenceMuted: true,
			statusMessage: "Application started...",
			recordingLength: 60,
		};
		this.audioData = [];
		this.audioBuffer = new AudioBuffer({
			channelCount: 1,
			length: 44100,
			sampleRate: 44100,
		});
		this.audioContext = new AudioContext({
			sampleRate: 44100,
		});
		this.startPosition = 0;
		this.mp3Progress = 0;
		this.text = "";
		this.audioTainted = false;

		this.step = props.step;
		this.editmode = props.editmode;

		this.hasSTT = this.props.languageInfo.stt;
		this.hasTTS = this.props.languageInfo.tts;
		this.register = this.props.register;

		this.record = this.props.recording;
	}

	getWavHeader(options) {
		const numFrames = options.numFrames;
		const numChannels = options.numChannels || 2;
		const sampleRate = options.sampleRate || 44100;
		const bytesPerSample = options.isFloat ? 4 : 2;
		const format = options.isFloat ? 3 : 1;

		const blockAlign = numChannels * bytesPerSample;
		const byteRate = sampleRate * blockAlign;
		const dataSize = numFrames * blockAlign;

		const buffer = new ArrayBuffer(44);
		const dv = new DataView(buffer);

		let p = 0;

		function writeString(s) {
			for (let i = 0; i < s.length; i++) {
				dv.setUint8(p + i, s.charCodeAt(i));
			}
			p += s.length;
		}

		function writeUint32(d) {
			dv.setUint32(p, d, true);
			p += 4;
		}

		function writeUint16(d) {
			dv.setUint16(p, d, true);
			p += 2;
		}

		writeString("RIFF"); // ChunkID
		writeUint32(dataSize + 36); // ChunkSize
		writeString("WAVE"); // Format
		writeString("fmt "); // Subchunk1ID
		writeUint32(16); // Subchunk1Size
		writeUint16(format); // AudioFormat https://i.stack.imgur.com/BuSmb.png
		writeUint16(numChannels); // NumChannels
		writeUint32(sampleRate); // SampleRate
		writeUint32(byteRate); // ByteRate
		writeUint16(blockAlign); // BlockAlign
		writeUint16(bytesPerSample * 8); // BitsPerSample
		writeString("data"); // Subchunk2ID
		writeUint32(dataSize); // Subchunk2Size

		return new Uint8Array(buffer);
	}

	updateAudioWs() {
		if (this.audioWS) {
			this.audioWS.empty();
			this.audioWS.loadDecodedBuffer(this.audioBuffer);
			this.audioWS.setHeight(50);
		}
	}

	componentDidMount() {
		const audio = this.step.mediafiles
			.filter((file) => file.type === "audio/mpeg")
			.pop();
		const video = this.step.mediafiles
			.filter((file) => file.type === "video/mp4")
			.pop();

		if (!this.audioWS) {
			this.audioWS = WaveSurfer.create({
				container: this.audioRef.current,
				height: 50,
				waveColor: "#00D7A0",
				progressColor: "#00646E",
				interact: true,
				responsive: true,
			});

			this.updateAudioWs();
		}

		this.setState({
			caption: this.step.title,
			text: this.step.description,
			audioPlaying: false,
			video: video,
		});

		if (this.record) {
			this.audioWS.loadDecodedBuffer(this.record);
		} else if (audio && this.audioWS) {
			audio.mediaUrl && this.audioWS.load(audio.mediaUrl);
		}
	}

	play(e) {
		if (e) {
			e.preventDefault();
		}
		this.setState({
			playActive: true,
		});
	}

	pause(e) {
		if (e) {
			e.preventDefault();
		}
		this.setState({
			playActive: false,
		});
		if (this.audioWS) {
			this.audioWS.pause();
		}
		this.stopRecording();
	}

	handlePlayAudio(e) {
		if (e) {
			e.preventDefault();
		}
		if (this.state.audioPlaying) {
			this.audioWS.pause();

			this.setState({
				audioPlaying: false,
			});
		} else {
			this.setState({
				audioPlaying: true,
			});
			this.audioWS.play();
		}
	}

	async pushDataToAudioBuffer() {
		try {
			let blob = new Blob(this.audioData);
			let arrayBuffer = await blob.arrayBuffer();

			let audioTmpBuffer = await this.audioContext.decodeAudioData(
				arrayBuffer
			);
			let targetPosition = this.startPosition * 44100;
			this.audioBuffer.copyToChannel(
				audioTmpBuffer.getChannelData(0),
				0,
				targetPosition
			);
		} catch {}
	}

	async startRecording(e) {
		if (e) {
			e.preventDefault();
		}
		if (!this.mediaRecorder) {
			this.initializeAudioBuffer(e);
			this.audioTainted = true;
			this.startPosition = 0;
			this.play(e);
			this.audioWS.play();
			this.setState({
				recordingActive: true,
				audioPlaying: true,
			});
			this.audioData = [];
			let stream = await navigator.mediaDevices.getUserMedia({
				audio: true,
			});
			this.mediaRecorder = new MediaRecorder(stream);
			this.mediaRecorder.ondataavailable = (e) => {
				if (e.data.size) {
					this.audioData.push(e.data);
					this.pushDataToAudioBuffer().then((r) =>
						this.updateAudioWs()
					);
				}
			};
			this.mediaRecorder.onstop = (e) => {
				this.pushDataToAudioBuffer().then((r) => this.updateAudioWs());
				delete this.mediaRecorder;
			};
			this.mediaRecorder.start(250);
		}
	}

	stopRecording(e) {
		if (e) {
			e.preventDefault();
		}
		if (this.mediaRecorder) {
			this.setState({
				recordingActive: false,
			});
			if (this.mediaRecorder.state === "recording") {
				this.mediaRecorder.stop();
				this.pause(e);
				this.props.setAudio(this.audioBuffer);
			}
		}
	}

	initializeAudioBuffer(e) {
		if (e) {
			e.preventDefault();
		}

		this.audioBuffer = new AudioBuffer({
			channelCount: 1,
			length: this.state.recordingLength * 44100,
			sampleRate: 44100,
		});
		this.updateAudioWs();
	}

	exportWav(e) {
		if (e) {
			e.preventDefault();
		}
		let pcmData = this.audioBuffer.getChannelData(0).buffer;
		let numFrames = pcmData.byteLength / Float32Array.BYTES_PER_ELEMENT;
		let headerBytes = this.getWavHeader({
			isFloat: true,
			numChannels: 1,
			sampleRate: 44100,
			numFrames,
		});
		let wavBytes = new Uint8Array(headerBytes.length + pcmData.byteLength);
		wavBytes.set(headerBytes, 0);
		wavBytes.set(new Uint8Array(pcmData), headerBytes.length);
		let wav = new Blob([wavBytes], { type: "audio/wav" });
		let downloadLink = document.createElement("a");
		downloadLink.href = URL.createObjectURL(wav);
		downloadLink.setAttribute("download", "my-audio.wav"); // name file
		downloadLink.click();
	}

	/**
	 * @return {Promise<Blob>}
	 */

	async save(e) {
		if (e) {
			e.preventDefault();
		}
		const apiBaseUrl = getEndpoint();
		let audioId = this.state.audioId;
		if (this.audioTainted) {
			this.setState({
				statusMessage: "Audio is tainted. Reencoding to MP3",
			});

			let mp3Blob = await this.encodeMP3();
			let formData = new FormData();
			formData.append("audio", mp3Blob);
			this.setState({
				statusMessage: "Audio Reencoding done, uploading...",
			});
			let result = await fetch(`${apiBaseUrl}/file`, {
				method: "POST",
				body: formData,
			}).then((res) => res.json());
			this.setState({
				statusMessage: "Upload done. Updating workflow",
			});
			audioId = `${result.fileId}.mp3`;
		}
		let payload = {
			caption: this.state.caption,
			text: this.state.text,
			language: this.props.language,
			audioId,
			workflowId: this.props.workflowId,
			stepId: this.props.stepId,
		};
		await fetch(`${apiBaseUrl}/workflow/update-language`, {
			method: "POST",
			body: JSON.stringify(payload),
		});
		this.setState({
			statusMessage: "Update successful",
		});
	}

	handleChange(property, e) {
		let c = {};
		c[property] = e.target.value;
		this.setState(c);
	}

	renderStartStop() {
		if (this.state.playActive) {
			return (
				<div className="voice-control">
					<button
						className=" btn btn-sm btn-secondary"
						onClick={(e) => this.pause(e)}
					>
						<span className="icon"></span> Pause
					</button>
				</div>
			);
		} else {
			return (
				<div className="voice-control">
					<button
						className=" btn btn-sm btn-secondary"
						onClick={(e) => this.play(e)}
					>
						<span className="icon"></span> Play
					</button>
				</div>
			);
		}
	}

	renderRecording() {
		if (this.state.recordingActive) {
			return (
				<div className="voice-control">
					<button
						className=" btn btn-sm btn-secondary"
						onClick={(e) => this.stopRecording(e)}
					>
						<span className="icon"></span> Stop recording
					</button>
				</div>
			);
		} else {
			return (
				<>
					<select
						name="duration"
						id="duration"
						onChange={(e) =>
							this.setState({ recordingLength: e.target.value })
						}
						value={this.state.recordingLength}
					>
						<option value="30">30s</option>
						<option value="45">45s</option>
						<option value="60">60s</option>
					</select>
					<div className="voice-control">
						<button
							className=" btn btn-sm btn-secondary"
							onClick={(e) => this.startRecording(e)}
						>
							<span className="icon">+</span> Start recording
						</button>
					</div>
				</>
			);
		}
	}

	renderMute() {
		if (this.state.referenceMuted) {
			return (
				<button
					className=" btn btn-sm btn-secondary"
					onClick={(e) => this.unMuteReference(e)}
				>
					<span className="icon"></span> Unmute reference
				</button>
			);
		} else {
			return (
				<button
					className=" btn btn-sm btn-secondary"
					onClick={(e) => this.muteReference(e)}
				>
					<span className="icon"></span> Mute reference
				</button>
			);
		}
	}

	playButton() {
		return (
			<button
				className="btn btn-sm btn-secondary btn-round mr-3"
				onClick={(e) => this.handlePlayAudio(e)}
			>
				{this.audioWS.isPlaying() ? (
					<span className="icon"></span>
				) : (
					<span className="icon"></span>
				)}
			</button>
		);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.language !== prevProps.language) {
			const video = this.step.mediafiles
				.filter((file) => file.type === "video/mp4")
				.pop();
			const audio = this.step.mediafiles
				.filter((file) => file.type === "audio/mpeg")
				.pop();
			const record = this.step.mediafiles
				.filter((file) => file.type === "audio/wav")
				.pop();

			this.setState({
				caption: this.step.title,
				text: this.step.description,
				video: video,
			});
			if (this.recordingWs && record) {
				this.audioTainted = false;
				this.recordingWs.load(record.mediaUrl);
			}
			if (this.audioWS) {
				this.audioWS.load(audio.mediaUrl);
			}
		}
	}
	async convertTextToSpeech(e) {
		if (e) {
			e.preventDefault();
		}
	}
	async convertSpeechToText(e) {
		if (e) {
			e.preventDefault();
		}
		const apiBaseUrl = getEndpoint();
		let audioId = this.state.audioId;
		if (this.audioTainted) {
			this.setState({
				statusMessage: "Audio is tainted. Reencoding to MP3",
			});
			let mp3Blob = await this.encodeMP3();
			this.setState({
				statusMessage: "Reencoding to MP3 done. uploading file...",
			});
			// console.log(mp3Blob);
			let formData = new FormData();
			formData.append("audio", mp3Blob);
			let result = await fetch(`${apiBaseUrl}/file`, {
				method: "POST",
				body: formData,
			}).then((res) => res.json());
			audioId = `${result.fileId}.mp3`;
			this.setState({
				statusMessage: "File upload done.",
			});
		}
		this.setState({
			statusMessage:
				"Starting speech to text conversion. This may take some time...",
		});
		let result = await fetch(`${apiBaseUrl}/tools/generateTextFromSpeech`, {
			method: "POST",
			body: JSON.stringify({
				fileId: audioId,
				language: this.props.language,
			}),
		}).then((res) => res.json());
		// console.log(result);
		if (result.transcript.transcripts[0]) {
			// console.log(result.transcript);
			this.setState({
				text:
					this.state.text +
					result.transcript.transcripts[0].transcript,
				statusMessage: "Transcription done. Text appended.",
			});
		}
	}

	render() {
		return (
			<div className="description">
				<div className="mb-4">
					<label>Audio</label>
					<div className="flex flex-row">
						{this.audioWS && this.playButton()}
						<div
							style={{
								width: "100%",
								height: "50px",
							}}
							ref={this.audioRef}
						></div>
					</div>
				</div>
				<div className="voice-control-container flex flex-row mt-5">
					{this.editmode && (
						<>
							{this.renderRecording()}
							<button
								className=" btn btn-sm btn-secondary"
								onClick={(e) => this.initializeAudioBuffer(e)}
							>
								Clear Audio
							</button>
						</>
					)}
				</div>
			</div>
		);
	}
}

export { VideoComponent, AudioComponent, encodeMP3 };
