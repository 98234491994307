import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import md5 from "md5";
import Select from "../../components/select/Select";
import ErrorHandler from "../../app/ErrorHandler";
import { useGetSessionInfoQuery } from "../../app/services/session";
import { useGetOrganizationsQuery } from "../../app/services/organization";
import IconSidebar from "./IconSidebar";
import SidebarMenu from "./SidebarMenu";
import { useDispatch } from "react-redux";
import { setCurrentOrganization } from "../../app/slices/appStateSlice";
import logo75 from '../../static/logo75.png';

const Sidebar = () => {
	const {
		data,
		isLoading,
		error,
		isSuccess: loadSuccess,
	} = useGetSessionInfoQuery();
	const { data: organizations, isSuccess: orgaLoadSuccess } =
		useGetOrganizationsQuery();
	const dispatch = useDispatch();

	const [avatarURL, setAvatarUrl] = useState("");
	const [storedOrg, setStoredOrg] = useState();

	useEffect(() => {
		var id = localStorage.getItem("selected-org");

		if (orgaLoadSuccess) {
			if (id === undefined || id === "undefined" || id === null) {
				setStoredOrg(organizations[0]);
				dispatch(setCurrentOrganization(organizations[0]));
			} else {
				var orgSelection = organizations.find((org) => {
					return org.id === id;
				});
				setStoredOrg(orgSelection);
				dispatch(setCurrentOrganization(orgSelection));
			}
		}
	}, [storedOrg, organizations, orgaLoadSuccess, dispatch]);

	useEffect(() => {
		if (!isLoading) {
			if (data && data.message === "Not signed in") {
				var myError = { status: "403" };
				ErrorHandler(myError);
			}
			loadSuccess &&
				setAvatarUrl(
					"https://www.gravatar.com/avatar/" +
						md5(data?.email) +
						".jpg"
				);
		}
	}, [data, error, isLoading, loadSuccess]);

	const handleOrgSwitch = (org) => {
		localStorage.setItem("selected-org", org.id);
		setStoredOrg(org);
	};

	const handleOpenSidebar = (e) => {
		if (
			document.getElementById("sidebar").classList.contains("open") &&
			e.target.type === "button"
		) {
			return false;
		}
		document.getElementById("sidebar").classList.toggle("open");
	};

	const handleOrgSelection = (id) => {
		var orgSelection = organizations.find((org) => org.id === id);
		handleOrgSwitch(orgSelection);
		dispatch(setCurrentOrganization(orgSelection));
	};

	return (
		<>
			{loadSuccess && orgaLoadSuccess && (
				<>
					<aside
						onClick={(e) => handleOpenSidebar(e)}
						id="sidebar"
						className="main-sidebar sidebar-dark-primary elevation-4"
					>
						<a href="/" className="brand-link  pb-4 mt-4">
							<span className="brand-text font-weight-light">
								<h2><img src={logo75} alt="MT XR" style={{height: "50px", margin: "0 auto"}}/></h2>
							</span>
						</a>
						<div className="sidebar">
							<div className="user-panel mt-4 pb-4 d-flex">
								<div className="image">
									<img
										src={avatarURL}
										className="img-circle elevation-2"
										alt="User"
									/>
								</div>
								<div className="info">
									<NavLink to="/">
										{data.name} {data.surname}
									</NavLink>
								</div>
							</div>

							<nav className="mt-2">
								{storedOrg && storedOrg !== undefined && (
									<SidebarMenu
										OrgSelection={
											<>
												<Select
													id="organization-selector"
													data={organizations}
													selected={storedOrg}
													selectCallback={(id) =>
														handleOrgSelection(id)
													}
												/>
											</>
										}
									/>
								)}
							</nav>
						</div>

						<div className="sidebar-opener" />
						<div className="sidebar-footer versioning">
							Version 1.4.1
						</div>
					</aside>
					{storedOrg && (
						<IconSidebar
							storedOrg={storedOrg}
							handleOrgSelection={handleOrgSelection}
						/>
					)}
				</>
			)}
		</>
	);
};

export default Sidebar;
