import React from "react";

const Preview = ({ filename, large, medium, zoomable, onClick }) => {
	const getSizeClass = () => {
		let sizeClass = "";

		if (large) {
			sizeClass += "preview-lg ";
		} else if (medium) {
			sizeClass += "preview-md ";
		} else {
			sizeClass += "preview-sm ";
		}

		if (zoomable) {
			sizeClass += "zoomable";
		}

		return sizeClass.trim();
	};

	const handleOnClick = () => {
		if (filename) {
			onClick();
		}
	};

	return (
		<div className={`preview-box ${getSizeClass()}`}>
			<div
				className={filename ? "preview" : "preview noimage"}
				onClick={handleOnClick}
			>
				{filename && <img src={filename} alt="Preview" />}
			</div>
		</div>
	);
};

export default Preview;
