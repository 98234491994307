import React from "react";
import {Outlet, useOutletContext} from "react-router-dom";
import { ConfirmDeleteModal } from "../modules/Modals/ConfirmDeleteModal";
import TitleWithBreadcrumb from "../modules/title/TitleWithBreadcrumb";
import { useSelector } from "react-redux";
import { getCurrentOrganization } from "../app/slices/appStateSlice";

/**
 *
 * @param parent
 * @param title
 * @param children
 * @param props
 * @return {Element}
 * @constructor
 */
const Content = ({ parent, title, children, ...props }) => {
	const currentOrg = useSelector(getCurrentOrganization);
	const [navBarExtensions, setNavbarExtensions] = useOutletContext();
	return (
		<>
			<div {...props}>
				<section className="content">
					<Outlet context={[navBarExtensions, setNavbarExtensions]}/>
				</section>
			</div>
			{currentOrg && <ConfirmDeleteModal id="delete" />}
		</>
	);
};

const TitleType = {
	Preview: "Preview",
	Edit: "Edit",
	Create: "Create",
	Overview: "Overview",
};

const Titlebar = ({ parent, title, ...props }) => {
	const generateTitle = () => {
		var fullTitle;
		switch (props.type) {
			default:
				fullTitle = title;
				break;
			case TitleType.Overview:
				fullTitle = title + " Overview";
				break;
			case TitleType.Create:
				fullTitle = "New " + title;
				break;
		}
		return fullTitle;
	};

	return (
		<>
			{title !== undefined && (
				<section className="content-header mb-5">
					<TitleWithBreadcrumb
						parent={parent}
						title={generateTitle()}
					/>
				</section>
			)}
		</>
	);
};

export { Titlebar, Content, TitleType };
