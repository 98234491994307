import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { createEntityWithImage } from "../../../app/Helpers";
import { ErrorMessages, SuccessMessages } from "../../../app/Messages";
import {
	useCreateARObjectMutation,
	useUpdateARObjectMutation,
} from "../../../app/services/ARObject";
import {
	useCreateIconMutation,
	useUpdateIconMutation,
} from "../../../app/services/icon";
import {
	useGetARObjsFromOrganizationQuery,
	useGetIconsFromOrganizationQuery,
} from "../../../app/services/organization";
import {
	getCurrentOrganization,
	showModal,
} from "../../../app/slices/appStateSlice";
import {
	NotificationType,
	startNotification,
} from "../../../app/slices/notifactionStateSlice";
import Dropzone from "../../../components/dragndrop/Dropzone";
import { CustomModal } from "../../../components/modal/Modal";
import { ModalTypes } from "../ConfirmDeleteModal";

const UploadImageModal = ({ modal }) => {
	const dispatch = useDispatch();

	const [object, setObject] = useState("");
	const [skip, setSkip] = useState(true);
	const currentOrg = useSelector(getCurrentOrganization);
	const [createARObj] = useCreateARObjectMutation();
	const [updateARObject] = useUpdateARObjectMutation();
	const { refetch: refetchAR } = useGetARObjsFromOrganizationQuery(
		currentOrg.id,
		{ skip }
	);

	const [createIcon] = useCreateIconMutation();
	const [updateIcon] = useUpdateIconMutation();
	const { refetch: refetchIcons } = useGetIconsFromOrganizationQuery(
		currentOrg.id,
		{ skip }
	);

	useEffect(() => {
		currentOrg && setSkip(false);
	}, [currentOrg]);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const handleSave = async (formData) => {
		// e.preventDefault(); // prevent default form submission

		// const formData = new FormData(document.querySelector("form")); // create form data from form
		// const postData = Object.fromEntries(formData.entries()); // convert form data to plain object
		const postData = formData; // convert form data to plain object

		postData.organization = currentOrg.id; // add current organization to post data

		const { type } = modal;
		if (type === ModalTypes.ARObject) {
			await createEntityWithImage({
				type: "arobject",
				createFn: createARObj,
				updateFn: updateARObject,
				onSuccess,
				onError,
				postData,
				object,
				patchAttribute: "objectId",
			});
		} else {
			await createEntityWithImage({
				type,
				createFn: createIcon,
				updateFn: updateIcon,
				onSuccess,
				onError,
				postData,
				object,
				patchAttribute: "imageId",
			});
		}
	};

	const onError = (error) => {
		console.error(error);
		dispatch(
			startNotification({
				message: error.message,
				type: NotificationType.ERROR,
			})
		);
	};

	const onSuccess = () => {
		if (modal.type === ModalTypes.ARObject) {
			refetchAR();
		} else {
			refetchIcons();
		}

		dispatch(showModal("none")); // hide modal
		dispatch(
			// show success notification
			startNotification({
				message: SuccessMessages.UPLOAD,
				type: NotificationType.SUCCESS,
			})
		);
	};

	return (
		<>
			{modal && (
				<CustomModal
					id="create"
					title={"Create new " + modal.type}
					okButton={
						<button
							type="button"
							className="btn btn-primary ml-3"
							onClick={handleSubmit(handleSave)}
						>
							<i className="icon"></i>
							Save
						</button>
					}
				>
					<form className="fullWidth">
						<div className="row mb-5 mt-2 ">
							<div className="col-md-12">
								<div className={`label-container`}>
									<label htmlFor={"name"}>Object Name</label>
								</div>
								<div
									className={`input-container ${
										errors["name"] && "error"
									}`}
								>
									<input
										name={"name"}
										type={"text"}
										className={`form-control mr-2 centered ${
											errors["name"] && "error"
										}`}
										defaultValue={""}
										{...register("name", {
											required: ErrorMessages.REQUIRED,
										})}
									/>
									{errors && errors["name"] && (
										<span className="error">
											{errors["name"]?.message}
										</span>
									)}
								</div>
							</div>
						</div>
						<div className="row mb-2 mt-2 ">
							<div className="col-md-12">
								<Dropzone
									callback={setObject}
									filetypes={
										modal.type === ModalTypes.ARObject && {
											"*": "*",
										}
									}
								/>
							</div>
						</div>
					</form>
				</CustomModal>
			)}
		</>
	);
};

export default UploadImageModal;
