import React, { useEffect, useRef, useState } from "react";

const VIDEO_STATE = {
	PLAY: "play",
	PAUSE: "pause",
	END: "end",
	REPLAY: "replay",
};

const CustomVideo = ({ mediafile }) => {
	const [playing, setPlaying] = useState(false);
	const videoRef = useRef(null);

	const video =
		mediafile instanceof File
			? URL.createObjectURL(mediafile)
			: mediafile.mediaUrl;

	useEffect(() => {}, [videoRef]);

	const handleControl = (control) => {
		switch (control) {
			case VIDEO_STATE.PLAY:
				setPlaying(true);
				videoRef.current.play();
				break;
			case VIDEO_STATE.REPLAY:
				setPlaying(false);
				videoRef.current.currentTime = 0;
				videoRef.current.play();
				break;
			case VIDEO_STATE.END:
				setPlaying(true);
				videoRef.current.currentTime = 0;
				videoRef.current.play();
				break;
			default:
			case VIDEO_STATE.PAUSE:
				setPlaying(false);
				videoRef.current.pause();
				break;
		}
	};

	return (
		<>
			{videoRef && (
				<div className="mediafile-preview">
					<div className="media-player icon">
						<video ref={videoRef}>
							<source src={video + "#t=0.001"} />
						</video>
						<div className="control">
							{playing ? (
								<span
									onClick={() =>
										handleControl(VIDEO_STATE.PAUSE)
									}
									className="control-pause icon"
								/>
							) : videoRef.current && videoRef.current.ended ? (
								<span
									onClick={() =>
										handleControl(VIDEO_STATE.END)
									}
									className="control-end icon"
								/>
							) : (
								<span
									onClick={() =>
										handleControl(VIDEO_STATE.PLAY)
									}
									className="control-play icon"
								/>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default CustomVideo;
