import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGetEnvironmentQuery } from "../../../app/services/environment";
import { Titlebar, TitleType } from "../../../layouts/Content";
import FullpageSpinner from "../../../modules/spinner/FullpageSpinner";
import EnvironmentDetail from "./EnvironmentDetail";
import FourOFour from "../../errors/404";

const EnvironmentEdit = () => {
	const { id } = useParams();
	const {
		data,
		isSuccess: loadSuccess,
		isLoading,
		refetch,
		isUninitialized,
	} = useGetEnvironmentQuery(id);

	useEffect(() => {}, [refetch]);

	return (
		<>
			{isLoading || isUninitialized ? (
				<FullpageSpinner />
			) : (
				<>
					{loadSuccess && data ? (
						<>
							<Titlebar
								title={data.name}
								parent={{
									name: "Environments",
									url: "/environments",
								}}
							/>
							{data && (
								<EnvironmentDetail
									data={data}
									refetch={refetch}
								/>
							)}
						</>
					) : (
						<>
							<FourOFour
								error={`Environment with ID "${id}" not found`}
								parent={{
									name: "environments",
									url: "/environments",
								}}
							/>
						</>
					)}
				</>
			)}
		</>
	);
};

const EnvironmentNew = () => {
	return (
		<>
			<Titlebar
				type={TitleType.Create}
				title="Environment"
				parent={{
					name: "Environments",
					url: "/environments",
				}}
			/>
			<EnvironmentDetail data={{}} />
		</>
	);
};

export { EnvironmentEdit, EnvironmentNew };
