import React, { useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ErrorHandler from "../../app/ErrorHandler";
import {ActionMessages, ErrorMessages, SuccessMessages} from "../../app/Messages";
import {
	useGetWorkflowsFromOrganizationQuery,
	useGetWorkplacesFromOrganizationQuery,
} from "../../app/services/organization";
import {
	useCopyWorkflowMutation,
	useDeleteWorkflowMutation,
	useUpdateWorkflowMutation,
} from "../../app/services/workflow";
import {
	getCurrentOrganization,
	setModalData,
	showModal,
} from "../../app/slices/appStateSlice";
import {
	NotificationType,
	startNotification,
} from "../../app/slices/notifactionStateSlice";
import DragItem from "../../components/dragndrop/DragItem";
import DropContainer from "../../components/dragndrop/DropContainer";
import List from "../../components/list/List";
import Steps from "../../components/stepper/Steps";
import { Titlebar, TitleType } from "../../layouts/Content";
import ListOverviewTemplate from "../../layouts/ListOverviewTemplate";
import { ModalTypes } from "../../modules/Modals/ConfirmDeleteModal";
import {generateQRCodePdf} from "../../services/pdf/QRGenerator";

const WorkflowOverview = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const currentOrg = useSelector(getCurrentOrganization);
	const [skip, setSkip] = useState(true);
	const {
		data: workflowData,
		isLoading,
		error,
		isSuccess: loadSuccess,
		refetch,
	} = useGetWorkflowsFromOrganizationQuery(currentOrg.id, { skip });
	// const { data: allWorkflows } = useGetWorkflowsFromOrganizationQuery(
	// 	currentOrg.id,
	// 	{ skip }
	// );
	const { data: workplacesData, refetch: refetchWorkplacesData } =
		useGetWorkplacesFromOrganizationQuery(currentOrg.id, { skip });
	const [items, setItems] = useState(workflowData);
	const [{ isSuccess: deleteSuccess }] = useDeleteWorkflowMutation();
	const [updateWorkflow] = useUpdateWorkflowMutation();
	const [copyWorkflow, { isSuccess: copySuccess }] = useCopyWorkflowMutation();

	useEffect(() => {
		currentOrg && setSkip(false);
	}, [currentOrg]);

	useEffect(() => {
		loadSuccess && setItems(workflowData);
		!isLoading && error && ErrorHandler(error);
	}, [error, isLoading, loadSuccess, workflowData]);

	useEffect(() => {}, [deleteSuccess, copySuccess, refetch, workflowData]);

	const handleAdd = () => {
		navigate("/workflows/new");
	};

	const handleDelete = (item) => {
		dispatch(showModal("delete"));
		dispatch(setModalData({ type: ModalTypes.Workflow, data: item }));
	};

	const handleCreateQrCode = async ({id}) => {
		await generateQRCodePdf([id]);
	}

	const handleCopy = async (item) => {
		dispatch(
			startNotification({
				message: ActionMessages.COPY_PROGRESS,
				type: NotificationType.SUCCESS,
				timeout: 30000
			})
		);
		try {
			await copyWorkflow(item.id);
			dispatch(
				startNotification({
					message: SuccessMessages.COPY,
					type: NotificationType.SUCCESS
				})
			);
			refetch();
		} catch (e) {
			dispatch(
				startNotification({
					message: ErrorMessages.GENERIC,
					type: NotificationType.ERROR,
				})
			);
		}
	};

	const handleEdit = (item) => {
		navigate("/workflows/" + item.id);
	};

	const handleRowClick = (e, item) => {
		if (e.target.type === "button" || e.target.type === "submit") return;
		handleEdit(item);
	};

	const onDragEnd = (result) => {
		// dont update if its dropped in the same soucr again
		// dont drop if its dropped in the debug droppable container (WFs without workplaces)
		if (
			result.destination.droppableId === result.source.droppableId ||
			result.destination.droppableId === "0"
		) {
			return;
		}

		const newItems = Array.from(items);
		const [removed] = newItems.splice(result.source.index, 1);
		newItems.splice(result.destination.index, 0, removed);

		var itemsInDroppedGroup = items.filter(
			(item) => item.workplace?.id === result.destination.droppableId
		);

		var position =
			itemsInDroppedGroup.length > 0
				? itemsInDroppedGroup[itemsInDroppedGroup.length - 1].index + 1
				: 0;

		var draggedItem = items.find((item) => item.id === result.draggableId);
		draggedItem = {
			...draggedItem,
			workplace: result.destination.droppableId,
			index: position,
		};
		updateWorkflow({ id: draggedItem.id, patch: draggedItem }).then(() => {
			dispatch(
				startNotification({
					message: SuccessMessages.REORDER,
					type: NotificationType.SUCCESS,
				})
			);
			refetch();
			refetchWorkplacesData();
		});
	};

	return (
		<>
			<Titlebar type={TitleType.Overview} title="Workflow" />
			{workflowData && items && workplacesData && (
				<ListOverviewTemplate
					addCallback={workplacesData.length > 0 && handleAdd}
					isLoading={isLoading}
				>
					{currentOrg && items && loadSuccess && (
						<List id="workflows">
							{workplacesData.length > 0 ? (
								<DragDropContext onDragEnd={onDragEnd}>
									{workplacesData.map((workplace, index) => {
										return (
											<DropContainer
												data={workplace}
												title={
													"Workplace: " +
													workplace.name
												}
												key={index}
												id={workplace.id.toString()}
											>
												{items
													.filter(
														(workflow) =>
															workflow.workplace &&
															workflow.workplace
																.id ===
																workplace.id
													)
													.map((item, index) => {
														return (
															<DragItem
																item={item}
																index={index}
																key={index}
															>
																<List.Item
																	onClick={(
																		e
																	) =>
																		handleRowClick(
																			e,
																			item
																		)
																	}
																	classes={
																		"clickable"
																	}
																	key={index}
																	buttons={[
																		<List.Item.Button
																			type="Edit"
																			key="edit-button"
																			callback={() =>
																				handleEdit(
																					item
																				)
																			}
																		/>,
																		<List.Item.Button
																			type="Copy"
																			key="copy-button"
																			callback={() =>
																				handleCopy(
																					item
																				)
																			}
																		/>,
																		<List.Item.Button
																			type="QR"
																			key="qr-button"
																			callback={() =>
																				handleCreateQrCode(
																					item
																				)
																			}
																		/>,
																		<List.Item.Button
																			type="Delete"
																			key="delete-button"
																			callback={() =>
																				handleDelete(
																					item
																				)
																			}
																		/>,
																	]}
																>
																	<List.Item.Content key="index">
																		<span className="hide-onPhone">
																			{index +
																				1}
																		</span>
																	</List.Item.Content>
																	<List.Item.Content
																		title
																		key="name"
																	>
																		{
																			item.name
																		}
																	</List.Item.Content>
																	<List.Item.Content key="confidentiality">
																		<div className="hide-onSmallPhone">
																			<span className="hide-onPhone ">
																				Confidentiality
																				/
																			</span>
																			<span>
																				ACP
																			</span>
																			<Steps
																				current={
																					index
																						? index
																						: 1
																				}
																				offset={
																					0
																				}
																				steps={[
																					{
																						title: " ",
																					},
																					{
																						title: " ",
																					},
																					{
																						title: " ",
																					},
																					{
																						title: " ",
																					},
																				]}
																			/>
																		</div>
																	</List.Item.Content>
																</List.Item>
															</DragItem>
														);
													})}
											</DropContainer>
										);
									})}
									{/* To Debug, Workflows without Workplaces are rendered, too */}
									<DropContainer
										data={{ id: "0" }}
										title={"undefined workplace"}
									>
										{items
											.filter(
												(workflow) =>
													workflow.workplace ===
														"0" ||
													workflow.workplace === null
											)
											.map((item, index) => {
												return (
													<DragItem
														item={item}
														index={index}
													>
														<List.Item
															onClick={(e) =>
																handleRowClick(
																	e,
																	item
																)
															}
															classes={
																"clickable"
															}
															key={index}
															buttons={[
																<List.Item.Button
																	type="Edit"
																	key="edit-button"
																	callback={() =>
																		handleEdit(
																			item
																		)
																	}
																/>,
																<List.Item.Button
																	type="Delete"
																	key="delete-button"
																	callback={() =>
																		handleDelete(
																			item
																		)
																	}
																/>,
															]}
														>
															<List.Item.Content key="index">
																{index + 1}
															</List.Item.Content>
															<List.Item.Content
																title
																key="name"
															>
																{item.name}
															</List.Item.Content>
															<List.Item.Content
																className="hide-onSmallPhone"
																key="confidentiality"
															>
																<span>
																	Confidentiality
																	(ACP):
																</span>
																<Steps
																	current={
																		index
																			? index
																			: 1
																	}
																	offset={0}
																	steps={[
																		{
																			title: " ",
																		},
																		{
																			title: " ",
																		},
																		{
																			title: " ",
																		},
																		{
																			title: " ",
																		},
																	]}
																/>
															</List.Item.Content>
														</List.Item>
													</DragItem>
												);
											})}
									</DropContainer>
								</DragDropContext>
							) : (
								<div className="warning-dialogue">
									<span>
										{" "}
										You have to define at least one{" "}
									</span>
									<Link to={"/workplaces/new"}>
										workplace
									</Link>
									<span>
										{" "}
										before you can create a workflow.
									</span>
								</div>
							)}
						</List>
					)}
				</ListOverviewTemplate>
			)}
		</>
	);
};

export default WorkflowOverview;
