import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getEndpoint } from "./basics";
import { organizationApi } from "./organization";
const base = getEndpoint();

export const iconApi = createApi({
	reducerPath: "iconApi",
	baseQuery: fetchBaseQuery({
		baseUrl: base,
		prepareHeaders: (headers) => {
			headers.set("Content-Type", "application/json");
			headers.set("Accept", "application/json");
			return headers;
		},
		credentials: "include",
	}),
	tagTypes: [],
	endpoints: (builder) => ({
		getIcon: builder.query({
			query: (id) => ({
				url: `icon/${id}`,
				method: "GET",
			}),
		}),
		createIcon: builder.mutation({
			query: (data) => ({
				url: `icon`,
				method: "POST",
				body: data,
			}),
		}),
		updateIcon: builder.mutation({
			query: ({ id, patch }) => ({
				url: `icon/${id}`,
				method: "PATCH",
				body: patch,
			}),
			onSettled: (_, { queryFulfilled, arg: { patch } }) => {
				if (queryFulfilled?.type === "updateIcon") {
					// Invalidate the tag for the getIconsFromOrganization query with the id of the organization
					organizationApi.invalidateTags(
						"Icons",
						patch.organizationId
					);
				}
			},
		}),
		deleteIcon: builder.mutation({
			query: (id) => ({
				url: `icon/${id}`,
				method: "DELETE",
			}),
		}),
	}),
});

// Export hooks for usage in functional components
export const {
	useGetIconQuery,
	useCreateIconMutation,
	useDeleteIconMutation,
	useUpdateIconMutation,
} = iconApi;
