import React from "react";
import { useParams } from "react-router-dom";
import { useGetGroupQuery } from "../../../app/services/group";
import { Titlebar, TitleType } from "../../../layouts/Content";
import FullpageSpinner from "../../../modules/spinner/FullpageSpinner";
import GroupDetail from "./GroupDetail";
import FourOFour from "../../errors/404";

const GroupEdit = () => {
	const { id, tab } = useParams();
	const {
		data,
		isSuccess: loadSuccess,
		isLoading,
		isUninitialized,
	} = useGetGroupQuery(id);

	return (
		<>
			{isLoading || isUninitialized ? (
				<FullpageSpinner />
			) : (
				<>
					{loadSuccess && data ? (
						<>
							<Titlebar
								title={data.name}
								parent={{
									name: "Groups",
									url: "/groups",
								}}
							/>
							<GroupDetail data={data} tab={tab} />
						</>
					) : (
						<FourOFour
							error={`Group with ID "${id}" not found`}
							parent={{
								name: "groups",
								url: "/groups",
							}}
						/>
					)}
				</>
			)}
		</>
	);
};
const GroupNew = () => {
	return (
		<>
			<Titlebar
				type={TitleType.Create}
				title="group"
				parent={{
					name: "Groups",
					url: "/groups",
				}}
			/>
			<GroupDetail data={{}} />
		</>
	);
};

export { GroupNew, GroupEdit };
