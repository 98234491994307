import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getEndpoint } from "./basics";
const base = getEndpoint();

export const sessionApi = createApi({
	reducerPath: "sessionApi",
	baseQuery: fetchBaseQuery({
		baseUrl: base,
		prepareHeaders: (headers) => {
			headers.set("Content-Type", "application/json");
			headers.set("Accept", "application/json");
			return headers;
		},
		credentials: "include",
	}),
	tagTypes: [],
	endpoints: (builder) => ({
		getSessionInfo: builder.query({
			query: () => ({
				// When performing a mutation, you typically use a method of
				// PATCH/PUT/POST/DELETE for REST endpoints
				// fetchBaseQuery automatically adds `content-type: application/json` to
				// the Headers and calls `JSON.stringify(patch)`
				url: `session/info`,
				method: "GET",
			}),
		}),
	}),
});

// Export hooks for usage in functional components
export const { useGetSessionInfoQuery } = sessionApi;
