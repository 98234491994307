import {jsPDF} from "jspdf";
import QRCodeStyling from "qr-code-styling";
import logo_mt_100 from "../../static/logo_mt_1500.png";
import logo_mtxr_horizontal_1500 from "../../static/logo_mtxr_horizontal_1500.png";
import Siemens_Sans_Bold_font from "../../static/SiemensSans_Prof_Black.ttf";
import Siemens_Sans_Roman_font from "../../static/SiemensSans_Prof_Roman.ttf";

const QR_CODE_SIZE = 54;
const PAGE_WIDTH = 54;
const PAGE_HEIGHT = PAGE_WIDTH * 1.35;

const IMAGE_RATIO = 2000/955;


/**
 * Generate a new PDF Document including QRCodes.
 * @param {Array<string>} url A list or URLs to generate QRCodes for
 */
export const generateQRCodePdf = async (identifier) => {
	// Default export is a4 paper, portrait, using millimeters for units
	const doc = new jsPDF({
		unit: "mm",
		format: [
			PAGE_WIDTH,
			PAGE_HEIGHT
		]
	})

	let qrCode = new QRCodeStyling({
		"width": 600,
		"height": 600,
		"data": `${document.location.origin}/view/workflows/${identifier[0]}`,
		"margin": 0,
		"qrOptions": {"typeNumber": "0", "mode": "Byte", "errorCorrectionLevel": "Q"},
		"imageOptions": {"hideBackgroundDots": true, "imageSize": 0.4, "margin": 15},
		"dotsOptions": {"type": "rounded", "color": "#333353"},
		"backgroundOptions": {"color": "#ffffff"},
		"image": logo_mt_100,
		"dotsOptionsHelper": {"colorType": {"single": true, "gradient": false}, "gradient": {"linear": true, "radial": false, "color1": "#6a1a4c", "color2": "#6a1a4c", "rotation": "0"}},
		"cornersSquareOptions": {"type": "square", "color": "#000000", "gradient": null},
		"cornersSquareOptionsHelper": {"colorType": {"single": true, "gradient": false}, "gradient": {"linear": true, "radial": false, "color1": "#000000", "color2": "#000000", "rotation": "0"}},
		"cornersDotOptions": {"type": "", "color": "#333353"},
		"cornersDotOptionsHelper": {"colorType": {"single": true, "gradient": false}, "gradient": {"linear": true, "radial": false, "color1": "#000000", "color2": "#000000", "rotation": "0"}},
		"backgroundOptionsHelper": {"colorType": {"single": true, "gradient": false}, "gradient": {"linear": true, "radial": false, "color1": "#ffffff", "color2": "#ffffff", "rotation": "0"}}
	});
	let qrBlob = await qrCode.getRawData("png");

	doc.addImage({
		imageData: window.URL.createObjectURL(qrBlob),
		x: 5,
		y: 5,
		width: QR_CODE_SIZE - 10,
		height: QR_CODE_SIZE - 10
	});
	doc.addFont(
		Siemens_Sans_Bold_font,
		"Siemens Sans",
		"bold"
	);
	doc.addFont(
		Siemens_Sans_Roman_font,
		"Siemens Sans",
		"roman"
	);
	doc.setFillColor("#000028");
	doc.rect(0, QR_CODE_SIZE, QR_CODE_SIZE, PAGE_HEIGHT - QR_CODE_SIZE, "F");
	const IMAGE_HEIGHT = PAGE_HEIGHT - 8 - QR_CODE_SIZE;
	const IMAGE_WIDTH = IMAGE_HEIGHT * IMAGE_RATIO;
	doc.addImage({
		imageData: logo_mtxr_horizontal_1500,
		x: 6,
		y: QR_CODE_SIZE + 4,
		width: IMAGE_WIDTH,
		height: IMAGE_HEIGHT
	});
	doc.setTextColor("#ffffff");
	doc.setFont("Siemens Sans", "bold");
	doc.setFontSize(12);
	const RIGHT_COLUM_CENTERLINE = (PAGE_WIDTH - 6 - IMAGE_WIDTH) / 2 + (6 + IMAGE_WIDTH);
	doc.text("FLOW", RIGHT_COLUM_CENTERLINE, QR_CODE_SIZE + 8.5, {
		align: "center"
	});
	doc.setFont("Siemens Sans", "roman");
	doc.text(`#${identifier[0]}`, RIGHT_COLUM_CENTERLINE, QR_CODE_SIZE + 13.5, {
		align: "center",
		maxWidth: PAGE_WIDTH - IMAGE_WIDTH - 12
	});
	let outputBlob = doc.output("blob");
	let outputUrl = window.URL.createObjectURL(outputBlob);
	let tab = window.open();
	tab.location.href = outputUrl;
}