import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/esm/Tab";
import Tabs from "react-bootstrap/esm/Tabs";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ErrorMessages, SuccessMessages } from "../../../app/Messages";
import {
	useCreateGroupMutation,
	useGetGroupQuery,
	useUpdateGroupMutation,
} from "../../../app/services/group";
import {
	NotificationType,
	startNotification,
} from "../../../app/slices/notifactionStateSlice";
import Details from "../../../layouts/Details";
import { ModalTypes } from "../../../modules/Modals/ConfirmDeleteModal";
import GroupPermissions from "./tabs/GroupPermissions";
import GroupUsers from "./tabs/GroupUsers";

const GroupDetail = ({ data, tab }) => {
	const rootPath = "/groups";
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [createGroup] = useCreateGroupMutation();
	const [updateGroup] = useUpdateGroupMutation();
	const { refetch } = useGetGroupQuery(data.id);

	const [adminGroups, setAdminGroups] = useState([]);
	const [memberGroups, setMemberGroups] = useState([]);
	const [groupUsers, setGroupUsers] = useState([]);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const editMode = Object.keys(data).length !== 0;

	useEffect(() => {
		data && data.adminGroups && setAdminGroups(data.adminGroups);
		data && data.memberGroups && setMemberGroups(data.memberGroups);
		data && data.users && setGroupUsers(data.users);
	}, [data]);

	const handleSave = async (formData) => {
		if (errors.name) {
			dispatch(
				startNotification({
					message: errors,
					type: NotificationType.ERROR,
				})
			);
			return null;
		} else {
			const memberGroupIds = memberGroups.map((item) => (item = item.id));
			const adminGroupIds = adminGroups.map((item) => (item = item.id));
			const groupUserIds = groupUsers.map((item) => (item = item.id));

			// var postData = Object.fromEntries(formData.entries());
			var postData = {
				...formData,
				memberGroups: memberGroupIds,
				adminGroups: adminGroupIds,
				users: groupUserIds,
			};

			try {
				editMode
					? await updateGroup({ id: data.id, patch: postData })
					: await createGroup(postData);
				// on updating an existing group:
				if (editMode) {
					dispatch(
						startNotification({
							message: SuccessMessages.SAVE,
							type: NotificationType.SUCCESS,
						})
					);
					refetch();
				} else {
					// on creating a new group:
					startNotification({
						message: SuccessMessages.CREATE,
						type: NotificationType.SUCCESS,
					});
					navigate(-1);
				}
			} catch (error) {
				dispatch(
					startNotification({
						message: error.message
							? error.message
							: "An error occured.",
						type: NotificationType.ERROR,
					})
				);
			}
		}
	};

	const General = () => {
		return (
			<div className="form-group">
				<div className={`label-container`}>
					<label htmlFor={"name"}>Group Name</label>
				</div>
				<div className={`input-container ${errors["name"] && "error"}`}>
					<input
						name={"name"}
						type={"text"}
						placeholder="Group Name"
						className={`form-control mr-2 centered ${
							errors["name"] && "error"
						}`}
						defaultValue={data?.name ?? ""}
						{...register("name", {
							required: ErrorMessages.REQUIRED,
						})}
					/>
					{errors && errors["name"] && (
						<span className="error">{errors["name"]?.message}</span>
					)}
				</div>
			</div>
		);
	};

	return (
		<>
			<form>
				<Details
					saveFunction={handleSubmit(handleSave)}
					modalData={{
						type: ModalTypes.Group,
						data: data,
						navigate: rootPath,
					}}
				>
					<Tabs
						transition={false}
						defaultActiveKey={tab ? tab : "settings"}
						id="justify-tab-example"
						className="mb-5 mt-4"
						justify
					>
						<Tab eventKey="settings" title="General Settings">
							<div className="row">
								<div className="col-md-12">
									<General />
								</div>
							</div>
						</Tab>
						<Tab eventKey="users" title="Manage Users">
							<div className="row">
								{groupUsers && (
									<GroupUsers
										data={data}
										groupUsers={groupUsers}
										setGroupUsers={setGroupUsers}
									/>
								)}
							</div>
						</Tab>
						<Tab eventKey="permissions" title="Permissions">
							<div className="row">
								{adminGroups && memberGroups && (
									<GroupPermissions
										adminGroups={adminGroups}
										setAdminGroups={setAdminGroups}
										memberGroups={memberGroups}
										setMemberGroups={setMemberGroups}
									/>
								)}
							</div>
						</Tab>
					</Tabs>
				</Details>
			</form>
		</>
	);
};

export default GroupDetail;
