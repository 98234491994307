import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import ButtonGroup from "react-bootstrap/ButtonGroup";

const Select = ({
	data,
	selected,
	selectCallback,
	type = ButtonGroup,
	className,
	id,
	...props
}) => {
	return (
		<>
			<div
				id={id}
				className={className ? "form-group " + className : "form-group"}
			>
				{selected && (
					<DropdownButton
						as={type}
						title={selected.name}
						id="dropdown-basic-button"
						onSelect={(selectedId) => selectCallback(selectedId)}
						{...props}
					>
						{data.map((item, index) => (
							<Dropdown.Item
								className="ellipsis"
								key={"dropdown-" + index}
								eventKey={item.id}
							>
								{item.name}
							</Dropdown.Item>
						))}
					</DropdownButton>
				)}
			</div>
		</>
	);
};

export default Select;
