import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getEndpoint } from "./basics";
const base = getEndpoint();

export const groupApi = createApi({
	reducerPath: "groupApi",
	baseQuery: fetchBaseQuery({
		baseUrl: base,
		prepareHeaders: (headers) => {
			headers.set("Content-Type", "application/json");
			headers.set("Accept", "application/json");
			return headers;
		},
		credentials: "include",
	}),
	tagTypes: ["Groups"],
	endpoints: (builder) => ({
		getGroups: builder.query({
			query: () => ({
				// When performing a mutation, you typically use a method of
				// PATCH/PUT/POST/DELETE for REST endpoints
				// fetchBaseQuery automatically adds `content-type: application/json` to
				// the Headers and calls `JSON.stringify(patch)`
				url: `group`,
				method: "GET",
				providesTags: ["Groups"],
			}),
		}),
		getGroup: builder.query({
			query: (id) => ({
				url: `group/${id}`,
				method: "GET",
			}),
		}),
		createGroup: builder.mutation({
			query: (data) => ({
				url: `group`,
				method: "POST",
				body: data,
			}),
			invalidateTags: ["Groups"],
		}),
		updateGroup: builder.mutation({
			query: ({ id, patch }) => ({
				url: `group/${id}`,
				method: "PATCH",
				body: patch,
			}),
			invalidateTags: ["Groups"],
		}),
		addUserToGroup: builder.mutation({
			query: ({ groupID, userID }) => ({
				url: `group/${groupID}/user/${userID}`,
				method: "POST",
			}),
			invalidateTags: ["Groups"],
		}),
		removeUserFromGroup: builder.mutation({
			query: ({ groupID, userID }) => ({
				url: `group/${groupID}/user/${userID}`,
				method: "DELETE",
			}),
			invalidateTags: ["Groups"],
		}),
		deleteGroup: builder.mutation({
			query: (id) => ({
				url: `group/${id}`,
				method: "DELETE",
			}),
			invalidateTags: ["Groups"],
		}),
	}),
});

// Export hooks for usage in functional components
export const {
	useGetGroupsQuery,
	useGetGroupQuery,
	useCreateGroupMutation,
	useAddUserToGroupMutation,
	useRemoveUserFromGroupMutation,
	useDeleteGroupMutation,
	useUpdateGroupMutation,
} = groupApi;
