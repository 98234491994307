import { getEndpoint } from "./services/basics";

export const createEntityWithImage = async ({
	type,
	createFn,
	updateFn,
	onSuccess,
	onError,
	postData,
	object,
	patchAttribute,
}) => {
	// console.log("///////////////// createEntityWithImage");
	// console.log("Type:", type);
	// console.log("Create Function:", createFn);
	// console.log("Update Function:", updateFn);
	// console.log("On Success Function:", onSuccess);
	// console.log("On Error Function:", onError);
	// console.log("Post Data:", postData);
	// console.log("Object:", object);
	// console.log("Patch Attribute:", patchAttribute);

	const base = getEndpoint();
	var entityId = postData.id;
	try {
		if (entityId === undefined) {
			const {
				data: { id },
			} = await createFn(postData); // create entity and get its ID
			entityId = id;
		}
		const response = await fetch(
			`${base}/${type}/${entityId}/getUploadUrl`,
			{
				credentials: "include",
			}
		); // get presigned S3 URL for uploading file

		const { url, uploadObjectId } = await response.json(); // extract URL and upload object ID

		try {
			await fetch(url, {
				method: "PUT",
				headers: {
					"x-amz-server-side-encryption": "aws:kms",
				},
				body: object,
			}); // upload file to S3

			const patchData = { ...postData, [patchAttribute]: uploadObjectId };

			try {
				await updateFn({
					id: entityId,
					patch: patchData,
				}); // update entity with the upload object ID
			} catch (error) {
				console.error(error);
				onError(error);
				return;
			}

			onSuccess && onSuccess();
		} catch (error) {
			console.error(error);
			onError(error);
			return;
		}
	} catch (error) {
		console.error(error);
		onError(error);
	}
};
