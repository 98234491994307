import React from "react";
import FullpageSpinner from "../modules/spinner/FullpageSpinner";

const ListOverviewTemplate = ({ addCallback, isLoading = false, children }) => {
	return (
		<>
			{isLoading ? (
				<FullpageSpinner />
			) : (
				<>
					{children}
					{addCallback && (
						<div className="row">
							<div className="col-12">
								<button
									type="button"
									className="add-button btn btn-cta center btn-lg float-right icon  mr-3 "
									onClick={addCallback}
								>
									
								</button>
							</div>
						</div>
					)}
				</>
			)}
		</>
	);
};

export default ListOverviewTemplate;
