import md5 from "md5";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import ErrorHandler from "../../app/ErrorHandler";
import { useGetOrganizationsQuery } from "../../app/services/organization";
import { useGetSessionInfoQuery } from "../../app/services/session";
import { LanguageIndicator } from "../../components/select/LanguageSelector";
import Select from "../../components/select/Select";

const IconSidebar = ({ storedOrg, handleOrgSelection }) => {
	const {
		data,
		isLoading,
		error,
		isSuccess: loadSuccess,
	} = useGetSessionInfoQuery();
	const { data: organizations } = useGetOrganizationsQuery();
	const [showSidebar, setShowSidebar] = useState(false);
	const [avatarURL, setAvatarUrl] = useState("");

	const handlers = useSwipeable({
		onSwiped: (eventData) => handleSwipe(eventData),
		delta: 10,
	});

	useEffect(() => {
		if (!isLoading) {
			if (data && data.message === "Not signed in") {
				var myError = { status: "403" };
				ErrorHandler(myError);
			}
			loadSuccess &&
				setAvatarUrl(
					"https://www.gravatar.com/avatar/" +
						md5(data?.email) +
						".jpg"
				);
		}
	}, [data, error, isLoading, loadSuccess]);

	const handleSwipe = (eventData) => {
		toggleSidebar();
	};

	const toggleSidebar = () => {
		setShowSidebar((showSidebar) => !showSidebar);
	};

	const handleSidebarClick = (e) => {
		if (
			e.target.classList.contains("nav-link") ||
			e.target.parentElement.classList.contains("nav-link")
		) {
			setShowSidebar(false);
		}
	};

	return (
		<>
			<nav
				className={
					showSidebar
						? "icon-navbar open elevation-4"
						: "icon-navbar elevation-4"
				}
				{...handlers}
				onClick={(e) => handleSidebarClick(e)}
			>
				<div className="sidebar-container">
					<div
						className="sidebar-opener"
						onClick={toggleSidebar}
					></div>
					<div className="sidebar-header">
						<span className="brand-text show-onSwipe fullWidth font-weight-light mb-4">
							<h2>Task2Fit</h2>
						</span>
						<div className="show-onSwipe user-panel pb-4 d-flex">
							<div className="image">
								<img
									src={avatarURL}
									className="img-circle elevation-2"
									alt="User"
								/>
							</div>
							<div className="info">
								<NavLink to="/">
									{data.name} {data.surname}
								</NavLink>
							</div>
						</div>
					</div>
					<ul
						className="nav nav-pills nav-sidebar flex-column"
						data-widget="treeview"
						role="menu"
					>
						<li className="nav-item menu-open">
							<ul className="nav nav-treeview">
								<li className="nav-item">
									<NavLink to="/" className="nav-link">
										<i className="icon"></i>
										<p className="show-onSwipe">Home</p>
									</NavLink>
								</li>
								<li className="nav-item">
									<NavLink to="/users" className="nav-link">
										<i className="icon"></i>
										<p className="show-onSwipe">Users</p>
									</NavLink>
								</li>
								<li className="nav-item">
									<NavLink to="/groups" className="nav-link">
										<i className="icon"></i>
										<p className="show-onSwipe">Groups</p>
									</NavLink>
								</li>
								<li className="nav-item">
									<NavLink
										to="/organizations"
										className="nav-link"
									>
										<i className="icon"></i>
										<p className="show-onSwipe">
											Organizations
										</p>
									</NavLink>
								</li>
								<li>
									{storedOrg && storedOrg !== undefined && (
										<>
											<Select
												id="organization-selector"
												data={organizations}
												selected={storedOrg}
												selectCallback={(id) =>
													handleOrgSelection(id)
												}
												className={"show-onSwipe"}
											/>
										</>
									)}
								</li>
								<li className="user-panel mb-4"></li>
								<li className="nav-item">
									<NavLink
										to="/workplaces"
										className="nav-link"
									>
										<i className="icon"></i>

										<p className="show-onSwipe">
											Workplaces
										</p>
									</NavLink>
								</li>
								<li className="nav-item">
									<NavLink
										to="/workflows"
										className="nav-link"
									>
										<i className="icon"></i>
										<p className="show-onSwipe">
											Workflows
										</p>
									</NavLink>
								</li>
								<li className="nav-item">
									<NavLink
										to="/environments"
										className="nav-link"
									>
										<i className="icon"></i>
										<p className="show-onSwipe">
											Environments
										</p>
									</NavLink>
								</li>
								<li className="user-panel  mt-4 mb-4"></li>
								<li className="nav-item">
									<NavLink
										to="/customize"
										className="nav-link"
									>
										<i className="icon"></i>

										<p className="show-onSwipe">
											Customize App
										</p>
									</NavLink>
								</li>
								<li className="nav-item">
									<NavLink
										to="/settings"
										className="nav-link"
									>
										<i className="icon"></i>
										<p className="show-onSwipe">Settings</p>
									</NavLink>
								</li>
							</ul>
						</li>
					</ul>
					{showSidebar ? (
						<div
							className="show-onSwipe sidebar-footer dropdown open-upward languages"
							style={{
								textAlign: "center",
								marginBottom: "1.5rem",
							}}
						>
							{/* <LanguageSelector
								active={"DE"}
								fullWidth={true}
								className={"fullWidth"}
							/> */}
						</div>
					) : (
						<>
							<div
								className="sidebar-footer "
								style={{
									textAlign: "center",
									marginBottom: "1.5rem",
								}}
							>
								<LanguageIndicator />
							</div>
						</>
					)}
				</div>
			</nav>
		</>
	);
};

export default IconSidebar;
