import React, { useEffect } from "react";
import ListOverviewTemplate from "../../layouts/ListOverviewTemplate";
import List from "../../components/list/List";
import { useNavigate } from "react-router-dom";
import {
	useDeleteOrganizationMutation,
	useGetOrganizationsQuery,
} from "../../app/services/organization";
import ErrorHandler from "../../app/ErrorHandler";
import { useDispatch } from "react-redux";
import { setModalData, showModal } from "../../app/slices/appStateSlice";
import { ModalTypes } from "../../modules/Modals/ConfirmDeleteModal";
import { Titlebar, TitleType } from "../../layouts/Content";

const Organizations = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {
		data,
		isLoading,
		error,
		isSuccess: loadSuccess,
		refetch,
	} = useGetOrganizationsQuery();
	const [{ isSuccess: deleteSuccess }] = useDeleteOrganizationMutation();

	const handleDelete = (item) => {
		// deleteOrganization(item.id).then(() => {
		// 	refetch();
		// });
		dispatch(showModal("delete"));
		dispatch(setModalData({ type: ModalTypes.Organization, data: item }));
	};

	const handleEdit = (item) => {
		navigate("/organizations/" + item.id);
	};

	const handleAdd = () => {
		navigate("/organizations/new");
	};

	const handleRowClick = (e, item) => {
		if (e.target.type === "button" || e.target.type === "submit") return;
		handleEdit(item);
	};
	useEffect(() => {
		!isLoading && error && ErrorHandler(error);
	}, [error, isLoading]);

	useEffect(() => {
		refetch();
	}, [deleteSuccess, refetch, data]);

	return (
		<>
			<Titlebar type={TitleType.Overview} title="Organizations" />
			<ListOverviewTemplate addCallback={handleAdd} isLoading={isLoading}>
				{loadSuccess && (
					<List id="organizations">
						<List.Header>
							<List.Header.Cell>#</List.Header.Cell>
							<List.Header.Cell>Name</List.Header.Cell>
							<List.Header.Cell>Domain</List.Header.Cell>
							<List.Header.Cell>Licenses</List.Header.Cell>
							<List.Header.Cell>Licenses</List.Header.Cell>
							<List.Header.Cell>Licenses</List.Header.Cell>
						</List.Header>
						{data.length > 0 &&
							data.map((item, index) => (
								<List.Item
									key={index}
									classes="clickable"
									onClick={(e) => handleRowClick(e, item)}
									buttons={
										handleEdit && handleDelete
											? [
													<List.Item.Button
														key="edit-button"
														type="Edit"
														callback={() =>
															handleEdit(item)
														}
													/>,
													<List.Item.Button
														key="delete-button"
														type="Delete"
														callback={() =>
															handleDelete(item)
														}
													/>,
											  ]
											: null
									}
								>
									<List.Item.Content key="index">
										{index + 1}
									</List.Item.Content>
									<List.Item.Content key="name" title>
										{item.name}
									</List.Item.Content>
									<List.Item.Content key="domain">
										{item.domain}
									</List.Item.Content>
									<List.Item.Content key="licenses-1">
										1/10
									</List.Item.Content>
									<List.Item.Content key="licenses-2">
										5/5
									</List.Item.Content>
									<List.Item.Content key="licenses-3">
										10/15
									</List.Item.Content>
								</List.Item>
							))}
					</List>
				)}
			</ListOverviewTemplate>
		</>
	);
};

export default Organizations;
