import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import ErrorHandler from "../app/ErrorHandler";
import { useGetOrganizationsQuery } from "../app/services/organization";
import {
	getCurrentOrganization,
	setCurrentOrganization,
} from "../app/slices/appStateSlice";
import Footer from "../modules/footer/Footer";
import Navbar from "../modules/navbar/Navbar";
import Notification from "../modules/notification/Notification";
import Sidebar from "../modules/sidebar/Sidebar";
import FullpageSpinner from "../modules/spinner/FullpageSpinner";

const Layout = ({ isPrivate = true, ...props }) => {
	const dispatch = useDispatch();
	const { data, isSuccess, error } = useGetOrganizationsQuery();
	const currentOrg = useSelector(getCurrentOrganization);
	const [navBarExtensions, setNavbarExtensions] = useState("bla");

	if (error) {
		ErrorHandler(error);
	}

	useEffect(() => {
		if (isSuccess && currentOrg === null) {
			data && dispatch(setCurrentOrganization(data[0]));
		}
	}, [currentOrg, data, dispatch, isSuccess]);

	return isPrivate ? (
		<>
			{currentOrg ? (
				<div className={props.className}>
					{!props.withoutSidebar && <Sidebar />}
					<Outlet context={[navBarExtensions, setNavbarExtensions]}/>
					<Notification />
					<Footer />
				</div>
			) : (
				<FullpageSpinner />
			)}
		</>
	) : (
		<>
			<section className="content">
				<div className="content-wrapper">
					<Outlet />
				</div>
			</section>
			<Footer />
		</>
	);
};

const ViewonlyLayout = () => {
	const { id } = useParams();

	const workflowNavbarExtension = (id) => {
		if(id) {
			return (
				<div>details</div>
			)
		}
		return (
			<div>no workflow</div>
		)
	}

	const [navBarExtensions, setNavbarExtensions] = useState("bla");

	return (
		<>
			{/*{currentOrg ? (*/}
				<div className={"content-wrapper viewonly fullwidth pt-4"}>
					<Navbar id={id} extensions={[navBarExtensions]}/>
					<Outlet context={[navBarExtensions, setNavbarExtensions]}/>
					<Notification />
					<Footer />
				</div>
			{/*) : (*/}
			{/*	<FullpageSpinner />*/}
			{/*)}*/}
		</>
	);
};
export { Layout, ViewonlyLayout };
