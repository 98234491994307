import React, {useEffect, useState} from "react";
import { NavLink } from "react-router-dom";
import {useGetSessionInfoQuery} from "../../app/services/session";
import ErrorHandler from "../../app/ErrorHandler";

const SidebarMenu = ({ OrgSelection }) => {
	const {
		data,
		isLoading,
		error,
		isSuccess: loadSuccess,
	} = useGetSessionInfoQuery();

	const [isSiemensUser, setIsSiemensUser] = useState(false);

	useEffect(() => {
		if (!isLoading) {
			if (data && data.message === "Not signed in") {
				var myError = { status: "403" };
				ErrorHandler(myError);
			}
			if(loadSuccess && data.email) {
				//TODO: replace this with a serverside feature-flag logic
				setIsSiemensUser(/.*@siemens\.com$/.test(data.email));
			}

		}
	}, [data, error, isLoading, loadSuccess]);

	return (
		<ul
			className="nav nav-pills nav-sidebar flex-column"
			data-widget="treeview"
			role="menu"
		>
			<li className="nav-item menu-open">
				<ul className="nav nav-treeview">
					{
						isSiemensUser && (
							<>
								<li className="nav-item">
									<NavLink to="/users" className="nav-link">
										<i className="far fa-circle nav-icon"></i>
										<p>User</p>
									</NavLink>
								</li>
								<li className="nav-item">
									<NavLink to="/groups" className="nav-link">
										<i className="far fa-circle nav-icon"></i>
										<p>Groups</p>
									</NavLink>
								</li>
								<li className="nav-item">
									<NavLink to="/organizations" className="nav-link">
										<i className="far fa-circle nav-icon"></i>
										<p>Organizations</p>
									</NavLink>
								</li>
							</>
						)
					}
					<li>{OrgSelection && OrgSelection}</li>
					<li className="user-panel mb-4"></li>
					<li className="nav-item">
						<NavLink to="/workplaces" className="nav-link">
							<i className="far fa-circle nav-icon"></i>
							<p>Workplace</p>
						</NavLink>
					</li>
					<li className="nav-item">
						<NavLink to="/workflows" className="nav-link">
							<i className="far fa-circle nav-icon"></i>
							<p>Workflows</p>
						</NavLink>
					</li>
					<li className="nav-item">
						<NavLink to="/environments" className="nav-link">
							<i className="far fa-circle nav-icon"></i>
							<p>Environments</p>
						</NavLink>
					</li>

					{
						isSiemensUser && (
							<>
								<li className="user-panel  mt-4 mb-4"></li>
								<li className="nav-item">
									<NavLink to="/workflows" className="nav-link">
										<i className="far fa-circle nav-icon"></i>
										<p>OEM Products</p>
									</NavLink>
								</li>
							</>
					)}

					{
						isSiemensUser && (
							<>
								<li className="user-panel  mt-4 mb-4"></li>
								<li className="nav-item">
									<NavLink to="/customize" className="nav-link">
										<i className="far fa-circle nav-icon"></i>
										<p>Customize App</p>
									</NavLink>
								</li>
								<li className="nav-item">
									<NavLink to="/settings/organization" className="nav-link">
										<i className="far fa-circle nav-icon"></i>
										<p>Organization Settings</p>
									</NavLink>
								</li>
								<li className="nav-item">
									<NavLink to="/settings/system" className="nav-link">
										<i className="far fa-circle nav-icon"></i>
										<p>System Settings</p>
									</NavLink>
								</li>
							</>
						)
					}
				</ul>
				{" "}
			</li>
		</ul>
	);
};

export default SidebarMenu;
