import React from "react";

const Steps = ({ steps, current, offset = 1, ...props }) => {
	const Step = ({ className, title, data }) => {
		return (
			<li className={className} data-step={data}>
				<div className="ellipsis">{title}</div>
			</li>
		);
	};

	const VisibleSteps = ({ index, step }) => {
		var sidesLeft = (props.stepsShowing - 1) * 0.5;
		var sidesRight = (props.stepsShowing - 1) * 0.5;

		//  START of stepper (show more steps to right)
		if (current < props.offsetThreshold) {
			//  render 5 to right instead of 2
			sidesRight = props.stepsShowing - current;
		}
		//  END of stepper (show more steps to left)
		if (current > steps.length - props.offsetThreshold) {
			sidesLeft = props.stepsShowing + current - steps.length - 1;
		}
		// dont render steps with index bigger than X steps to right from current
		if (index >= current + sidesRight) {
			return null;
		}
		// dont render steps with index smaller than X steps to left from current
		if (index < current - sidesLeft - 1) {
			return null;
		}

		var classes = `step 
		${index + 1 === current ? " is-active" : " "} 
		${index === 0 ? " is-start" : " "}
		${index === steps.length - 1 ? " is-end" : " "}
		${index === current + sidesRight - 1 ? "steps-overflow" : " "}
		${index !== 0 && index === current - sidesLeft - 1 ? "steps-overflow" : " "}
		${current > index + 1 ? " is-complete" : ""}`;

		return (
			<>
				<Step
					index={index}
					data={index + offset}
					title={step.title}
					className={classes}
				/>
			</>
		);
	};

	return (
		<>
			{steps && steps.length > 0 && (
				<ol className="steps">
					{steps.map((step, index) => {
						return (
							<VisibleSteps
								index={index}
								step={step}
								key={index}
							/>
						);
					})}
				</ol>
			)}
		</>
	);
};

export default Steps;
