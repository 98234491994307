import React from "react";
import Card from "react-bootstrap/Card";

const FileIcon = ({ file, editable, deleteCallback }) => {
	return (
		<>
			{file && (
				<Card
					key={file.name}
					style={{ width: "18rem" }}
					className="mb-2 file"
				>
					<Card.Header className={"file-header"}>
						<span className="icon icon-bg"></span>
						<div className="file-icon-bar">
							{file.mediaUrl && (
								<div
									className="icon-remove button btn-md btn-secondary"
									onClick={() =>
										window.open(file.mediaUrl, "_blank")
									}
								>
									<span className="icon"></span>
								</div>
							)}
							{editable && (
								<div
									className="icon-remove button btn-md btn-danger"
									onClick={deleteCallback}
								>
									<span className="icon"></span>
								</div>
							)}
						</div>
					</Card.Header>
					<Card.Body>
						<Card.Title>{file.name}</Card.Title>
					</Card.Body>
				</Card>
			)}
		</>
	);
};

export default FileIcon;
