import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
	useGetWorkflowsFromOrganizationQuery,
	useGetWorkplacesFromOrganizationQuery,
} from "../../../app/services/organization";
import {
	useCreateTTSMutation,
	useDeleteStepMutation,
	useUpdateStepMutation,
} from "../../../app/services/step";
import {
	useCreateWorkflowLanguageMutation,
	useCreateWorkflowMutation,
	useGetStepsOfWorkflowQuery,
	useGetWorkflowLanguagesQuery,
	useGetWorkflowQuery,
	useUpdateWorkflowMutation,
} from "../../../app/services/workflow";
import {
	getCurrentOrganization,
	showModal,
} from "../../../app/slices/appStateSlice";
import { LanguageSelectorWithAdd } from "../../../components/select/LanguageSelector";
import Select from "../../../components/select/Select";
import Details from "../../../layouts/Details";
import { ModalTypes } from "../../../modules/Modals/ConfirmDeleteModal";
import StepList from "./StepList";
import { ErrorMessages, SuccessMessages } from "../../../app/Messages";
import AddLanguageModal from "../../../modules/Modals/AddLanguageModal";
import {
	NotificationType,
	startNotification,
} from "../../../app/slices/notifactionStateSlice";
import { useGetWorkflowLanguageQuery } from "../../../app/services/workflow";
import FullpageSpinner from "../../../modules/spinner/FullpageSpinner";
import OverwriteAudioModal from "../../../modules/Modals/OverwriteAudioModal";
import { SaveCallbackContext } from "../../../modules/step/StepDescription";
const _ = require("lodash");

function timeout(delay) {
	return new Promise((res) => setTimeout(res, delay));
}

const WorkflowDetail = ({ id }) => {
	const rootPath = "/workflows";
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [data, setData] = useState(null);
	const [activeStepId, setActiveStepId] = useState(null);
	const [steps, setSteps] = useState(null);

	const [selectedLanguage, setSelectedLanguage] = useState("");

	const [editWorkflow, setEditWorkflow] = useState(null);
	const currentOrg = useSelector(getCurrentOrganization);

	const { data: workflowSteps, refetch: refetchSteps } =
		useGetStepsOfWorkflowQuery(id);
	const [updateWorkflow, { isLoading: updatingWorkflow }] =
		useUpdateWorkflowMutation();
	const [createWorkflow] = useCreateWorkflowMutation();
	const [createWorkflowLanguage, { isLoading: creatingWorkflowLanguage }] =
		useCreateWorkflowLanguageMutation();
	const { data: workflow } = useGetWorkflowQuery(id);
	const [{ isSuccess: deleteSuccess }] = useDeleteStepMutation();
	const [updateStep] = useUpdateStepMutation();
	const [selectedWorkplace, setSelectedWorkplace] = useState(null);
	const { data: workplaces } = useGetWorkplacesFromOrganizationQuery(
		currentOrg.id
	);
	const { data: languages } = useGetWorkflowLanguagesQuery(id);
	const { data: languageData } = useGetWorkflowLanguageQuery({
		id,
		language: selectedLanguage,
	});
	const { refetch } = useGetWorkflowsFromOrganizationQuery(currentOrg.id);
	const [createTTS, { isLoading: ttsCreating }] = useCreateTTSMutation();

	const test =
		data === null
			? {}
			: {
					workplace: data?.workplace?.name,
					name: data.name,
			  };
	const {
		register,
		handleSubmit,
		getValues,
		formState: { errors, isDirty },
	} = useForm({
		defaultValues: test,
	});

	// Language switching logic. We let RTK query keep the selected language version in sync and instruct
	// it to load a new language, whenever the user changes the selected language. To avoid concurrency
	// issues, we must have only a single place, which will react to the change of the language version.
	// We do not need to skip any api query if we compare the id of the language version to the currently
	// available id of the workflow data. So:

	// react to any change of the languageData, which can happen during page load and during language switch
	useEffect(() => {
		// we only want to react to that, whenever data and languageData has been set.
		// If data has not been set, the workflow is not loaded, yet
		console.debug("verifying language...");
		if (data?.id && languageData?.id && data?.id !== languageData?.id) {
			console.debug("we have a new language to go to");
			console.debug("navigating to...", rootPath + "/" + languageData.id);
			navigate(rootPath + "/" + languageData.id);
		}
	}, [languageData, navigate, data]);

	// Next update logic encapsulates the loading of the workflow data. Whenever a navigation happens,
	// the workflow is reloaded via RTK. Now a hook is used to transfer this state into the workflow.
	// The local "mirror" of the workflow is the data state. So now what we're doing is comparing the
	// loaded workflow to the currently loaded data. In case they match, we don't have to do anything
	// at all. But if they differ, the data has to be replaced.
	useEffect(() => {
		console.debug("Workflow or data has changed. Validating sync...");
		if (workflow?.id && workflow?.id !== data?.id) {
			console.debug("New workflow detected, setting data.");
			setData(workflow);
		}
	}, [workflow, data]);

	// keep track of whether we are editing a workflow or creating a new one
	useEffect(() => {
		if (data?.id) {
			setEditWorkflow(true);
		} else {
			setEditWorkflow(false);
		}
	}, [data]);

	useEffect(() => {
		console.log("we was loaded....");
		//currentOrg && setSkip(false);
		//!skip && navigate(rootPath);
		dispatch(showModal("none"));
		// eslint-disable-next-line
	}, [currentOrg, id]);

	useEffect(() => {
		workflowSteps && setSteps(workflowSteps);
	}, [workflowSteps]);

	useEffect(() => {
		workflow
			? setSelectedWorkplace(workflow.workplace ? workflow.workplace : {})
			: setSelectedWorkplace({});
	}, [workflow, deleteSuccess, workflowSteps, workplaces]);

	useEffect(() => {
		if (id === null) {
			setData({});
		}
	}, [id]);

	const handleSave = () => {
		var postData = getValues();
		var index = workflow ? workflow.index : null;

		postData = {
			...postData,
			id: id,
			language: selectedLanguage,
			organization: currentOrg.id,
			workplace: selectedWorkplace.id,
			steps,
			index,
		};

		if (editWorkflow) {
			return updateWorkflow({ id: data.id, patch: postData }).then(() => {
				refetch();
			});
		} else {
			createWorkflow(Object.assign(postData, { steps: [] })).then(
				(res) => {
					navigate("/workflows/" + res.data.id);
				}
			);
		}
	};

	const handleView = () => {
		window.open("../../view/workflows/" + data.id);
	};

	const handleTTSUpdate = async (e, data) => {
		updateStep({ id: data.step.id, patch: data.step }).then(async () => {
			setActiveStepId(data.step.id);
			await timeout(1000);
			createTTS(data.step.id).then((res) => {
				if (res?.error) {
					dispatch(
						startNotification({
							message: res?.error?.error,
							type: NotificationType.ERROR,
						})
					);
					return;
				} else {
					dispatch(
						startNotification({
							message: SuccessMessages.UPDATE,
							type: NotificationType.SUCCESS,
						})
					);
				}
			});

			dispatch(showModal("none"));
		});
	};

	const handleStepSave = async ({ steps }) => {
		var index = workflow ? workflow.index : null;
		var formData = new FormData(document.querySelector("form"));

		var workflowUpdate = {
			...Object.fromEntries(formData.entries()),
			id: id,
			organization: currentOrg.id,
			workplace: selectedWorkplace.id,
			steps,
			index,
		};

		updateWorkflow({ id: data.id, patch: workflowUpdate }).then(
			async (res) => {
				if (res?.error) {
					dispatch(
						startNotification({
							message: res.error.error,
							type: NotificationType.ERROR,
						})
					);
				} else {
					dispatch(
						startNotification({
							message: SuccessMessages.UPDATE,
							type: NotificationType.SUCCESS,
						})
					);

					_.forEach(steps, (step, index) => {
						updateStep({ id: step.id, patch: step });
					});
				}

				await timeout(1000);
				refetchSteps();
				refetch();
			}
		);
	};

	const handleWorkplaceSelection = (id) => {
		var getSelectedWorkplace = workplaces.find(
			(workplace) => workplace.id === id
		);
		setSelectedWorkplace(getSelectedWorkplace);
	};

	const handleNewLanguage = (item) => {
		createWorkflowLanguage({ id, data: { language: item } }).then((res) => {
			if (res?.error) {
				dispatch(
					startNotification({
						message: res.error.error,
						type: NotificationType.ERROR,
					})
				);
			} else {
				navigate(rootPath + "/" + res.data.id);
				navigate(0);

				dispatch(
					startNotification({
						message: SuccessMessages.SAVE,
						type: NotificationType.SUCCESS,
					})
				);
			}
		});
	};

	const onChangeLanguage = (code) => {
		setSelectedLanguage(code);
	};

	return (
		data && (
			<>
				{creatingWorkflowLanguage || updatingWorkflow ? (
					<FullpageSpinner
						text={
							creatingWorkflowLanguage
								? "creating.."
								: "updating.."
						}
					/>
				) : (
					<>
						{currentOrg && (
							<SaveCallbackContext.Provider
								value={handleTTSUpdate}
							>
								<form id="workflow">
									<Details
										saveFunction={
											isDirty && handleSubmit(handleSave)
										}
										modalData={{
											type: ModalTypes.Workflow,
											data: data,
											navigate: rootPath,
										}}
									>
										<>
											{creatingWorkflowLanguage ||
											ttsCreating ? (
												<FullpageSpinner
													text={
														ttsCreating &&
														"Generating Text to Speech Audiofile"
													}
												/>
											) : (
												<div className="row mb-5">
													<div className="form-group col-12 row">
														<div className="col-3 flex flex-column">
															<div
																className={`label-container`}
															>
																<label
																	htmlFor={
																		"name"
																	}
																>
																	{
																		"Workflow Name"
																	}
																</label>
															</div>
															<div
																className={`input-container ${
																	errors[
																		"name"
																	] && "error"
																}`}
															>
																<input
																	name={
																		"name"
																	}
																	type={
																		"text"
																	}
																	className={`form-control mr-2 centered ${
																		errors[
																			"name"
																		] &&
																		"error"
																	}`}
																	defaultValue={
																		data.name ??
																		""
																	}
																	{...register(
																		"name",
																		{
																			required:
																				ErrorMessages.REQUIRED,
																		}
																	)}
																/>
																{errors &&
																	errors[
																		"name"
																	] && (
																		<span className="error">
																			{
																				errors[
																					"name"
																				]
																					?.message
																			}
																		</span>
																	)}
															</div>
														</div>
														<div className="col-3">
															<label>
																Workplace
															</label>
															<div className="flex">
																{workplaces && (
																	// <Controller
																	// 	render={({
																	// 		field,
																	// 		fieldState:
																	// 			{
																	// 				invalid,
																	// 				isTouched,
																	// 				isDirty,
																	// 				error,
																	// 			},
																	// 	}) => (
																	<Select
																		// {...field}
																		type="div"
																		className="dropdown-inputstyle"
																		data={
																			workplaces
																		}
																		selected={
																			selectedWorkplace
																		}
																		selectCallback={(
																			id
																		) =>
																			handleWorkplaceSelection(
																				id
																			)
																		}
																	/>
																	// 	)}
																	// 	name="workplace"
																	// 	control={
																	// 		control
																	// 	}
																	// 	defaultValue={
																	// 		selectedWorkplace.name
																	// 	}
																	// />
																)}
															</div>
														</div>
														<div className="col-3">
															<div className="flex ">
																{languages && (
																	<>
																		{id !==
																			null && (
																			<LanguageSelectorWithAdd
																				fullWidth={
																					true
																				}
																				className={
																					"fullWidth dropdown light languages"
																				}
																				languages={
																					languages
																				}
																				active={
																					workflow.language
																				}
																				onChangeCB={(
																					code
																				) =>
																					onChangeLanguage(
																						code
																					)
																				}
																			/>
																		)}
																	</>
																)}
															</div>
														</div>
														{id && (
															<div className="col-2">
																<label className="hide-onPhone">
																	{" "}
																</label>
																<div className="flex">
																	<button
																		className="btn btn-lg btn-tertiary"
																		onClick={
																			handleView
																		}
																	>
																		View workflow
																		<i className="icon">
																			
																		</i>
																	</button>
																</div>
															</div>
														)}
													</div>
													<div className="col-12">
														<label>
															Workflow Steps
														</label>

														<StepList
															activeStepId={
																activeStepId
															}
															workflowId={data.id}
															saveWorkflowCb={
																handleStepSave
															}
															setStepsCb={
																setSteps
															}
														/>
													</div>
												</div>
											)}
										</>
									</Details>
									<AddLanguageModal
										blacklist={languages}
										saveCallback={handleNewLanguage}
									/>
									<OverwriteAudioModal />
								</form>
							</SaveCallbackContext.Provider>
						)}
					</>
				)}
			</>
		)
	);
};

export default WorkflowDetail;
