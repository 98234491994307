import React, { Children } from "react";

const Item = ({ children, classes, buttons, ...props }) => {
	return (
		<div
			className={
				classes
					? classes + " list-item-container flex info-box"
					: "list-item-container flex info-box"
			}
			{...props}
		>
			{Children.map(children, (child, i) => (
				<div className="list-item" key={i}>
					{child}
				</div>
			))}
			<div className="list-item list-item-buttons">{buttons}</div>
		</div>
	);
};

export default Item;
