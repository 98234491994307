import React from "react";
import { CustomModal } from "../../../components/modal/Modal";
import Preview from "../../../components/preview/Preview";

const PreviewImageModal = ({ data }) => {
	return (
		<>
			{data && (
				<CustomModal id="preview" title={data.name}>
					<Preview
						large
						filename={data.thumbnailUrl}
						alt={!data.thumbnailUrl && "no image"}
					/>
				</CustomModal>
			)}
		</>
	);
};

export default PreviewImageModal;
