import React from "react";
import { useParams } from "react-router-dom";
import { useGetWorkplaceQuery } from "../../../app/services/workplace";
import { Titlebar, TitleType } from "../../../layouts/Content";
import FullpageSpinner from "../../../modules/spinner/FullpageSpinner";
import WorkplaceDetail from "./WorkplaceDetail";
import FourOFour from "../../errors/404";

const WorkplaceNew = () => {
	return (
		<>
			<Titlebar
				type={TitleType.Create}
				title="Workplace"
				parent={{
					name: "Workplaces",
					url: "/workplaces",
				}}
			/>
			<WorkplaceDetail data={{}} />
		</>
	);
};
const WorkplaceEdit = () => {
	const { id } = useParams();
	const {
		data,
		isSuccess: loadSuccess,
		isLoading,
		isUninitialized,
	} = useGetWorkplaceQuery(id);
	return (
		<>
			{isLoading || isUninitialized ? (
				<FullpageSpinner />
			) : (
				<>
					{loadSuccess && data ? (
						<>
							<Titlebar
								title={data.name}
								parent={{
									name: "Workplaces",
									url: "/workplaces",
								}}
							/>
							<WorkplaceDetail
								data={data}
								isLoading={isLoading}
							/>
						</>
					) : (
						<>
							<FourOFour
								error={`Workplace with ID "${id}" not found`}
								parent={{
									name: "workplaces",
									url: "/workplaces",
								}}
							/>
						</>
					)}
				</>
			)}
		</>
	);
};

export { WorkplaceNew, WorkplaceEdit };
