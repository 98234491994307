import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentOrganization } from "../../../app/slices/appStateSlice";
import Preview from "../../../components/preview/Preview";
import Toggle from "../../../components/toggle/Toggle";
import ListOverviewTemplate from "../../../layouts/ListOverviewTemplate";
import List from "../../../components/list/List";
import { ImageModalActions } from "../../../modules/Modals/ImageModals/ImageModalSelector";
import { ModalTypes } from "../../../modules/Modals/ConfirmDeleteModal";
import { useGetARObjsFromOrganizationQuery } from "../../../app/services/organization";
import { handleModal } from "../../../components/modal/Modal";

const SettingsAR = () => {
	const dispatch = useDispatch();
	const selectedOrg = useSelector(getCurrentOrganization);
	const [skip, setSkip] = useState(true);
	const { data } = useGetARObjsFromOrganizationQuery(selectedOrg.id, {
		skip,
	});

	const [currentObjects, setCurrentObjects] = useState(data);

	useEffect(() => {
		selectedOrg && setSkip(false);
	}, [selectedOrg]);

	useEffect(() => {
		setCurrentObjects(data);
	}, [data]);

	const handlePreview = (id) => {
		let selectedData = getDataById(id);
		handleModal(
			dispatch,
			ModalTypes.ARObject,
			ImageModalActions.Preview,
			selectedData
		);
	};

	const handleNew = () => {
		let selectedData = {};
		handleModal(
			dispatch,
			ModalTypes.ARObject,
			ImageModalActions.Create,
			selectedData
		);
	};

	const handleEdit = (id) => {
		let selectedData = getDataById(id);
		handleModal(
			dispatch,
			ModalTypes.ARObject,
			ImageModalActions.Edit,
			selectedData
		);
	};

	const handleDelete = (item) => {
		handleModal(
			dispatch,
			ModalTypes.ARObject,
			ImageModalActions.Delete,
			item
		);
	};

	const getDataById = (id) => {
		var result = data.find((i) => i.id === id);
		return result;
	};

	const ARList = () => {
		return (
			<>
				<List id="ar">
					<List.Header>
						<List.Header.Cell>Preview</List.Header.Cell>
						<List.Header.Cell>Name</List.Header.Cell>
						<List.Header.Cell>Active</List.Header.Cell>
					</List.Header>
					{currentObjects.map((item) => (
						<List.Item
							key={item.id}
							buttons={[
								<List.Item.Button
									type="View"
									key="view-button"
									callback={() => handlePreview(item.id)}
									hidden={!item.thumbnailUrl}
								/>,
								<List.Item.Button
									type="Edit"
									key="edit-button"
									callback={() => handleEdit(item.id)}
								/>,
								<List.Item.Button
									type="Delete"
									key="delete-button"
									callback={() => handleDelete(item)}
								/>,
							]}
						>
							<List.Item.Content key="preview">
								<Preview
									filename={item.thumbnailUrl}
									zoomable={Boolean(item.thumbnailUrl)}
									onClick={
										item.thumbnailUrl
											? () => handlePreview(item.id)
											: undefined
									}
									size={item.thumbnailSize}
								/>
							</List.Item.Content>
							<List.Item.Content title key="name">
								{item.name}
							</List.Item.Content>
							<List.Item.Content key="toggle">
								<Toggle
									id={item.id}
									disabled={true}
									checked={true}
								/>
							</List.Item.Content>
						</List.Item>
					))}
				</List>
			</>
		);
	};

	return (
		<>
			<>
				<div className={"mb-5 mt-5"}>
					<h5>AR Objects Overview</h5>
				</div>
				<ListOverviewTemplate addCallback={handleNew}>
					{currentObjects && <ARList />}
				</ListOverviewTemplate>
			</>
		</>
	);
};

export default SettingsAR;
