import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getModalData, showModal } from "../../app/slices/appStateSlice";
import { CustomModal } from "../../components/modal/Modal";

import {
	LanguageSelectorTemporary,
	useCustomLabels,
} from "../../components/select/LanguageSelector";
import { ModalTypes } from "./ConfirmDeleteModal";
import { useGetLanguagesQuery } from "../../app/services/workflow";

const AddLanguageModal = ({ blacklist, saveCallback }) => {
	const modal = useSelector(getModalData);
	const dispatch = useDispatch();
	const [selectedLanguage, setSelectedLanguage] = useState([]);
	const { data: languageData } = useGetLanguagesQuery();

	const labels = useCustomLabels();
	const [customLabels, setCustomLabels] = useState(null);

	useEffect(() => {
		if (customLabels === null) {
			setCustomLabels(labels);
		}
	}, [customLabels, labels]);

	const handleSave = () => {
		const selectedLanguageCode = languageData.find(
			(elm) => elm.flag === selectedLanguage
		);
		selectedLanguageCode && saveCallback(selectedLanguageCode.languageCode);
		dispatch(showModal("none"));
	};

	const handleSelection = (item) => {
		setSelectedLanguage(item);
	};

	useEffect(() => {}, [modal]);

	useEffect(() => {}, [selectedLanguage]);

	return (
		<>
			{modal && modal.type === ModalTypes.Language && (
				<CustomModal
					id={"language"}
					title={"Add new Language to workflow"}
					okButton={
						<button
							type="button"
							className="btn btn-primary ml-3 "
							onClick={() => handleSave()}
						>
							<i className="icon"></i>
							Save
						</button>
					}
				>
					{customLabels && (
						<LanguageSelectorTemporary
							blacklist={blacklist}
							fullWidth={true}
							className={"fullWidth dropdown languages light"}
							customLabels={customLabels}
							handleSelect={handleSelection}
						/>
					)}
				</CustomModal>
			)}
		</>
	);
};

export default AddLanguageModal;
