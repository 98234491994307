import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/esm/Tab";
import Tabs from "react-bootstrap/esm/Tabs";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
	useCreateEnvironmentMutation,
	useGetUnassignedWorkflowsOfEnvironmentQuery,
	useUpdateEnvironmentMutation,
} from "../../../app/services/environment";

import { getCurrentOrganization } from "../../../app/slices/appStateSlice";
import Details from "../../../layouts/Details";
import { ModalTypes } from "../../../modules/Modals/ConfirmDeleteModal";
import GroupManagement from "./tabs/Groups/EnvironmentUser";
import Settings from "./tabs/Settings/EnvironmentSettings";
import { useNavigate } from "react-router-dom";
import { SuccessMessages } from "../../../app/Messages";
import {
	NotificationType,
	startNotification,
} from "../../../app/slices/notifactionStateSlice";

const EnvironmentDetail = ({ data, refetch }) => {
	const editMode = Object.keys(data).length !== 0;
	const rootPath = "/environments";

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { data: unassignedWorkflows, refetch: refetchUnassigned } =
		useGetUnassignedWorkflowsOfEnvironmentQuery(data.id);
	const currentOrg = useSelector(getCurrentOrganization);
	const [selectedGroups, setSelectedGroups] = useState(
		data.memberGroup ? data.memberGroup : []
	);
	const [updateEnvironment] = useUpdateEnvironmentMutation();
	const [createEnvironment] = useCreateEnvironmentMutation();

	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm();
	const [currentData, setCurrentData] = useState(data);

	useEffect(() => {}, [data.id]);

	useEffect(() => {
		setCurrentData(data);
	}, [data]);

	const handleSave = (postData) => {
		postData = { ...postData, memberGroup: selectedGroups };

		const action = editMode
			? updateEnvironment({ id: data.id, patch: postData }).then(() => {
					refetch && refetch();
					refetchUnassigned();
					dispatch(
						startNotification({
							message: SuccessMessages.UPDATE,
							type: NotificationType.SUCCESS,
						})
					);
					// TODO: Throw error on error!
			  })
			: createEnvironment(postData).then((res) => {
					navigate("/environments/" + res.data.id);
					dispatch(
						startNotification({
							message: SuccessMessages.SAVE,
							type: NotificationType.SUCCESS,
						})
					);
					// TODO: Throw error on error!
			  });
		return action;
	};

	return (
		<>
			{currentOrg && (
				<form>
					<Details
						saveFunction={handleSubmit(handleSave)}
						modalData={{
							type: ModalTypes.Environment,
							data: currentData,
							navigate: rootPath,
						}}
					>
						<input
							className="hidden"
							type="hidden"
							defaultValue={currentOrg.id}
							{...register("organization")}
						/>
						<Tabs
							transition={false}
							defaultActiveKey="settings"
							id="justify-tab-example"
							className="mb-5"
							justify
						>
							<Tab eventKey="settings" title="Settings">
								{currentData && (
									<Settings
										data={currentData}
										control={control}
										register={register}
										errors={errors}
										unassignedWorkflows={
											unassignedWorkflows
										}
									/>
								)}
							</Tab>
							<Tab eventKey="users" title="User Management">
								{selectedGroups && (
									<GroupManagement
										groups={selectedGroups}
										setGroups={setSelectedGroups}
									/>
								)}
							</Tab>
						</Tabs>
					</Details>
				</form>
			)}
		</>
	);
};

export default EnvironmentDetail;
