import React from "react";
import { useGetStepsOfWorkflowQuery } from "../../../app/services/workflow";
import Stepper from "../../../layouts/Stepper";
import FullpageSpinner from "../../../modules/spinner/FullpageSpinner";

const WorkflowDetailViewonly = ({ data }) => {
	const { data: workflowSteps, isLoading } = useGetStepsOfWorkflowQuery(
		data?.id
	);

	return (
		<>
			{!isLoading ? (
				<>
					<div className="stepper vertical-onPhone">
						{data ? (
							<>
								{data.steps?.length > 0 ? (
									<div className="content-row">
										<Stepper
											data={workflowSteps}
											stepsShowing={5}
										/>
									</div>
								) : (
									<p className="centered-text">
										There are no steps created for this
										workflow.
									</p>
								)}
							</>
						) : (
							<div className="title-row">
								<h1 className="title">No Data</h1>
							</div>
						)}
					</div>
				</>
			) : (
				<FullpageSpinner />
			)}
		</>
	);
};

export default WorkflowDetailViewonly;
